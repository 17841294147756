import RoleClient from '@/api/role.api';
import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store';
import { User } from '@/@typespaces/interfaces/user.interface';
import { UsersMutationTypes } from '@/store/modules/users/mutation-types';
import MapUserClient from '@/api/map/map-users.api';
import {
  ProjectUserChangeRole,
  UserProjectBlock,
  UserProjectRemove,
  UserProjectUnblock,
} from '@/@typespaces/types/user.types';
import FranchiseClient from '@/api/franchises.api';
import TmapUserClient from '@/api/tmap/tmap-users.api';
import { State } from './state';
import { Mutations } from './mutations';
import { UsersActionTypes } from './action-types';

const mapClient = new MapUserClient();
const tmapClient = new TmapUserClient();
const roleClient = new RoleClient();
const franchiseClient = new FranchiseClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [UsersActionTypes.GET_USERS]({ commit }: AugmentedActionContext, data: any): void;

  [UsersActionTypes.DELETE_USER]({ commit }: AugmentedActionContext, id: string): void;

  [UsersActionTypes.GET_ONE_USER]({ commit }: AugmentedActionContext, id: string): void;

  [UsersActionTypes.GET_FRANCHISE_USER](
    { commit }: AugmentedActionContext,
    data: { franchiseId: string; userId: string }
  ): void;

  [UsersActionTypes.DELETE_USERS]({ commit }: AugmentedActionContext, data: { ids: string[] }): void;

  [UsersActionTypes.BLOCK_USER]({ commit }: AugmentedActionContext, id: string): void;

  [UsersActionTypes.UNBLOCK_USER]({ commit }: AugmentedActionContext, id: string): void;

  [UsersActionTypes.BLOCK_USERS]({ commit }: AugmentedActionContext, data: { ids: string[] }): void;

  [UsersActionTypes.UNBLOCK_USERS]({ commit }: AugmentedActionContext, data: { ids: string[] }): void;

  [UsersActionTypes.ADD_USER]({ commit }: AugmentedActionContext, data: User): void;

  [UsersActionTypes.EDIT_USER]({ commit }: AugmentedActionContext, data: { id: string; body: Partial<User> }): void;

  // Для таблицы "Проекты" в отдельно выбранном пользователе
  [UsersActionTypes.GET_USER_PROJECTS]({ commit }: AugmentedActionContext, data: { id: string; query: any }): void;

  [UsersActionTypes.BLOCK_USER_IN_PROJECT]({ commit }: AugmentedActionContext, data: UserProjectBlock): void;

  [UsersActionTypes.UNBLOCK_USER_IN_PROJECT]({ commit }: AugmentedActionContext, data: UserProjectUnblock): void;

  [UsersActionTypes.REMOVE_USER_FROM_PROJECT]({ commit }: AugmentedActionContext, data: UserProjectRemove): void;

  [UsersActionTypes.CHANGE_ROLE_PROJECT_USER]({ commit }: AugmentedActionContext, data: ProjectUserChangeRole): void;

  [UsersActionTypes.GET_FRANCHISE_GROUP_ROLES]({ commit }: AugmentedActionContext): void;

  [UsersActionTypes.GET_PROJECT_GROUP_ROLES]({ commit }: AugmentedActionContext): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [UsersActionTypes.GET_USERS]({ commit, getters }, data) {
    commit(UsersMutationTypes.USER_LOADING);
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      let users;
      if (isGroupAdmin) {
        users = await mapClient.getUsers(data);
      } else {
        users = await tmapClient.getUsers(data);
      }
      commit(UsersMutationTypes.USER_SUCCEEDED);
      commit(UsersMutationTypes.SET_DATA_USERS, users);
    } catch (error: any) {
      commit(UsersMutationTypes.USER_ERROR, error);
    }
  },

  async [UsersActionTypes.GET_ONE_USER]({ commit, getters }, data) {
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      let user;
      if (isGroupAdmin) {
        user = await mapClient.getUser(data);
      } else {
        user = await tmapClient.getUser(data);
      }
      commit(UsersMutationTypes.GET_ONE_USER, user);
    } catch (error: any) {
      commit(UsersMutationTypes.USER_ERROR, error);
    }
  },
  async [UsersActionTypes.GET_FRANCHISE_USER]({ commit }, data: { franchiseId: string; userId: string }) {
    try {
      const user = await franchiseClient.getFranchiseUser(data);
      commit(UsersMutationTypes.GET_ONE_USER, user);
    } catch (error: any) {
      commit(UsersMutationTypes.USER_ERROR, error);
    }
  },

  async [UsersActionTypes.DELETE_USER]({ commit, getters }, id: string) {
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapClient.deleteUser(id);
      } else {
        await tmapClient.deleteUser(id);
      }
      commit(UsersMutationTypes.DELETE_USER, id);
    } catch (error: any) {
      commit(UsersMutationTypes.USER_ERROR, error);
      throw new Error(error);
    }
  },
  async [UsersActionTypes.DELETE_USERS]({ commit, getters }, data: { ids: string[] }) {
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapClient.deleteUsers(data);
      } else {
        await tmapClient.deleteUsers(data);
      }
      commit(UsersMutationTypes.DELETE_USERS, data.ids);
    } catch (error: any) {
      commit(UsersMutationTypes.USER_ERROR, error);
      throw new Error(error);
    }
  },
  async [UsersActionTypes.BLOCK_USER]({ commit, getters }, id: string) {
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapClient.blockUser(id);
      } else {
        await tmapClient.blockUser(id);
      }
      commit(UsersMutationTypes.BLOCK_USER, id);
    } catch (error: any) {
      commit(UsersMutationTypes.USER_ERROR, error);
      throw new Error(error);
    }
  },
  async [UsersActionTypes.UNBLOCK_USER]({ commit, getters }, id: string) {
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapClient.unblockUser(id);
      } else {
        await tmapClient.unblockUser(id);
      }
      commit(UsersMutationTypes.UNBLOCK_USER, id);
    } catch (error: any) {
      commit(UsersMutationTypes.USER_ERROR, error);
      throw new Error(error);
    }
  },
  async [UsersActionTypes.BLOCK_USERS]({ commit, getters }, data: { ids: string[] }) {
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapClient.blockUsers(data);
      } else {
        await tmapClient.blockUsers(data);
      }
      commit(UsersMutationTypes.BLOCK_USERS, data.ids);
    } catch (error: any) {
      commit(UsersMutationTypes.USER_ERROR, error);
      throw new Error(error);
    }
  },
  async [UsersActionTypes.UNBLOCK_USERS]({ commit, getters }, data: { ids: string[] }) {
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapClient.unblockUsers(data);
      } else {
        await tmapClient.unblockUsers(data);
      }
      commit(UsersMutationTypes.UNBLOCK_USERS, data.ids);
    } catch (error: any) {
      commit(UsersMutationTypes.USER_ERROR, error);
      throw new Error(error);
    }
  },
  async [UsersActionTypes.ADD_USER]({ commit, getters }, data: User) {
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      let users;
      if (isGroupAdmin) {
        users = await mapClient.addUser(data);
      } else {
        users = await tmapClient.addUser(data);
      }
      commit(UsersMutationTypes.ADD_USER, users);
    } catch (error: any) {
      commit(UsersMutationTypes.USER_ERROR, error);
      throw new Error(error);
    }
  },
  async [UsersActionTypes.EDIT_USER]({ commit, getters }, data: { id: string; body: Partial<User> }) {
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapClient.editUser(data);
      } else {
        await tmapClient.editUser(data);
      }
      commit(UsersMutationTypes.EDIT_USER, data);
    } catch (error: any) {
      commit(UsersMutationTypes.USER_ERROR, error);
      throw new Error(error);
    }
  },

  // Для таблицы "Проекты" в отдельно выбранном пользователе
  async [UsersActionTypes.GET_USER_PROJECTS]({ commit, getters }, data: { id: string; query: any }) {
    commit(UsersMutationTypes.USER_PROJECTS_LOADING);
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      let response;
      if (isGroupAdmin) {
        response = await mapClient.getProjectsByUserId(data);
      } else {
        response = await tmapClient.getProjectsByUserId(data);
      }
      commit(UsersMutationTypes.USER_PROJECTS_SUCCEEDED);
      commit(UsersMutationTypes.SET_USER_PROJECTS, response);
    } catch (error: any) {
      commit(UsersMutationTypes.USER_PROJECTS_ERROR, error);
    }
  },
  async [UsersActionTypes.BLOCK_USER_IN_PROJECT]({ commit, getters }, data: UserProjectBlock) {
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapClient.blockProjectOfUser(data);
      } else {
        await tmapClient.blockProjectOfUser(data);
      }
      commit(UsersMutationTypes.BLOCK_USER_IN_PROJECT, data);
    } catch (error: any) {
      commit(UsersMutationTypes.USER_PROJECTS_ERROR, error);
      throw new Error(error);
    }
  },
  async [UsersActionTypes.UNBLOCK_USER_IN_PROJECT]({ commit, getters }, data: UserProjectUnblock) {
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapClient.unblockProjectOfUser(data);
      } else {
        await tmapClient.unblockProjectOfUser(data);
      }
      commit(UsersMutationTypes.UNBLOCK_USER_IN_PROJECT, data);
    } catch (error: any) {
      commit(UsersMutationTypes.USER_PROJECTS_ERROR, error);
      throw new Error(error);
    }
  },
  async [UsersActionTypes.REMOVE_USER_FROM_PROJECT]({ commit, getters }, data: UserProjectRemove) {
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapClient.removeProjectOfUser(data);
      } else {
        await tmapClient.removeProjectOfUser(data);
      }
      commit(UsersMutationTypes.REMOVE_USER_FROM_PROJECT, data);
    } catch (error: any) {
      commit(UsersMutationTypes.USER_ERROR, error);
      throw new Error(error);
    }
  },
  async [UsersActionTypes.CHANGE_ROLE_PROJECT_USER]({ commit, getters }, data: ProjectUserChangeRole) {
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapClient.changeRoleOfProjectUser(data);
      } else {
        await tmapClient.changeRoleOfProjectUser(data);
      }
      commit(UsersMutationTypes.CHANGE_ROLE_PROJECT_USER, data);
    } catch (error: any) {
      commit(UsersMutationTypes.USER_ERROR, error);
      throw new Error(error);
    }
  },
  async [UsersActionTypes.GET_FRANCHISE_GROUP_ROLES]({ commit }) {
    try {
      const response = await roleClient.getFranchiseGroupRoles();
      commit(UsersMutationTypes.GET_FRANCHISE_GROUP_ROLES, response);
    } catch (error: any) {
      commit(UsersMutationTypes.USER_ERROR, error);
      throw new Error(error);
    }
  },

  async [UsersActionTypes.GET_PROJECT_GROUP_ROLES]({ commit }) {
    try {
      const response = await roleClient.getProjectGroupRoles();
      commit(UsersMutationTypes.GET_PROJECT_GROUP_ROLES, response);
    } catch (error: any) {
      commit(UsersMutationTypes.USER_ERROR, error);
      throw new Error(error);
    }
  },
};
