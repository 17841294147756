import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '@/store';
import ReviewsClient from '@/api/reviews.api';
import { ReviewContentPriority, UpdateReviewContent } from '@/@typespaces/interfaces/form/reviews-form.interface';
import { ReviewsMutationTypes } from './mutation-types';
import { State } from './state';
import { Mutations } from './mutations';
import { ReviewsActionTypes } from './action-types';

const client = new ReviewsClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [ReviewsActionTypes.FETCH_REVIEWS]({ commit }: AugmentedActionContext): void;

  [ReviewsActionTypes.EDIT_REVIEWS]({ commit }: AugmentedActionContext, payload: string): void;

  [ReviewsActionTypes.ADD_REVIEW_CONTENT]({ commit }: AugmentedActionContext): void;

  [ReviewsActionTypes.EDIT_REVIEW_CONTENT](
    { commit }: AugmentedActionContext,
    payload: { id: string; data: UpdateReviewContent }
  ): void;

  [ReviewsActionTypes.DELETE_REVIEW_CONTENT]({ commit }: AugmentedActionContext, id: string): void;

  [ReviewsActionTypes.REVIEW_EDIT_PRIORITY](
    { commit }: AugmentedActionContext,
    payload: { reviews: ReviewContentPriority[] }
  ): void;

  [ReviewsActionTypes.UPDATE_REVIEWS_FILE](
    { commit }: AugmentedActionContext,
    payload: { content_id: string; file_id: string }
  ): void;
  [ReviewsActionTypes.REMOVE_REVIEWS_FILE](
    { commit }: AugmentedActionContext,
    payload: { content_id: string; file_id: string }
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ReviewsActionTypes.FETCH_REVIEWS]({ commit }) {
    commit(ReviewsMutationTypes.REVIEWS_LOADING);
    try {
      const data = await client.getReviews();
      commit(ReviewsMutationTypes.FETCH_REVIEWS, data);
      commit(ReviewsMutationTypes.REVIEWS_SUCCEEDED);
    } catch (error: any) {
      commit(ReviewsMutationTypes.REVIEWS_ERROR, error);
      throw new Error(error);
    }
  },
  async [ReviewsActionTypes.EDIT_REVIEWS]({ commit }, payload: string) {
    try {
      await client.editReviews(payload);
    } catch (error: any) {
      commit(ReviewsMutationTypes.REVIEWS_ERROR, error);
      throw new Error(error);
    }
  },

  async [ReviewsActionTypes.ADD_REVIEW_CONTENT]({ commit }) {
    try {
      return await client.addReviewContent();
    } catch (error: any) {
      commit(ReviewsMutationTypes.REVIEWS_ERROR, error);
      throw new Error(error);
    }
  },
  async [ReviewsActionTypes.EDIT_REVIEW_CONTENT]({ commit }, payload: { id: string; data: UpdateReviewContent }) {
    try {
      return await client.editReviewContent(payload);
    } catch (error: any) {
      commit(ReviewsMutationTypes.REVIEWS_ERROR, error);
      throw new Error(error);
    }
  },

  async [ReviewsActionTypes.DELETE_REVIEW_CONTENT]({ commit }, id: string) {
    commit(ReviewsMutationTypes.REVIEWS_LOADING);
    try {
      await client.deleteReviewContent(id);
      commit(ReviewsMutationTypes.DELETE_REVIEW_CONTENT, id);
      commit(ReviewsMutationTypes.REVIEWS_SUCCEEDED);
    } catch (error: any) {
      commit(ReviewsMutationTypes.REVIEWS_ERROR, error);
      throw new Error(error);
    }
  },
  async [ReviewsActionTypes.REVIEW_EDIT_PRIORITY]({ commit }, payload: { reviews: ReviewContentPriority[] }) {
    commit(ReviewsMutationTypes.REVIEWS_TABLE_LOADING);
    try {
      await client.editPriority(payload);
      commit(ReviewsMutationTypes.REVIEWS_TABLE_SUCCEEDED);
    } catch (error: any) {
      const data = await client.getReviews();
      commit(ReviewsMutationTypes.FETCH_REVIEWS, data);
      commit(ReviewsMutationTypes.REVIEWS_TABLE_ERROR, error);
      throw new Error(error);
    }
  },

  async [ReviewsActionTypes.UPDATE_REVIEWS_FILE]({ commit }, payload: { content_id: string; file_id: string }) {
    try {
      await client.updateReviewFile(payload);
    } catch (error: any) {
      commit(ReviewsMutationTypes.REVIEWS_ERROR, error);
      throw new Error(error);
    }
  },

  async [ReviewsActionTypes.REMOVE_REVIEWS_FILE]({ commit }, payload: { content_id: string; file_id: string }) {
    try {
      await client.removeReviewFile(payload);
    } catch (error: any) {
      commit(ReviewsMutationTypes.REVIEWS_ERROR, error);
      throw new Error(error);
    }
  },
};
