// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum FranchiseActionTypes {
  GET_FRANCHISE = 'GET_FRANCHISE',
  CLEAR_FRANCHISE = 'CLEAR_FRANCHISE',
  UPDATE_FRANCHISE = 'UPDATE_FRANCHISE',
  UPDATE_FRANCHISE_FILE = 'UPDATE_FRANCHISE_FILE',
  REMOVE_FRANCHISE_FILE = 'REMOVE_FRANCHISE_FILE',

  // Domain

  CONNECT_FRANCHISE_DOMAIN = 'CONNECT_FRANCHISE_DOMAIN',
  CHECK_FRANCHISE_HTTPS_ACCESS = 'CHECK_FRANCHISE_HTTPS_ACCESS',
  REMOVE_FRANCHISE_DOMAIN = 'REMOVE_FRANCHISE_DOMAIN',
  CLEAR_FRANCHISE_DOMAIN_STORE = 'CLEAR_FRANCHISE_DOMAIN_STORE',
}
