// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum ProjectsActionTypes {
  FETCH_PROJECTS = 'FETCH_PROJECTS',
  CREATE_PROJECT = 'CREATE_PROJECT ',
  EDIT_PROJECT = 'EDIT_PROJECT',
  DELETE_PROJECT = 'DELETE_PROJECT',
  BLOCK_PROJECT = 'BLOCK_PROJECT',
  UNBLOCK_PROJECT = 'UNBLOCK_PROJECT',
  GET_ONE_PROJECT = 'GET_ONE_PROJECT',
  CLEAR_ONE_PROJECT = 'CLEAR_ONE_PROJECT',
  GET_ALL_PROJECT_SHORT = 'GET_ALL_PROJECT_SHORT',
  UPDATE_PROJECT_FILE = 'UPDATE_PROJECT_FILE',
  REMOVE_PROJECT_FILE = 'REMOVE_PROJECT_FILE',

  // Для таблицы пользователей
  FETCH_USERS = 'FETCH_USERS',
  GET_PROJECT_USERS_ROLES = 'GET_PROJECT_USERS_ROLES',
  ADD_USER_IN_PROJECT = 'ADD_USER_IN_PROJECT',
  CHANGE_ROLE_USER_IN_PROJECT = 'CHANGE_ROLE_USER_IN_PROJECT',
  DELETE_USER_IN_CURRENT_PROJECT = 'DELETE_USER_IN_CURRENT_PROJECT',
  BLOCK_USER_IN_CURRENT_PROJECT = 'BLOCK_USER_IN_CURRENT_PROJECT',
  UNBLOCK_USER_IN_CURRENT_PROJECT = 'UNBLOCK_USER_IN_CURRENT_PROJECT',
}
