import Status from '@/@typespaces/enum/status.enum';
import { Url } from '@/@typespaces/interfaces/domain.interface';
import { FranchisesMutationTypes } from '@/store/modules/franchises/mutation-types';
import { MutationTree } from 'vuex';
import { Franchise } from '@/@typespaces/interfaces/franchise.interface';
import { State } from '@/store/modules/franchise/state';
import { FranchiseMutationTypes } from '@/store/modules/franchise/mutation-types';

export type Mutations<S = State> = {
  [FranchiseMutationTypes.GET_FRANCHISE](state: S, payload: Franchise): void;
  [FranchiseMutationTypes.CLEAR_FRANCHISE](state: S): void;
  [FranchiseMutationTypes.UPDATE_FRANCHISE](state: S, payload: Partial<Franchise>): void;
  [FranchiseMutationTypes.FRANCHISE_ERROR](state: S, payload: string): void;

  // Domain
  [FranchiseMutationTypes.CONNECT_FRANCHISE_DOMAIN](state: S, payload: Franchise): void;
  [FranchiseMutationTypes.CONNECT_SSL_TO_DOMAIN](state: S, payload: Franchise): void;
  [FranchiseMutationTypes.CHECK_FRANCHISE_HTTPS_ACCESS](state: S, payload: Url): void;
  [FranchiseMutationTypes.REMOVE_FRANCHISE_DOMAIN](state: S, payload: Franchise): void;

  // Domain status
  [FranchiseMutationTypes.DOMAIN_FRANCHISE_SUCCEEDED](state: S): void;
  [FranchiseMutationTypes.DOMAIN_FRANCHISE_LOADING](state: S): void;
  [FranchiseMutationTypes.DOMAIN_FRANCHISE_ERROR](state: S, payload: string): void;

  [FranchiseMutationTypes.FRANCHISE_SUCCEEDED](state: S): void;
  [FranchiseMutationTypes.FRANCHISE_LOADING](state: S): void;
  [FranchiseMutationTypes.REMOVE_CERT_OR_KEY_FILE](state: S, payload: 'cert' | 'key'): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [FranchiseMutationTypes.GET_FRANCHISE](state: State, payload: Franchise) {
    state.franchise = payload;
  },
  [FranchiseMutationTypes.CLEAR_FRANCHISE](state: State) {
    state.franchise = null;
  },
  [FranchiseMutationTypes.UPDATE_FRANCHISE](state: State, payload: Partial<Franchise>) {
    state.franchise = Object.assign(state.franchise, payload);
  },
  [FranchiseMutationTypes.FRANCHISE_ERROR](state: State, error) {
    state.status = Status.FAILED;
    state.error = error;
  },

  // Domain
  [FranchiseMutationTypes.CONNECT_FRANCHISE_DOMAIN](state: State, payload: Franchise) {
    if (state.franchise) {
      state.franchise = payload;
    }
  },
  [FranchiseMutationTypes.CHECK_FRANCHISE_HTTPS_ACCESS](state: State, payload: Url) {
    if (state.franchise) {
      state.franchise = {
        ...state.franchise,
        domain_settings: { ...state.franchise.domain_settings, main_domain: payload },
      };
    }
  },
  // Domain status
  [FranchiseMutationTypes.DOMAIN_FRANCHISE_LOADING](state: State) {
    state.domainStatus = Status.LOADING;
    state.error = null;
  },
  [FranchiseMutationTypes.DOMAIN_FRANCHISE_SUCCEEDED](state: State) {
    state.domainStatus = Status.SUCCEEDED;
    state.error = null;
  },
  [FranchiseMutationTypes.DOMAIN_FRANCHISE_ERROR](state: State, error) {
    state.domainStatus = Status.FAILED;
    state.error = error;
  },

  [FranchiseMutationTypes.FRANCHISE_LOADING](state: State) {
    state.status = Status.LOADING;
    state.error = null;
  },
  [FranchiseMutationTypes.FRANCHISE_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
    state.error = null;
  },
  [FranchiseMutationTypes.CONNECT_SSL_TO_DOMAIN](state: State, payload: Franchise) {
    if (state.franchise) {
      state.franchise = {
        ...state.franchise,
        ...payload,
      };
    }
  },
  [FranchiseMutationTypes.REMOVE_FRANCHISE_DOMAIN](state: State, payload: Franchise) {
    if (state.franchise) {
      state.franchise = payload;
    }
  },
  [FranchiseMutationTypes.REMOVE_CERT_OR_KEY_FILE](state: State, payload: 'cert' | 'key') {
    if (state.franchise) {
      state.franchise.domain_settings[payload] = null;
    }
  },
};
