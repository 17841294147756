import Status from '@/@typespaces/enum/status.enum';
import { Template, ShortTemplate } from '@/@typespaces/interfaces/templates.interface';

export interface State {
  data: ShortTemplate[];
  templatesIds: string[];
  status: Status;
  error: null | string;
  pagination: {
    page: number;
    pageSize: number;
    total: number;
  };
  oneTemplate: Template | null;
}

export const state: State = {
  data: [],
  templatesIds: [],
  oneTemplate: null,
  status: Status.IDLE,
  error: null,
  pagination: {
    page: 0,
    pageSize: 0,
    total: 0,
  },
};
