import { FranchiseFileType } from '@/@typespaces/enum/file-type.enum';
import { Franchise } from '@/@typespaces/interfaces/franchise.interface';
import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store';
import FranchiseClient from '@/api/franchise.api';
import { FRANCHISE_GROUP } from '@/@constants';
import { State } from '@/store/modules/franchise/state';
import { FranchiseActionTypes } from '@/store/modules/franchise/action-types';
import { FranchiseMutationTypes } from '@/store/modules/franchise/mutation-types';
import { CheckHttpsPayload } from '@/@typespaces/types/domain.types';
import { Mutations } from './mutations';

const client = new FranchiseClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [FranchiseActionTypes.GET_FRANCHISE]({ commit }: AugmentedActionContext): void;

  [FranchiseActionTypes.CLEAR_FRANCHISE]({ commit }: AugmentedActionContext): void;

  [FranchiseActionTypes.UPDATE_FRANCHISE]({ commit }: AugmentedActionContext, data: Partial<Franchise>): void;

  [FranchiseActionTypes.UPDATE_FRANCHISE_FILE](
    { commit }: AugmentedActionContext,
    data: { type: FranchiseFileType; fileId: string }
  ): void;

  // Domain
  [FranchiseActionTypes.CHECK_FRANCHISE_HTTPS_ACCESS](
    { commit }: AugmentedActionContext,
    payload: CheckHttpsPayload
  ): void;
  [FranchiseActionTypes.REMOVE_FRANCHISE_DOMAIN]({ commit }: AugmentedActionContext, payload: CheckHttpsPayload): void;
  [FranchiseActionTypes.REMOVE_FRANCHISE_FILE](
    { commit }: AugmentedActionContext,
    payload: { file_id: string; type: string }
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [FranchiseActionTypes.GET_FRANCHISE]({ commit, getters }) {
    commit(FranchiseMutationTypes.FRANCHISE_LOADING);
    try {
      const isGroupAdmin = getters.getGroupName;
      if (isGroupAdmin === FRANCHISE_GROUP) {
        const data = await client.getFranchise();
        commit(FranchiseMutationTypes.GET_FRANCHISE, data);
      }
      commit(FranchiseMutationTypes.FRANCHISE_SUCCEEDED);
    } catch (error: any) {
      throw new Error(error);
    }
  },
  [FranchiseActionTypes.CLEAR_FRANCHISE]({ commit }) {
    commit(FranchiseMutationTypes.CLEAR_FRANCHISE);
  },
  async [FranchiseActionTypes.UPDATE_FRANCHISE]({ commit }, data: Partial<Franchise>) {
    commit(FranchiseMutationTypes.FRANCHISE_LOADING);
    try {
      await client.editFranchise(data);
      commit(FranchiseMutationTypes.UPDATE_FRANCHISE, data);
      commit(FranchiseMutationTypes.FRANCHISE_SUCCEEDED);
    } catch (error: any) {
      commit(FranchiseMutationTypes.FRANCHISE_ERROR, error);
      throw new Error(error);
    }
  },
  async [FranchiseActionTypes.UPDATE_FRANCHISE_FILE]({ commit }, data: { type: FranchiseFileType; fileId: string }) {
    try {
      await client.updateFranchiseFile(data);
    } catch (error: any) {
      commit(FranchiseMutationTypes.FRANCHISE_ERROR, error);
      throw new Error(error);
    }
  },

  // Domain
  async [FranchiseActionTypes.CHECK_FRANCHISE_HTTPS_ACCESS]({ commit }, payload: CheckHttpsPayload) {
    commit(FranchiseMutationTypes.DOMAIN_FRANCHISE_LOADING);
    try {
      const response = await client.checkHttpsAccess(payload);
      commit(FranchiseMutationTypes.CHECK_FRANCHISE_HTTPS_ACCESS, response);
      commit(FranchiseMutationTypes.DOMAIN_FRANCHISE_SUCCEEDED);
    } catch (error: any) {
      commit(FranchiseMutationTypes.DOMAIN_FRANCHISE_ERROR, error);
      throw new Error(error);
    }
  },

  async [FranchiseActionTypes.REMOVE_FRANCHISE_DOMAIN]({ commit }, payload: CheckHttpsPayload) {
    commit(FranchiseMutationTypes.DOMAIN_FRANCHISE_LOADING);
    try {
      await client.removeDomain(payload);
      commit(FranchiseMutationTypes.REMOVE_FRANCHISE_DOMAIN);
      commit(FranchiseMutationTypes.DOMAIN_FRANCHISE_SUCCEEDED);
    } catch (error: any) {
      commit(FranchiseMutationTypes.DOMAIN_FRANCHISE_ERROR, error);
      throw new Error(error);
    }
  },
  async [FranchiseActionTypes.REMOVE_FRANCHISE_FILE]({ commit }, payload: { file_id: string; type: string }) {
    try {
      await client.removeFile(payload);
    } catch (error: any) {
      commit(FranchiseMutationTypes.DOMAIN_FRANCHISE_ERROR, error);
      throw new Error(error);
    }
  },
};
