/* eslint-disable import/no-cycle */
import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { State } from './state';

export type Getters = {
  getCollapseStateMenu(state: State): boolean;
  getFileId(state: State): string | null;
  getIsWarningHide(state: State): boolean;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getCollapseStateMenu: (state) => state.collapseMenu,
  getFileId: (state) => state.file.fileId,
  getIsWarningHide: (state) => state.hide_warning,
};
