import { Settings } from '@/@typespaces/interfaces/settings.interface';
import Status from '@/@typespaces/enum/status.enum';

export interface State {
  data: Settings | null;
  status: Status;
}

export const state: State = {
  data: null,
  status: Status.IDLE,
};
