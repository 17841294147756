import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import Status from '@/@typespaces/enum/status.enum';
import { Content, Services } from '@/@typespaces/interfaces/services.interface';
import { State } from './state';

export type Getters = {
  getServices(state: State): Services | null;
  getServicesLoading(state: State): boolean;
  getServicesTableLoading(state: State): boolean;
  getServiceContentById: (state: State) => (id: string) => Content | undefined;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getServices: (state) => state.data,
  getServicesLoading: (state) => state.status === Status.LOADING,
  getServicesTableLoading: (state) => state.tableStatus === Status.LOADING,
  getServiceContentById: (state) => (id: string) => state.data?.content.find((item) => item._id === id),
};
