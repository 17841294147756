import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store';
import { ServicesMutationTypes } from '@/store/modules/services/mutation-types';
import ServicesClient from '@/api/services.api';
import {
  ServicesMainPayload,
  ServicesUpdateFilePayload,
  UpdateServiceContent,
} from '@/@typespaces/interfaces/form/services-form.interface';
import { Content } from '@/@typespaces/interfaces/services.interface';
import { State } from './state';
import { Mutations } from './mutations';
import { ServicesActionTypes } from './action-types';

const client = new ServicesClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [ServicesActionTypes.FETCH_SERVICES]({ commit }: AugmentedActionContext): void;

  [ServicesActionTypes.EDIT_SERVICES]({ commit }: AugmentedActionContext, data: ServicesMainPayload): void;

  [ServicesActionTypes.CREATE_SERVICE_CONTENT]({ commit }: AugmentedActionContext): void;

  [ServicesActionTypes.EDIT_SERVICE_CONTENT](
    { commit }: AugmentedActionContext,
    payload: { id: string; data: UpdateServiceContent }
  ): void;

  [ServicesActionTypes.DELETE_SERVICE_CONTENT]({ commit }: AugmentedActionContext, id: string): void;

  [ServicesActionTypes.EDIT_PRIORITY]({ commit }: AugmentedActionContext, payload: Content[]): void;

  [ServicesActionTypes.UPDATE_SERVICES_FILE](
    { commit }: AugmentedActionContext,
    payload: ServicesUpdateFilePayload
  ): void;
  [ServicesActionTypes.REMOVE_SERVICES_FILE](
    { commit }: AugmentedActionContext,
    payload: ServicesUpdateFilePayload
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ServicesActionTypes.FETCH_SERVICES]({ commit }) {
    commit(ServicesMutationTypes.SERVICES_LOADING);
    try {
      const data = await client.getServices();
      commit(ServicesMutationTypes.FETCH_SERVICES, data);
      commit(ServicesMutationTypes.SERVICES_SUCCEEDED);
    } catch (error: any) {
      commit(ServicesMutationTypes.SERVICES_ERROR, error);
      throw new Error(error);
    }
  },
  async [ServicesActionTypes.EDIT_SERVICES]({ commit }, payload: ServicesMainPayload) {
    try {
      await client.editServices(payload);
    } catch (error: any) {
      commit(ServicesMutationTypes.SERVICES_ERROR, error);
      throw new Error(error);
    }
  },

  async [ServicesActionTypes.CREATE_SERVICE_CONTENT]({ commit }) {
    try {
      return await client.addServiceContent();
    } catch (error: any) {
      commit(ServicesMutationTypes.SERVICES_ERROR, error);
      throw new Error(error);
    }
  },
  async [ServicesActionTypes.EDIT_SERVICE_CONTENT]({ commit }, payload: { id: string; data: UpdateServiceContent }) {
    commit(ServicesMutationTypes.SERVICES_LOADING);
    try {
      commit(ServicesMutationTypes.SERVICES_SUCCEEDED);
      return await client.editServiceContent(payload);
    } catch (error: any) {
      commit(ServicesMutationTypes.SERVICES_ERROR, error);
      throw new Error(error);
    }
  },

  async [ServicesActionTypes.DELETE_SERVICE_CONTENT]({ commit }, id: string) {
    try {
      await client.deleteServiceContent(id);
      commit(ServicesMutationTypes.DELETE_SERVICE_CONTENT, id);
    } catch (error: any) {
      commit(ServicesMutationTypes.SERVICES_ERROR, error);
      throw new Error(error);
    }
  },
  async [ServicesActionTypes.EDIT_PRIORITY]({ commit }, payload: Content[]) {
    commit(ServicesMutationTypes.SERVICE_TABLE_LOADING);
    try {
      await client.editPriority(payload);
      commit(ServicesMutationTypes.SERVICE_TABLE_SUCCEEDED);
    } catch (error: any) {
      const data = await client.getServices();
      commit(ServicesMutationTypes.FETCH_SERVICES, data);
      commit(ServicesMutationTypes.SERVICE_TABLE_ERROR, error);
      throw new Error(error);
    }
  },

  async [ServicesActionTypes.UPDATE_SERVICES_FILE]({ commit }, payload: ServicesUpdateFilePayload) {
    try {
      await client.updateServiceFile(payload);
    } catch (error: any) {
      commit(ServicesMutationTypes.SERVICES_ERROR, error);
      throw new Error(error);
    }
  },
  async [ServicesActionTypes.REMOVE_SERVICES_FILE]({ commit }, payload: ServicesUpdateFilePayload) {
    try {
      await client.removeServiceFile(payload);
    } catch (error: any) {
      commit(ServicesMutationTypes.SERVICES_ERROR, error);
      throw new Error(error);
    }
  },
};
