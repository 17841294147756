// Core
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

// Store
// eslint-disable-next-line import/no-cycle
import { store } from '@/store';
// Routing components
// Default
import Default from '@/layouts/default.vue';

// EditorLayout
import EditorLayout from '@/layouts/EditroLayout.vue';

// Auth
import Auth from '@/layouts/auth.vue';
import RecoveryPassword from '@/views/RecoveryPassword.vue';

// Admins
import Admins from '@/views/Admins/Admins.vue';
import AdminCreate from '@/views/Admins/AdminCreate.vue';
import AdminEdit from '@/views/Admins/AdminEdit.vue';

// Users
import Users from '@/views/Users/Users.vue';
import UserCreate from '@/views/Users/UserCreate.vue';
import UserEdit from '@/views/Users/UserEdit.vue';
import UserProjectsTable from '@/views/Users/UserProjectsTable/UserProjectsTable.vue';

// Franchises
import Franchise from '@/views/Franchise/Franchise.vue';
import Franchises from '@/views/Franchises/Franchises.vue';
import FranchiseCreate from '@/views/Franchises/FranchiseCreate.vue';
import FranchiseCard from '@/views/Franchises/FranchiseCard/FranchiseCard.vue';
import FranchiseEdit from '@/views/Franchises/FranchiseEdit.vue';
import FranchiseUserTable from '@/views/Franchises/FranchiseCard/FranchiseUserTable.vue';
import FranchiseProjectTable from '@/views/Franchises/FranchiseCard/FranchiseProjectTable.vue';
import FranchiseDocs from '@/views/Franchises/FranchiseDocs.vue';

// Projects
import Projects from '@/views/Projects/Projects.vue';
import ProjectCreate from '@/views/Projects/ProjectCreate.vue';
import ProjectEdit from '@/views/Projects/ProjectEdit.vue';
import ProjectCard from '@/views/Projects/ProjectCard.vue';

// Templates
import Templates from '@/views/Templates/Templates.vue';
import TemplateCard from '@/views/Templates/Template/TemplateCard.vue';
import TemplateCategories from '@/views/Templates/TemplatesCategories.vue';
import TemplateSetting from '@/views/Templates/TemplateSetting.vue';

// Faq
import Faq from '@/views/Faq/Faq.vue';
import FaqCreate from '@/views/Faq/FaqCreate.vue';
import FaqEdit from '@/views/Faq/FaqEdit.vue';

// Reviews
import Reviews from '@/views/Reviews/Reviews.vue';
import ReviewCreate from '@/views/Reviews/ReviewCreate.vue';
import ReviewEdit from '@/views/Reviews/ReviewEdit.vue';

// Profile
import Profile from '@/views/Profile.vue';

// Settings
import Settings from '@/views/Settings/Settings.vue';

// Contact
import Contact from '@/views/Contact/Contact.vue';

// Service
import Service from '@/views/Service/Service.vue';
import ServiceAdd from '@/views/Service/ServiceAdd.vue';
import ServiceEdit from '@/views/Service/ServiceEdit.vue';

// KnowledgeBase
import KnowledgeBase from '@/views/KnowledgeBase/KnowledgeBase.vue';
import KnowledgeEditArticle from '@/views/KnowledgeBase/KnowledgeEditArticle.vue';
import KnowledgeCreateArticle from '@/views/KnowledgeBase/KnowledgeCreateArticle.vue';

// Seo
import SeoView from '@/views/Seo/SeoView.vue';

// Main page
import MainPage from '@/views/MainPage/MainPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    redirect: '/admins',
    component: () => import('@/views/About.vue'),
    meta: {
      layout: Default,
    },
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: Auth,
    },
  },
  {
    path: '/recovery-password',
    name: 'RecoveryPassword',
    component: RecoveryPassword,
    meta: {
      layout: Auth,
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      layout: Default,
    },
  },
  {
    path: `/admins`,
    name: 'Admins',
    component: Admins,
    meta: {
      layout: Default,
    },
  },
  {
    path: `/admin/create`,
    name: 'AdminCreate',
    component: AdminCreate,
    meta: {
      layout: Default,
    },
  },
  {
    path: `/admin/edit/:id`,
    name: 'AdminEdit',
    component: AdminEdit,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/users/:id/projects',
    name: 'UserProjectsTable',
    component: UserProjectsTable,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/users/create',
    name: 'UserCreate',
    component: UserCreate,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/users/edit/:userId',
    name: 'UserEdit',
    component: UserEdit,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/users/edit/:franchiseId/:userId',
    name: 'FranchiseUserEdit',
    component: UserEdit,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/franchise',
    name: 'Franchise',
    component: Franchise,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/franchises',
    name: 'Franchises',
    component: Franchises,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/franchises/create',
    name: 'FranchiseCreate',
    component: FranchiseCreate,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/franchises/:id',
    name: 'FranchiseCard',
    component: FranchiseCard,
    children: [
      {
        path: 'users',
        name: 'FranchiseUserTable',
        component: FranchiseUserTable,
      },
      {
        path: 'projects',
        name: 'FranchiseProjectTable',
        component: FranchiseProjectTable,
      },
    ],
    meta: {
      layout: Default,
    },
  },
  {
    path: '/franchises/edit/:id',
    name: 'FranchiseEdit',
    component: FranchiseEdit,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/franchises/edit/:id/:doc',
    name: 'FranchiseDocs',
    component: FranchiseDocs,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/project/:id',
    name: 'ProjectCard',
    component: ProjectCard,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/project/create',
    name: 'ProjectCreate',
    component: ProjectCreate,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/project/edit/:id',
    name: 'ProjectEdit',
    component: ProjectEdit,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/templates',
    name: 'Templates',
    component: Templates,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/templates-categories',
    name: 'TemplatesCategories',
    component: TemplateCategories,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/templates-settings/:id',
    name: 'TemplateSetting',
    component: TemplateSetting,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/templates/card/:id/editor',
    name: 'Editor',
    component: () => import('@/views/Editor/Editor.vue'),
    meta: {
      layout: EditorLayout,
    },
  },
  {
    path: '/templates/card/:id',
    name: 'TemplateCard',
    component: TemplateCard,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/knowledge-base',
    name: 'KnowledgeBase',
    component: KnowledgeBase,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/knowledge-base/create/:id',
    name: 'KnowledgeArticle',
    component: KnowledgeCreateArticle,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/knowledge-base/edit/:id',
    name: 'KnowledgeArticleEdit',
    component: KnowledgeEditArticle,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/setting-site',
    name: 'SettingSite',
    component: Settings,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/service',
    name: 'Service',
    component: Service,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/service/add/:id',
    name: 'ServiceAdd',
    component: ServiceAdd,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/service/edit/:id',
    name: 'ServiceEdit',
    component: ServiceEdit,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/faq/create/:id',
    name: 'FaqCreate',
    component: FaqCreate,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/faq/edit/:id',
    name: 'FaqEdit',
    component: FaqEdit,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/reviews',
    name: 'Reviews',
    component: Reviews,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/reviews/create/:id',
    name: 'ReviewCreate',
    component: ReviewCreate,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/reviews/edit/:id',
    name: 'ReviewEdit',
    component: ReviewEdit,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contact,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/seo',
    name: 'Seo',
    component: SeoView,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/main-page',
    name: 'MainPage',
    component: MainPage,
    meta: {
      layout: Default,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const IS_LOGGED_IN = store.getters.isLoggedIn;
  const IS_ADMIN_GROUP = store.getters.getIsAdminGroup;
  if (to.name === 'RecoveryPassword') {
    next();
  }
  if (to.name !== 'SignIn' && !IS_LOGGED_IN) {
    next({ name: 'SignIn' });
  }
  if (to.name === 'SignIn' && IS_LOGGED_IN) {
    next({ name: 'Home' });
  }
  if (to.name === 'Franchises' && !IS_ADMIN_GROUP) {
    next({ name: 'Home' });
  }
  next();
});

export default router;
