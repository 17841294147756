import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '@/store';
import MainPageClient from '@/api/mainPage.api';
import {
  MainPageBenefitContent,
  MainPageCapabilityContent,
  MainPageContent,
  MainPageUpdateFilePayload,
} from '@/@typespaces/interfaces/form/mainPage-form.interface';
import { MainPageMutationTypes } from './mutation-types';
import { State } from './state';
import { Mutations } from './mutations';
import { MainPageActionTypes } from './action-types';

const client = new MainPageClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [MainPageActionTypes.FETCH_MAIN_PAGE]({ commit }: AugmentedActionContext): void;

  [MainPageActionTypes.EDIT_MAIN_PAGE]({ commit }: AugmentedActionContext, payload: MainPageContent): void;

  [MainPageActionTypes.ADD_BENEFIT]({ commit }: AugmentedActionContext): void;

  [MainPageActionTypes.ADD_CAPABILITY]({ commit }: AugmentedActionContext): void;
  [MainPageActionTypes.MAIN_PAGE_REMOVE_FILE](
    { commit }: AugmentedActionContext,
    payload: MainPageUpdateFilePayload
  ): void;

  [MainPageActionTypes.MAIN_PAGE_UPDATE_FILE](
    { commit }: AugmentedActionContext,
    payload: MainPageUpdateFilePayload
  ): void;

  [MainPageActionTypes.DELETE_BENEFIT]({ commit }: AugmentedActionContext, payload: string): void;

  [MainPageActionTypes.DELETE_CAPABILITY]({ commit }: AugmentedActionContext, payload: string): void;

  [MainPageActionTypes.UPDATE_BENEFITS](
    { commit }: AugmentedActionContext,
    payload: { benefits: MainPageBenefitContent[] }
  ): void;

  [MainPageActionTypes.UPDATE_CAPABILITIES](
    { commit }: AugmentedActionContext,
    payload: { capability: MainPageCapabilityContent[] }
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [MainPageActionTypes.FETCH_MAIN_PAGE]({ commit }) {
    commit(MainPageMutationTypes.MAIN_PAGE_LOADING);
    try {
      const data = await client.getMainPage();
      commit(MainPageMutationTypes.FETCH_MAIN_PAGE, data);
      commit(MainPageMutationTypes.MAIN_PAGE_SUCCEEDED);
    } catch (error) {
      commit(MainPageMutationTypes.MAIN_PAGE_ERROR);
      throw new Error(error as string);
    }
  },

  async [MainPageActionTypes.EDIT_MAIN_PAGE]({ commit }, payload: MainPageContent) {
    try {
      await client.editMainPage(payload);
    } catch (error) {
      commit(MainPageMutationTypes.MAIN_PAGE_ERROR);
      throw new Error(error as string);
    }
  },

  async [MainPageActionTypes.ADD_BENEFIT]({ commit }) {
    try {
      return await client.addBenefit();
    } catch (error) {
      commit(MainPageMutationTypes.MAIN_PAGE_ERROR);
      throw new Error(error as string);
    }
  },

  async [MainPageActionTypes.ADD_CAPABILITY]({ commit }) {
    try {
      return await client.addCapability();
    } catch (error) {
      commit(MainPageMutationTypes.MAIN_PAGE_ERROR);
      throw new Error(error as string);
    }
  },

  async [MainPageActionTypes.MAIN_PAGE_UPDATE_FILE]({ commit }, payload: MainPageUpdateFilePayload) {
    try {
      await client.updateFile(payload);
    } catch (error) {
      commit(MainPageMutationTypes.MAIN_PAGE_ERROR);
      throw new Error(error as string);
    }
  },

  async [MainPageActionTypes.DELETE_BENEFIT]({ commit }, payload: string) {
    try {
      await client.deleteBenefit(payload);
    } catch (error) {
      commit(MainPageMutationTypes.MAIN_PAGE_ERROR);
      throw new Error(error as string);
    }
  },

  async [MainPageActionTypes.DELETE_CAPABILITY]({ commit }, payload: string) {
    try {
      await client.deleteCapability(payload);
    } catch (error) {
      commit(MainPageMutationTypes.MAIN_PAGE_ERROR);
      throw new Error(error as string);
    }
  },

  async [MainPageActionTypes.UPDATE_BENEFITS]({ commit }, payload: { benefits: MainPageBenefitContent[] }) {
    try {
      await client.updateBenefits(payload);
    } catch (error) {
      commit(MainPageMutationTypes.MAIN_PAGE_ERROR);
      throw new Error(error as string);
    }
  },

  async [MainPageActionTypes.UPDATE_CAPABILITIES]({ commit }, payload: { capability: MainPageCapabilityContent[] }) {
    try {
      await client.updateCapabilities(payload);
    } catch (error) {
      commit(MainPageMutationTypes.MAIN_PAGE_ERROR);
      throw new Error(error as string);
    }
  },

  async [MainPageActionTypes.MAIN_PAGE_REMOVE_FILE]({ commit }, payload: MainPageUpdateFilePayload) {
    try {
      await client.removeFile(payload);
    } catch (error) {
      commit(MainPageMutationTypes.MAIN_PAGE_ERROR);
      throw new Error(error as string);
    }
  },
};
