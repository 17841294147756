// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum AuthMutationTypes {
  SIGN_IN = 'SIGN_IN',
  LOG_OUT = 'LOG_OUT',
  REFRESH_TOKEN = 'REFRESH_TOKEN',

  // Status
  AUTH_LOADING = 'AUTH_LOADING',
  AUTH_SUCCEEDED = 'AUTH_SUCCEEDED',
  AUTH_ERROR = 'AUTH_ERROR',
}
