/* eslint-disable camelcase */
import { IPayload } from '@/@typespaces/interfaces/payload.interfaces';
import { IAdmin } from '@/@typespaces/interfaces/admin.interface';
import { Query } from '@/@typespaces/types/query.types';
import { ADMIN_YETICRAB } from '@/@constants';
import InstanceHttpClient from './instance';

export default class AdminClient extends InstanceHttpClient {
  constructor() {
    super('map-admins');
  }

  async getAdmins(query: Query): Promise<IPayload<IAdmin>> {
    try {
      const adminsData: IPayload<IAdmin> = await this.apiCall({
        method: 'GET',
        params: query,
      });

      return {
        ...adminsData,
        data: {
          ...adminsData.data,
          users: adminsData.data.users.filter((item) => item.email !== ADMIN_YETICRAB),
        },
        total: ADMIN_YETICRAB ? adminsData.total - 1 : adminsData.total,
      };
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async addAdmin(body: Partial<IAdmin>): Promise<IAdmin> {
    try {
      return await this.apiCall({
        method: 'POST',
        data: body,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async editAdmin(data: Partial<IAdmin>): Promise<IAdmin> {
    const id = data._id;
    const body = data;
    delete body._id;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: id,
        data: body,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async deleteAdmin(id: string): Promise<void> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `${id}/remove`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async blockAdmin(id: string): Promise<void> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `${id}/block`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async unblockAdmin(id: string): Promise<void> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `${id}/unblock`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async blockAdmins({ ids }: { ids: string[] }): Promise<void> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: 'block-many',
        data: { usersIds: ids },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async unblockAdmins({ ids }: { ids: string[] }): Promise<void> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: 'unblock-many',
        data: { usersIds: ids },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async deleteAdmins({ ids }: { ids: string[] }): Promise<void> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: 'remove-many',
        data: { usersIds: ids },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }
}
