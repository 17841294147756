// Core
import { RuleObject } from 'ant-design-vue/es/form';

// Constants
import { EMAIL_REGEX, PHONE_REGEX } from '@/@constants/regExp.constants';
import { removeTags } from '@/utils/removeTags';

export const validateEmptyField = (message: string) => async (_rule: RuleObject, value: string) => {
  if (!value) {
    return Promise.reject(new Error(message));
  }
  if (value === 'Выберите') {
    return Promise.reject(new Error(message));
  }
  return Promise.resolve();
};

export const validateEmail = () => async (_rule: RuleObject, value: string) => {
  if (!value && _rule.required) {
    return Promise.reject(new Error('Пожалуйста, введите электронную почту'));
  }
  if (value && !value.match(EMAIL_REGEX)) {
    return Promise.reject(new Error('Пожалуйста, введите корректный адрес электронной почты'));
  }
  return Promise.resolve();
};

export const validatePhone = () => async (_rule: RuleObject, value: string) => {
  if (!value.toString() && _rule.required) {
    return Promise.reject(new Error('Пожалуйста, введите телефон'));
  }
  if ((value.toString() && !value.toString().match(PHONE_REGEX)) || value.toString().length > 20) {
    return Promise.reject(new Error('Пожалуйста, введите корректный телефон'));
  }
  return false;
};

export const validatePass = () => async (_rule: RuleObject, value: string) => {
  if (value === '') {
    return Promise.reject(new Error('Пожалуйста, введите пароль'));
  }
  if (value.trim().length < 6) {
    return Promise.reject(new Error('Длина пароля должна быть больше 6 символов'));
  }
  return Promise.resolve();
};

export const validateConfirmPass = (confirm_value: string) => async (_rule: RuleObject, value: string) => {
  if (!value) {
    return Promise.reject(new Error('Пожалуйста, повторите пароль'));
  }
  if (value !== confirm_value) {
    return Promise.reject(new Error('Пароли не совпадают'));
  }
  return Promise.resolve();
};

export const validateHtmlField = (message: string) => async (_rule: RuleObject, value: string) => {
  if (!value?.trim()) {
    return Promise.reject(new Error(message));
  }
  if (!removeTags(value).trim()) {
    return Promise.reject(new Error(message));
  }
  return Promise.resolve();
};

export const validateRole = async (_rule: RuleObject, value: string) => {
  if (!value) {
    return Promise.reject(new Error('Пожалуйста, выберите роль'));
  }
  if (value === 'Выберите') {
    return Promise.reject(new Error('Пожалуйста, выберите роль'));
  }
  return Promise.resolve();
};

export const validateFirstName = async (_rule: RuleObject, value: string) => {
  if (!value) {
    return Promise.reject(new Error('Пожалуйста, введите имя'));
  }
  return Promise.resolve();
};

export const validateSurName = async (_rule: RuleObject, value: string) => {
  if (!value) {
    return Promise.reject(new Error('Пожалуйста, введите фамилию'));
  }
  return Promise.resolve();
};

export const checkinInputPassword = async (_rule: RuleObject, value: string, value2: string) => {
  if (value === '') {
    return Promise.reject(new Error('Пожалуйста, повторите пароль'));
  }
  if (value !== value2) {
    return Promise.reject(new Error('Пароли не совпадают'));
  }
  return Promise.resolve();
};

export const validatePassForEditForm = async (_rule: RuleObject, value: string, isChange: boolean) => {
  if (!isChange) {
    return Promise.resolve();
  }
  if (value === '') {
    return Promise.reject(new Error('Пожалуйста, введите пароль'));
  }
  if (value.trim().length < 6) {
    return Promise.reject(new Error('Длина пароля должна быть больше 6 символов'));
  }
  return Promise.resolve();
};

export const checkinInputPasswordForEditForm = async (
  _rule: RuleObject,
  value: string,
  value2: string,
  isChange: boolean
) => {
  if (!isChange) {
    return Promise.resolve();
  }
  if (value === '') {
    return Promise.reject(new Error('Пожалуйста, повторите пароль'));
  }
  if (value !== value2) {
    return Promise.reject(new Error('Пароли не совпадают'));
  }
  return Promise.resolve();
};

export const validateFranchise = async (_rule: RuleObject, value: string) => {
  if (!value) {
    return Promise.reject(new Error('Пожалуйста, выберите франшизу'));
  }
  if (value === 'Выберите') {
    return Promise.reject(new Error('Пожалуйста, выберите франшизу'));
  }
  return Promise.resolve();
};

export const validateProject = async (_rule: RuleObject, value: string) => {
  if (!value) {
    return Promise.reject(new Error('Пожалуйста, выберите проект'));
  }
  if (value === 'Выберите') {
    return Promise.reject(new Error('Пожалуйста, выберите проект'));
  }
  return Promise.resolve();
};
