import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '@/store';
import FaqClient from '@/api/faq.api';
import { FaqFormState, FaqSolution, FaqSolutionPriority } from '@/@typespaces/interfaces/form/faq-form.interface';
import { FaqMutationTypes } from './mutation-types';
import { State } from './state';
import { Mutations } from './mutations';
import { FaqActionTypes } from './action-types';

const client = new FaqClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [FaqActionTypes.FETCH_FAQS]({ commit }: AugmentedActionContext): void;

  [FaqActionTypes.EDIT_FAQ]({ commit }: AugmentedActionContext, payload: FaqFormState): void;

  [FaqActionTypes.ADD_FAQ_SOLUTIONS]({ commit }: AugmentedActionContext): void;

  [FaqActionTypes.DELETE_FAQ_SOLUTIONS]({ commit }: AugmentedActionContext, id: string): void;

  [FaqActionTypes.EDIT_FAQ_SOLUTIONS](
    { commit }: AugmentedActionContext,
    payload: { id: string; data: FaqSolution }
  ): void;

  [FaqActionTypes.EDIT_FAQ_SOLUTIONS_PRIORITY](
    { commit }: AugmentedActionContext,
    payload: FaqSolutionPriority[]
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [FaqActionTypes.FETCH_FAQS]({ commit }) {
    commit(FaqMutationTypes.FAQ_LOADING);
    try {
      const data = await client.getFaq();
      commit(FaqMutationTypes.FETCH_FAQS, data);
      commit(FaqMutationTypes.FAQ_SUCCEEDED);
    } catch (error: any) {
      commit(FaqMutationTypes.FAQ_ERROR, error);
      throw new Error(error);
    }
  },

  async [FaqActionTypes.EDIT_FAQ]({ commit }, payload: FaqFormState) {
    try {
      await client.editFaq(payload);
    } catch (error: any) {
      commit(FaqMutationTypes.FAQ_ERROR, error);
      throw new Error(error);
    }
  },

  async [FaqActionTypes.ADD_FAQ_SOLUTIONS]({ commit }) {
    commit(FaqMutationTypes.FAQ_LOADING);
    try {
      commit(FaqMutationTypes.FAQ_SUCCEEDED);
      return await client.addSolutions();
    } catch (error: any) {
      commit(FaqMutationTypes.FAQ_ERROR, error);
      throw new Error(error);
    }
  },

  async [FaqActionTypes.DELETE_FAQ_SOLUTIONS]({ commit }, id: string) {
    commit(FaqMutationTypes.FAQ_TABLE_LOADING);
    try {
      await client.deleteSolutions(id);
      commit(FaqMutationTypes.DELETE_FAQ_SOLUTIONS, id);
      commit(FaqMutationTypes.FAQ_TABLE_SUCCEEDED);
    } catch (error: any) {
      commit(FaqMutationTypes.FAQ_TABLE_ERROR, error);
      throw new Error(error);
    }
  },

  async [FaqActionTypes.EDIT_FAQ_SOLUTIONS]({ commit }, payload: { id: string; data: FaqSolution }) {
    commit(FaqMutationTypes.FAQ_LOADING);
    try {
      await client.editSolutions(payload);
      commit(FaqMutationTypes.FAQ_LOADING);
    } catch (error) {
      commit(FaqMutationTypes.FAQ_ERROR, (error as Error).message);
      throw new Error((error as Error).message);
    }
  },

  async [FaqActionTypes.EDIT_FAQ_SOLUTIONS_PRIORITY]({ commit }, payload: FaqSolutionPriority[]) {
    commit(FaqMutationTypes.FAQ_TABLE_LOADING);
    try {
      await client.editSolutionsPriority(payload);
      commit(FaqMutationTypes.FAQ_TABLE_SUCCEEDED);
    } catch (error: any) {
      commit(FaqMutationTypes.FAQ_TABLE_ERROR, error);
      throw new Error(error);
    }
  },
};
