import { Franchise } from '@/@typespaces/interfaces/franchise.interface';
import InstanceHttpClient from './instance';

export default class DomainClient extends InstanceHttpClient {
  constructor() {
    super('domains');
  }

  async checkDomain({ franchiseId, domain }: { franchiseId: string; domain: string }): Promise<{
    cname: boolean;
    wcCname: boolean;
    https: boolean;
    preview_https: boolean;
    aRecord: boolean;
  }> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: `${franchiseId}/check-domain`,
        data: { domain },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async checkExistDomain({ franchiseId, domain }: { franchiseId: string; domain: string }): Promise<boolean> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: `${franchiseId}/check-exist-domain`,
        data: { domain },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async checkExpiryDate({ franchiseId, domain }: { franchiseId: string; domain: string }): Promise<any> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: `${franchiseId}/check-expiry-date`,
        data: { domain },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async checkWildcardCert({
    franchiseId,
    body,
  }: {
    franchiseId: string;
    body: FormData;
  }): Promise<{ success: boolean; message: string; domain?: string; expiryDate?: number; isWcCert: boolean }> {
    try {
      return await this.apiCall({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'POST',
        url: `${franchiseId}/check-cert-by-files`,
        data: body,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async removeCertOrKey({ franchiseId, type }: { type: 'cert' | 'key'; franchiseId: string }) {
    try {
      return await this.apiCall({
        method: 'POST',
        url: `${franchiseId}/remove-cert`,
        data: { type },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async connectDomain({ franchiseId, body }: { franchiseId: string; body: FormData }): Promise<Franchise> {
    try {
      return await this.apiCall({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'POST',
        url: `${franchiseId}/connect-domain`,
        data: body,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async connectSSLToDomain({ franchiseId, body }: { franchiseId: string; body: FormData }): Promise<Franchise> {
    try {
      return await this.apiCall({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'POST',
        url: `${franchiseId}/connect-ssl-to-domain`,
        data: body,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async changeHttpsStatus({ franchiseId, domain }: { franchiseId: string; domain: string }): Promise<boolean> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: `${franchiseId}/change-http-status`,
        data: { domain },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }
}
