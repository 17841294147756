import { IProfile } from '@/@typespaces/interfaces/profile.interface';
import InstanceHttpClient from './instance';

export default class ProfileClient extends InstanceHttpClient {
  constructor() {
    super('profile');
  }

  async getProfile(): Promise<IProfile> {
    try {
      return await this.apiCall({
        method: 'GET',
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async editProfile(body: IProfile): Promise<{ success: true }> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        data: body,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }
}
