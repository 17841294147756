// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum AdminsMutationTypes {
  SET_DATA_ADMINS = 'SET_DATA_ADMINS',
  LOADING = 'LOADING',
  SUCCEEDED = 'SUCCEEDED',
  ERROR = 'ERROR',
  DELETE_ADMIN = 'DELETE_ADMIN',
  DELETE_ADMINS = 'DELETE_ADMINS',
  BLOCK_ADMIN = 'BLOCK_ADMIN',
  BLOCK_ADMINS = 'BLOCK_ADMINS',
  UNBLOCK_ADMINS = 'UNBLOCK_ADMINS',
  ADD_ADMIN = 'ADD_ADMIN',
  EDIT_ADMIN = 'EDIT_ADMIN',
  UNBLOCK_ADMIN = 'UNBLOCK_ADMIN',
}
