import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { TemplateCategory } from '@/@typespaces/interfaces/templates.interface';
import Status from '@/@typespaces/enum/status.enum';
import { State } from './state';

export type Getters = {
  getTemplatesCategories(state: State): TemplateCategory[];
  getTemplatesCategoriesStatus(state: State): Status;
  getChangeableCategory(state: State): TemplateCategory | null;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getTemplatesCategories: (state) => state.data,
  getTemplatesCategoriesStatus: (state) => state.status,
  getChangeableCategory: (state) => state.changeableCategory,
};
