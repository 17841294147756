import { CreateTemplateCategory, TemplateCategory } from '@/@typespaces/interfaces/templates.interface';
import { Query } from '@/@typespaces/types/query.types';
import { AxiosError } from 'axios';
import InstanceHttpClient from './instance';

export default class TmapTemplatesCategoriesClient extends InstanceHttpClient {
  constructor() {
    super('tmap-template-categories');
  }

  async getTemplatesCategories(query?: Query): Promise<TemplateCategory[]> {
    try {
      return await this.apiCall({
        method: 'GET',
        params: query,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async getOneTemplateCategory(id: string): Promise<TemplateCategory> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: id,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async createTemplateCategory(body: CreateTemplateCategory): Promise<TemplateCategory> {
    try {
      return await this.apiCall({
        method: 'POST',
        data: body,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async editTemplateCategory(body: TemplateCategory): Promise<TemplateCategory> {
    const id = body._id;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: id,
        data: body,
      });
    } catch (err: any) {
      throw new Error((err as AxiosError).response?.status.toString());
    }
  }

  async deleteTemplateCategory(id: string) {
    try {
      await this.apiCall({
        method: 'DELETE',
        url: id,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }
}
