import { Article, Category, KnowledgeBase } from '@/@typespaces/interfaces/knowledgeBase.interface';
import Status from '@/@typespaces/enum/status.enum';

export interface KnowledgeBaseState {
  data: KnowledgeBase | null;
  categories: Category[];
  articles: Article[];
  currentCategory: Category | null;
  page: number;
  pageSize: number;
  total: number;
  status: Status;
  categoriesStatus: Status;
  articlesStatus: Status;
  error: string | null;
}

export const state: KnowledgeBaseState = {
  data: null,
  categories: [],
  articles: [],
  currentCategory: null,
  page: 0,
  pageSize: 0,
  total: 0,
  status: Status.IDLE,
  categoriesStatus: Status.IDLE,
  articlesStatus: Status.IDLE,
  error: null,
};
