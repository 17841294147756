import { MainPage } from '@/@typespaces/interfaces/mainPageInterface';
import {
  MainPageBenefitContent,
  MainPageCapabilityContent,
  MainPageContent,
  MainPageUpdateFilePayload,
} from '@/@typespaces/interfaces/form/mainPage-form.interface';
import InstanceHttpClient from './instance';

export default class MainPageClient extends InstanceHttpClient {
  constructor() {
    super('public-page/main-page');
  }

  async getMainPage(): Promise<MainPage> {
    try {
      return await this.apiCall({
        method: 'GET',
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async editMainPage(data: MainPageContent) {
    try {
      await this.apiCall({
        method: 'PATCH',
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async addBenefit(): Promise<{ benefit_id: string }> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: 'add-benefit',
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async addCapability(): Promise<{ capability_id: string }> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: 'add-capability',
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async updateFile(data: MainPageUpdateFilePayload) {
    try {
      await this.apiCall({
        method: 'PATCH',
        url: 'update-file',
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async deleteBenefit(benefit_id: string) {
    try {
      await this.apiCall({
        method: 'PATCH',
        url: 'delete-benefit',
        data: { benefit_id },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async deleteCapability(capability_id: string) {
    try {
      await this.apiCall({
        method: 'PATCH',
        url: 'delete-capability',
        data: { capability_id },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async updateBenefits(data: { benefits: MainPageBenefitContent[] }) {
    try {
      await this.apiCall({
        method: 'PATCH',
        url: 'update-benefits',
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async updateCapabilities(data: { capability: MainPageCapabilityContent[] }) {
    try {
      await this.apiCall({
        method: 'PATCH',
        url: 'update-capabilities',
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async removeFile(data: MainPageUpdateFilePayload) {
    try {
      await this.apiCall({
        method: 'PATCH',
        url: 'delete-file',
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }
}
