import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { Settings } from '@/@typespaces/interfaces/settings.interface';
import Status from '@/@typespaces/enum/status.enum';
import { State } from './state';

export type Getters = {
  getSettings(state: State): Settings | null;
  getSettingsLoading(state: State): boolean;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getSettings: (state) => state.data,
  getSettingsLoading: (state) => state.status === Status.LOADING,
};
