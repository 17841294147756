/* eslint-disable import/no-cycle */
import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { IAdmin } from '@/@typespaces/interfaces/admin.interface';
import Status from '@/@typespaces/enum/status.enum';
import { IRole } from '@/@typespaces/interfaces/role.interfaces';
import { PaginationData } from '@/@typespaces/types/paginationData';
import { State } from './state';

export type Getters = {
  getAdmins(state: State): IAdmin[];
  getAdminsIds(state: State): string[];
  getAdminRoles(state: State): IRole[];
  getAdminsLoading(state: State): boolean;
  getAdminsPagination(state: State): PaginationData;
  getAdminById: (state: State) => (id: string) => IAdmin | undefined;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getAdmins: (state) => state.data,
  getAdminsIds: (state) => state.usersIds,
  getAdminRoles: (state) => state.roles,
  getAdminsLoading: (state) => state.status === Status.LOADING,
  getAdminsPagination: (state) => state.pagination,
  getAdminById: (state) => (id: string) => state.data.find((admin) => admin._id === id),
};
