import { User, UserProjects, ShortUser } from '@/@typespaces/interfaces/user.interface';
import { IRole } from '@/@typespaces/interfaces/role.interfaces';
import Status from '@/@typespaces/enum/status.enum';

export interface State {
  data: ShortUser[];
  user: User | null;
  usersIds: string[];
  roles: IRole[];
  status: Status;
  error: null | string;
  pagination: {
    page: number;
    pageSize: number;
    total: number;
  };
  userProjects: {
    projects: UserProjects[];
    projectsIds: string[];
    projectUsersIds: string[];
    status: Status;
    error: null | string;
    pagination: {
      page: number;
      pageSize: number;
      total: number;
    };
  };
}

export const state: State = {
  data: [],
  user: null,
  usersIds: [],
  roles: [],
  status: Status.IDLE,
  error: null,
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
  },
  userProjects: {
    projects: [],
    projectsIds: [],
    projectUsersIds: [],
    status: Status.IDLE,
    error: null,
    pagination: {
      page: 1,
      pageSize: 10,
      total: 0,
    },
  },
};
