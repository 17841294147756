// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum TemplatesMutationTypes {
  FETCH_TEMPLATES = 'FETCH_TEMPLATES',
  FETCH_ONE_TEMPLATE = 'FETCH_ONE_TEMPLATE',
  CLEAR_ONE_TEMPLATE = 'CLEAR_ONE_TEMPLATE',
  EDIT_TEMPLATE = 'EDIT_TEMPLATE',
  CREATE_TEMPLATE = 'CREATE_TEMPLATE',
  DELETE_TEMPLATE = 'DELETE_TEMPLATE',
  PUBLISH_TEMPLATE = 'PUBLISH_TEMPLATE',
  UN_PUBLISH_TEMPLATE = 'UN_PUBLISH_TEMPLATE',
  DUPLICATE_TEMPLATE = 'DUPLICATE_TEMPLATE',
  // Status
  TEMPLATES_LOADING = 'TEMPLATES_LOADING',
  TEMPLATES_SUCCEEDED = 'TEMPLATES_SUCCEEDED',
  TEMPLATES_ERROR = 'TEMPLATES_ERROR',
}
