import { IRole } from '@/@typespaces/interfaces/role.interfaces';
import InstanceHttpClient from './instance';

export default class RoleClient extends InstanceHttpClient {
  constructor() {
    super('roles');
  }

  async getFranchiseGroupRoles(): Promise<IRole[]> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: `franchise-group`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async getProjectGroupRoles(): Promise<IRole[]> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: `project-group`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }
}
