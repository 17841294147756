import { IPayloadProjectsByUserId } from '@/@typespaces/interfaces/payload.interfaces';
import { User, UserPayload } from '@/@typespaces/interfaces/user.interface';
import {
  ProjectUserChangeRole,
  UserProjectBlock,
  UserProjectRemove,
  UserProjectsBlock,
  UserProjectUnblock,
} from '@/@typespaces/types/user.types';
import { Query } from '@/@typespaces/types/query.types';
import InstanceHttpClient from '../instance';

export default class MapUserClient extends InstanceHttpClient {
  constructor() {
    super('map-users');
  }

  async getUsers(query: Query): Promise<UserPayload> {
    try {
      return await this.apiCall({
        method: 'GET',
        params: query,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async getUser(id: string): Promise<User> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: id,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async addUser(body: Partial<User>): Promise<User> {
    try {
      return await this.apiCall({
        method: 'POST',
        data: body,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async editUser(data: { id: string; body: Partial<User> }): Promise<{ success: true }> {
    const { id, body } = data;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: id,
        data: body,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async blockUser(id: string): Promise<void> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `${id}/block`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async unblockUser(id: string): Promise<void> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `${id}/unblock`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async blockUsers({ ids }: { ids: string[] }): Promise<void> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: 'block-many',
        data: { usersIds: ids },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async unblockUsers({ ids }: { ids: string[] }): Promise<void> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: 'unblock-many',
        data: { usersIds: ids },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async deleteUser(id: string): Promise<void> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `${id}/remove`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async deleteUsers({ ids }: { ids: string[] }): Promise<void> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: 'remove-many',
        data: { usersIds: ids },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  // Методы для таблицы проектов у пользователя

  async getProjectsByUserId(data: { id: string; query: any }): Promise<IPayloadProjectsByUserId> {
    try {
      const { id, query } = data;
      return await this.apiCall({
        method: 'GET',
        url: `/${id}/projects`,
        params: {
          ...query,
        },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async blockProjectOfUser({ userId, projectId }: UserProjectBlock): Promise<{ success: true }> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `${userId}/projects/${projectId}/block`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async unblockProjectOfUser({ userId, projectId }: UserProjectUnblock) {
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `${userId}/projects/${projectId}/unblock`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async blockProjectsOfUser({ generalUserId, projectUsersIds }: UserProjectsBlock) {
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `${generalUserId}/projects/block-many`,
        data: { projectUsersIds },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async unblockProjectsOfUser({ generalUserId, projectUsersIds }: UserProjectsBlock): Promise<{ success: true }> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `${generalUserId}/projects/unblock-many`,
        data: { projectUsersIds },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async removeProjectOfUser({ userId, projectId }: UserProjectRemove) {
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `${userId}/projects/${projectId}/remove`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async changeRoleOfProjectUser({ projectId, userId, roleId }: ProjectUserChangeRole) {
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `${userId}/projects/${projectId}/change-role`,
        data: { roleId },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }
}
