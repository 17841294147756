import { MutationTree } from 'vuex';
import Status from '@/@typespaces/enum/status.enum';
import { FaqData } from '@/@typespaces/interfaces/faq.interface';
import { State } from './state';
import { FaqMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [FaqMutationTypes.FETCH_FAQS](state: S, payload: FaqData): void;
  [FaqMutationTypes.DELETE_FAQ_SOLUTIONS](state: S, payload: string): void;

  // Status
  [FaqMutationTypes.FAQ_LOADING](state: S): void;
  [FaqMutationTypes.FAQ_SUCCEEDED](state: S): void;
  [FaqMutationTypes.FAQ_ERROR](state: S, payload: string): void;

  // Status
  [FaqMutationTypes.FAQ_TABLE_LOADING](state: S): void;
  [FaqMutationTypes.FAQ_TABLE_SUCCEEDED](state: S): void;
  [FaqMutationTypes.FAQ_TABLE_ERROR](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [FaqMutationTypes.FETCH_FAQS](state: State, payload: FaqData) {
    state.data = payload.faq;
  },
  [FaqMutationTypes.DELETE_FAQ_SOLUTIONS](state: State, id: string) {
    if (state.data) {
      const content = state.data.content.filter((item) => item._id !== id);
      state.data = {
        ...state.data,
        content,
      };
    }
  },
  // Status

  [FaqMutationTypes.FAQ_LOADING](state: State) {
    state.status = Status.LOADING;
  },
  [FaqMutationTypes.FAQ_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
  },
  [FaqMutationTypes.FAQ_ERROR](state: State, error: string) {
    state.status = Status.FAILED;
    state.error = error;
  },

  [FaqMutationTypes.FAQ_TABLE_LOADING](state: State) {
    state.tableStatus = Status.LOADING;
  },
  [FaqMutationTypes.FAQ_TABLE_SUCCEEDED](state: State) {
    state.tableStatus = Status.SUCCEEDED;
  },
  [FaqMutationTypes.FAQ_TABLE_ERROR](state: State) {
    state.tableStatus = Status.FAILED;
  },
};
