import { MutationTree } from 'vuex';
import { Seo, SeoData } from '@/@typespaces/interfaces/seo.interface';
import Status from '@/@typespaces/enum/status.enum';
import { State } from './state';
import { SeoMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [SeoMutationTypes.FETCH_SEO](state: S, payload: SeoData): void;
  [SeoMutationTypes.EDIT_SEO](state: S, payload: Seo): void;
  [SeoMutationTypes.SEO_LOADING](state: S): void;
  [SeoMutationTypes.SEO_SUCCEEDED](state: S): void;
  [SeoMutationTypes.SEO_ERROR](state: S): void;
};
export const mutations: MutationTree<State> & Mutations = {
  [SeoMutationTypes.FETCH_SEO](state: State, payload: SeoData) {
    state.data = payload.seo;
  },
  [SeoMutationTypes.EDIT_SEO](state: State, payload: Seo) {
    state.data = payload;
  },
  [SeoMutationTypes.SEO_LOADING](state: State) {
    state.status = Status.LOADING;
  },
  [SeoMutationTypes.SEO_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
  },
  [SeoMutationTypes.SEO_ERROR](state: State) {
    state.status = Status.FAILED;
  },
};
