import Status from '@/@typespaces/enum/status.enum';

export interface State {
  collapseMenu: boolean;
  hide_warning: boolean;
  file: {
    fileId: string | null;
    error: string | null;
    status: Status;
  };
}

export const state: State = {
  collapseMenu: false,
  hide_warning: false,
  file: {
    status: Status.IDLE,
    fileId: null,
    error: null,
  },
};
