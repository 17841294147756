import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { Project, ShortenedProject, ShortProject } from '@/@typespaces/interfaces/project.interface';
import Status from '@/@typespaces/enum/status.enum';
import { IRole } from '@/@typespaces/interfaces/role.interfaces';
import { PaginationData } from '@/@typespaces/types/paginationData';
import { State } from './state';

export type Getters = {
  getProjectsData(state: State): ShortProject[];
  getProjectsIds(state: State): string[];
  getProjectsPagination(state: State): PaginationData;
  getOneProject(state: State): Project | null;
  getProjectById: (state: State) => (id: string) => ShortProject | undefined;
  getProjectsLoading(status: State): boolean;
  getShortenedProjects(state: State): ShortenedProject[];
  // Users
  getProjectUsersData(state: State): any[];
  getProjectUsersIds(state: State): string[];
  getProjectUsersRoles(state: State): IRole[];
  getProjectUsersPagination(state: State): PaginationData;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getProjectsData: (state) => state.projects,
  getProjectsIds: (state) => state.projectsIds,
  getOneProject: (state) => state.oneProject,
  getProjectsLoading: (state) => state.status === Status.LOADING,
  getProjectsPagination: (state: State) => state.pagination,
  getProjectById: (state) => (id: string) => state.projects.find((project) => project._id === id),
  getShortenedProjects: (state) => state.shortProjects,
  // Users
  getProjectUsersData: (state) => state.projectUsersData.users,
  getProjectUsersIds: (state) => state.projectUsersData.usersIds,
  getProjectUsersRoles: (state) => state.roles,
  getProjectUsersPagination: (state) => state.projectUsersData.pagination,
};
