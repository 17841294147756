/* eslint-disable import/no-cycle */
import BaseError from '@/components/BaseError.vue';
import createVNodeError from '@/helpers/createVNodeError';
import { ActionTree, ActionContext } from 'vuex';
import { message } from 'ant-design-vue';
import { RootState } from '@/store';
import { IAdmin } from '@/@typespaces/interfaces/admin.interface';
import AdminClient from '@/api/admins.api';
import FranchiseAdminsClient from '@/api/franchise-admins.api';
import { State } from './state';
import { Mutations } from './mutations';
import { AdminsMutationTypes } from './mutation-types';
import { AdminsActionTypes } from './action-types';

const adminClient = new AdminClient();
const franchiseAdminClient = new FranchiseAdminsClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [AdminsActionTypes.GET_ADMINS]({ commit }: AugmentedActionContext, data: any): void;

  [AdminsActionTypes.ADD_ADMIN]({ commit }: AugmentedActionContext, data: IAdmin): void;

  [AdminsActionTypes.EDIT_ADMIN]({ commit }: AugmentedActionContext, data: Partial<IAdmin>): void;

  [AdminsActionTypes.BLOCK_ADMIN]({ commit }: AugmentedActionContext, id: string): void;

  [AdminsActionTypes.UNBLOCK_ADMIN]({ commit }: AugmentedActionContext, id: string): void;

  [AdminsActionTypes.BLOCK_ADMINS]({ commit }: AugmentedActionContext, data: { ids: string[] }): void;

  [AdminsActionTypes.UNBLOCK_ADMINS]({ commit }: AugmentedActionContext, data: { ids: string[] }): void;

  [AdminsActionTypes.DELETE_ADMIN]({ commit }: AugmentedActionContext, id: string): void;

  [AdminsActionTypes.DELETE_ADMINS]({ commit }: AugmentedActionContext, data: { ids: string[] }): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [AdminsActionTypes.GET_ADMINS]({ commit, getters }, data) {
    commit(AdminsMutationTypes.LOADING);
    try {
      const isGroupAdmin = getters.getGroupName;
      let admins;
      if (isGroupAdmin === 'admins') {
        admins = await adminClient.getAdmins(data);
      } else {
        admins = await franchiseAdminClient.getAdmins(data);
      }
      commit(AdminsMutationTypes.SUCCEEDED);
      commit(AdminsMutationTypes.SET_DATA_ADMINS, admins);
    } catch (error: any) {
      commit(AdminsMutationTypes.ERROR, error);
    }
  },
  async [AdminsActionTypes.ADD_ADMIN]({ commit, getters }, data: IAdmin) {
    try {
      const isGroupAdmin = getters.getGroupName;
      let admin;
      if (isGroupAdmin === 'admins') {
        admin = await adminClient.addAdmin(data);
      } else {
        admin = await franchiseAdminClient.addAdmin(data);
      }
      commit(AdminsMutationTypes.ADD_ADMIN, admin);
    } catch (error: any) {
      commit(AdminsMutationTypes.ERROR, error);
      throw new Error(error);
    }
  },
  async [AdminsActionTypes.EDIT_ADMIN]({ commit, getters }, data: IAdmin) {
    try {
      const isGroupAdmin = getters.getGroupName;
      if (isGroupAdmin === 'admins') {
        await adminClient.editAdmin(data);
      } else {
        await franchiseAdminClient.editAdmin(data);
      }
      commit(AdminsMutationTypes.EDIT_ADMIN, data);
    } catch (error: any) {
      commit(AdminsMutationTypes.ERROR, error);
      throw new Error(error);
    }
  },
  async [AdminsActionTypes.DELETE_ADMIN]({ commit, getters }, id: string) {
    try {
      const isGroupAdmin = getters.getGroupName;
      if (isGroupAdmin === 'admins') {
        await adminClient.deleteAdmin(id);
      } else {
        await franchiseAdminClient.deleteAdmin(id);
      }
      commit(AdminsMutationTypes.DELETE_ADMIN, id);
    } catch (error: any) {
      commit(AdminsMutationTypes.ERROR, error);
      if (error instanceof Error) {
        const errorMessage = error.message;
        message.error(createVNodeError(BaseError, 'Удалить невозможно', errorMessage));
      }
      throw new Error(error);
    }
  },
  async [AdminsActionTypes.DELETE_ADMINS]({ commit, getters }, data: { ids: string[]; remove: string }) {
    try {
      const isGroupAdmin = getters.getGroupName;
      if (isGroupAdmin === 'admins') {
        await adminClient.deleteAdmins(data);
      } else {
        await franchiseAdminClient.deleteAdmins(data);
      }
      commit(AdminsMutationTypes.DELETE_ADMINS, data.ids);
      return;
    } catch (error: any) {
      commit(AdminsMutationTypes.ERROR, error);
      if (error instanceof Error) {
        const errorMessage = error.message;
        message.error(createVNodeError(BaseError, 'Удалить невозможно', errorMessage));
      }
      throw new Error(error);
    }
  },
  async [AdminsActionTypes.BLOCK_ADMIN]({ commit, getters }, id: string) {
    try {
      const isGroupAdmin = getters.getGroupName;
      if (isGroupAdmin === 'admins') {
        await adminClient.blockAdmin(id);
      } else {
        await franchiseAdminClient.blockAdmin(id);
      }
      commit(AdminsMutationTypes.BLOCK_ADMIN, id);
    } catch (error: any) {
      commit(AdminsMutationTypes.ERROR, error);
      throw new Error(error);
    }
  },
  async [AdminsActionTypes.UNBLOCK_ADMIN]({ commit, getters }, id: string) {
    try {
      const isGroupAdmin = getters.getGroupName;
      if (isGroupAdmin === 'admins') {
        await adminClient.unblockAdmin(id);
      } else {
        await franchiseAdminClient.unblockAdmin(id);
      }
      commit(AdminsMutationTypes.UNBLOCK_ADMIN, id);
    } catch (error: any) {
      commit(AdminsMutationTypes.ERROR, error);
      throw new Error(error);
    }
  },
  async [AdminsActionTypes.BLOCK_ADMINS]({ commit, getters }, data: { ids: string[] }) {
    try {
      const isGroupAdmin = getters.getGroupName;
      if (isGroupAdmin === 'admins') {
        await adminClient.blockAdmins(data);
      } else {
        await franchiseAdminClient.blockAdmins(data);
      }
      commit(AdminsMutationTypes.BLOCK_ADMINS, data.ids);
    } catch (error: any) {
      commit(AdminsMutationTypes.ERROR, error);
      throw new Error(error);
    }
  },
  async [AdminsActionTypes.UNBLOCK_ADMINS]({ commit, getters }, data: { ids: string[] }) {
    try {
      const isGroupAdmin = getters.getGroupName;
      if (isGroupAdmin === 'admins') {
        await adminClient.unblockAdmins(data);
      } else {
        await franchiseAdminClient.unblockAdmins(data);
      }
      commit(AdminsMutationTypes.UNBLOCK_ADMINS, data.ids);
    } catch (error: any) {
      commit(AdminsMutationTypes.ERROR, error);
      throw new Error(error);
    }
  },
};
