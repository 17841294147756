import { DuplicateTemplateData } from '@/@typespaces/types/template.type';
import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store';
import { TemplatesMutationTypes } from '@/store/modules/templates/mutation-types';
import MapTemplatesClient from '@/api/map/map-templates.api';
import TmapTemplatesClient from '@/api/tmap/tmap-templates.api';
import { Query } from '@/@typespaces/types/query.types';
import { Template } from '@/@typespaces/interfaces/templates.interface';
import { State } from './state';
import { Mutations } from './mutations';
import { TemplatesActionTypes } from './action-types';

const mapClient = new MapTemplatesClient();
const tmapClient = new TmapTemplatesClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [TemplatesActionTypes.FETCH_TEMPLATES]({ commit }: AugmentedActionContext, query: Query): void;

  [TemplatesActionTypes.FETCH_ONE_TEMPLATE]({ commit }: AugmentedActionContext, id: string): void;

  [TemplatesActionTypes.CREATE_TEMPLATE]({ commit }: AugmentedActionContext, data: Partial<Template>): void;

  [TemplatesActionTypes.PUBLISH_TEMPLATE]({ commit }: AugmentedActionContext, payload: string): void;

  [TemplatesActionTypes.UN_PUBLISH_TEMPLATE]({ commit }: AugmentedActionContext, payload: string): void;

  [TemplatesActionTypes.DUPLICATE_TEMPLATE]({ commit }: AugmentedActionContext, payload: DuplicateTemplateData): void;

  [TemplatesActionTypes.EDIT_TEMPLATE]({ commit }: AugmentedActionContext, template: Template): void;

  [TemplatesActionTypes.DELETE_TEMPLATE]({ commit }: AugmentedActionContext, id: string): void;
  [TemplatesActionTypes.CLEAR_ONE_TEMPLATE]({ commit }: AugmentedActionContext): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [TemplatesActionTypes.FETCH_TEMPLATES]({ commit, getters }, query: Query) {
    commit(TemplatesMutationTypes.TEMPLATES_LOADING);
    try {
      let data;
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        data = await mapClient.getTemplates(query);
      } else {
        data = await tmapClient.getTemplates(query);
      }
      commit(TemplatesMutationTypes.TEMPLATES_SUCCEEDED);
      commit(TemplatesMutationTypes.FETCH_TEMPLATES, data);
    } catch (error: any) {
      commit(TemplatesMutationTypes.TEMPLATES_ERROR, error);
      throw new Error(error);
    }
  },

  async [TemplatesActionTypes.FETCH_ONE_TEMPLATE]({ commit, getters }, id: string) {
    commit(TemplatesMutationTypes.TEMPLATES_LOADING);
    try {
      let data;
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        data = await mapClient.getOneTemplate(id);
      } else {
        data = await tmapClient.getOneTemplate(id);
      }
      commit(TemplatesMutationTypes.TEMPLATES_SUCCEEDED);
      commit(TemplatesMutationTypes.FETCH_ONE_TEMPLATE, data);
    } catch (error: any) {
      commit(TemplatesMutationTypes.TEMPLATES_ERROR, error);
      throw new Error(error);
    }
  },
  async [TemplatesActionTypes.CREATE_TEMPLATE]({ commit, getters }: AugmentedActionContext, template: Template) {
    commit(TemplatesMutationTypes.TEMPLATES_LOADING);
    try {
      let data;
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        data = await mapClient.createTemplate(template);
      } else {
        data = await tmapClient.createTemplate(template);
      }
      commit(TemplatesMutationTypes.TEMPLATES_SUCCEEDED);
      commit(TemplatesMutationTypes.CREATE_TEMPLATE, data);
    } catch (error: any) {
      commit(TemplatesMutationTypes.TEMPLATES_ERROR, error);
      throw new Error(error);
    }
  },

  async [TemplatesActionTypes.PUBLISH_TEMPLATE]({ commit, getters }: AugmentedActionContext, payload: string) {
    commit(TemplatesMutationTypes.TEMPLATES_LOADING);
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapClient.publishTemplate(payload);
      } else {
        await tmapClient.publishTemplate(payload);
      }
      commit(TemplatesMutationTypes.TEMPLATES_SUCCEEDED);
      commit(TemplatesMutationTypes.PUBLISH_TEMPLATE, payload);
    } catch (error: any) {
      commit(TemplatesMutationTypes.TEMPLATES_ERROR, error);
      throw new Error(error);
    }
  },

  async [TemplatesActionTypes.UN_PUBLISH_TEMPLATE]({ commit, getters }: AugmentedActionContext, payload: string) {
    commit(TemplatesMutationTypes.TEMPLATES_LOADING);
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapClient.unPublishTemplate(payload);
      } else {
        await tmapClient.unPublishTemplate(payload);
      }
      commit(TemplatesMutationTypes.TEMPLATES_SUCCEEDED);
      commit(TemplatesMutationTypes.UN_PUBLISH_TEMPLATE, payload);
    } catch (error: any) {
      commit(TemplatesMutationTypes.TEMPLATES_ERROR, error);
      throw new Error(error);
    }
  },
  async [TemplatesActionTypes.EDIT_TEMPLATE]({ commit, getters }: AugmentedActionContext, payload: Template) {
    commit(TemplatesMutationTypes.TEMPLATES_LOADING);
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      let data;
      if (isGroupAdmin) {
        data = await mapClient.editTemplate(payload);
      } else {
        data = await tmapClient.editTemplate(payload);
      }
      commit(TemplatesMutationTypes.TEMPLATES_SUCCEEDED);
      commit(TemplatesMutationTypes.EDIT_TEMPLATE, data);
    } catch (error: any) {
      commit(TemplatesMutationTypes.TEMPLATES_ERROR, error);
      throw new Error(error);
    }
  },

  async [TemplatesActionTypes.DELETE_TEMPLATE]({ commit, getters }: AugmentedActionContext, id: string) {
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapClient.deleteTemplate(id);
      } else {
        await tmapClient.deleteTemplate(id);
      }
      commit(TemplatesMutationTypes.DELETE_TEMPLATE, id);
    } catch (error: any) {
      commit(TemplatesMutationTypes.TEMPLATES_ERROR, error);
      throw new Error(error);
    }
  },
  async [TemplatesActionTypes.DUPLICATE_TEMPLATE]({ commit, getters }, payload: DuplicateTemplateData) {
    try {
      let template;
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        template = await mapClient.duplicateTemplate(payload);
      } else {
        template = await tmapClient.duplicateTemplate(payload);
      }

      const shortTemplate = {
        _id: template._id,
        title: template.title,
        available_in_dashboard: template.available_in_dashboard,
        price: template.price,
        category_title: template.category_title,
        updatedAt: template.updatedAt,
        preview: template.preview?.desktop?.url,
        number_of_uses: template.number_of_uses,
        description: template.description,
        franchise_tenant_id: template.franchise_tenant_id,
      };

      commit(TemplatesMutationTypes.DUPLICATE_TEMPLATE, shortTemplate);
      return template;
    } catch (error: any) {
      commit(TemplatesMutationTypes.TEMPLATES_ERROR, error);
      throw new Error(error);
    }
  },

  [TemplatesActionTypes.CLEAR_ONE_TEMPLATE]({ commit }) {
    commit(TemplatesMutationTypes.CLEAR_ONE_TEMPLATE);
  },
};
