import { FaqData } from '@/@typespaces/interfaces/faq.interface';
import { FaqFormState, FaqSolution, FaqSolutionPriority } from '@/@typespaces/interfaces/form/faq-form.interface';
import { AxiosError } from 'axios';
import InstanceHttpClient from './instance';

export default class FaqClient extends InstanceHttpClient {
  constructor() {
    super('public-page/faq');
  }

  async getFaq(): Promise<FaqData> {
    try {
      return await this.apiCall({
        method: 'GET',
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async editFaq(data: FaqFormState) {
    try {
      await this.apiCall({
        method: 'PATCH',
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async addSolutions(): Promise<{ solution_id: string }> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: 'solutions/add',
      });
    } catch (err: any) {
      throw new Error((err as AxiosError).response?.status.toString());
    }
  }

  async deleteSolutions(id: string) {
    try {
      await this.apiCall({
        method: 'DELETE',
        url: `solutions/${id}`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async editSolutions(payload: { id: string; data: FaqSolution }) {
    const { id, data } = payload;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `solutions/${id}`,
        data,
      });
    } catch (err) {
      throw new Error((err as AxiosError).response?.status.toString());
    }
  }

  async editSolutionsPriority(data: FaqSolutionPriority[]) {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `solutions/dnd`,
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }
}
