import Status from '@/@typespaces/enum/status.enum';
import { MainPage } from '@/@typespaces/interfaces/mainPageInterface';

export interface State {
  data: MainPage | null;

  status: Status;
}

export const state: State = {
  data: null,
  status: Status.IDLE,
};
