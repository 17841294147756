import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '@/store';
import ContactsClient from '@/api/contacts.api';
import { Contacts } from '@/@typespaces/interfaces/contacts.interface';
import { ContactsMutationTypes } from './mutation-types';
import { State } from './state';
import { Mutations } from './mutations';
import { ContactsActionTypes } from './action-types';

const client = new ContactsClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [ContactsActionTypes.FETCH_CONTACTS]({ commit }: AugmentedActionContext): void;

  [ContactsActionTypes.EDIT_CONTACTS]({ commit }: AugmentedActionContext, data: Contacts): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ContactsActionTypes.FETCH_CONTACTS]({ commit }) {
    commit(ContactsMutationTypes.CONTACTS_LOADING);
    try {
      const data = await client.getContacts();
      commit(ContactsMutationTypes.CONTACTS_SUCCEEDED);
      commit(ContactsMutationTypes.FETCH_CONTACTS, data);
    } catch (e) {
      commit(ContactsMutationTypes.CONTACTS_ERROR);
      throw new Error(e as string);
    }
  },
  async [ContactsActionTypes.EDIT_CONTACTS]({ commit }, data: Contacts) {
    commit(ContactsMutationTypes.CONTACTS_LOADING);
    try {
      await client.editContact(data);
      commit(ContactsMutationTypes.CONTACTS_SUCCEEDED);
    } catch (e) {
      commit(ContactsMutationTypes.CONTACTS_ERROR);
      throw new Error(e as string);
    }
  },
};
