// eslint-disable-next-line import/prefer-default-export
export const QUERY_PARAMS_SELECT_OPTIONS = {
  USERS: [
    {
      value: 'quantity_projects',
      label: 'Проекты',
    },
    {
      value: 'status',
      label: 'Статус',
    },
  ],
};
