/* eslint-disable import/no-cycle */
import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '@/store';
import { State } from './state';
import { Mutations } from './mutations';
import { ServiceMutationTypes } from './mutation-types';
import { ServiceActionTypes } from './action-types';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [ServiceActionTypes.CHANGE_WIDTH_COLLAPSE_MENU]({ commit }: AugmentedActionContext, collapseMenu: boolean): void;
  [ServiceActionTypes.HIDE_WARNING]({ commit }: AugmentedActionContext): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ServiceActionTypes.CHANGE_WIDTH_COLLAPSE_MENU]({ commit }, collapseMenu) {
    commit(ServiceMutationTypes.SET_COLLAPSE_STATE, collapseMenu);
  },

  [ServiceActionTypes.HIDE_WARNING]({ commit }) {
    commit(ServiceMutationTypes.HIDE_WARNING);
  },
};
