import { MutationTree } from 'vuex';
import { IAdmin } from '@/@typespaces/interfaces/admin.interface';
import Status from '@/@typespaces/enum/status.enum';
import { IPayload } from '@/@typespaces/interfaces/payload.interfaces';
import { State } from './state';
import { AdminsMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [AdminsMutationTypes.SET_DATA_ADMINS](state: S, payload: IPayload<IAdmin>): void;
  [AdminsMutationTypes.LOADING](state: S): void;
  [AdminsMutationTypes.SUCCEEDED](state: S): void;
  [AdminsMutationTypes.ERROR](state: S, payload: string): void;
  [AdminsMutationTypes.DELETE_ADMIN](state: S, payload: string): void;
  [AdminsMutationTypes.DELETE_ADMINS](state: S, payload: string[]): void;
  [AdminsMutationTypes.BLOCK_ADMIN](state: S, payload: string): void;
  [AdminsMutationTypes.UNBLOCK_ADMIN](state: S, payload: string): void;
  [AdminsMutationTypes.BLOCK_ADMINS](state: S, payload: string[]): void;
  [AdminsMutationTypes.UNBLOCK_ADMINS](state: S, payload: string[]): void;
  [AdminsMutationTypes.ADD_ADMIN](state: S, payload: IAdmin): void;
  [AdminsMutationTypes.EDIT_ADMIN](state: S, payload: IAdmin): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [AdminsMutationTypes.SET_DATA_ADMINS](state: State, data: IPayload<IAdmin>) {
    state.data = data.data.users;
    state.usersIds = data.data.usersIds;
    state.roles = data.roles;
    state.pagination.page = data.page;
    state.pagination.pageSize = data.pageSize;
    state.pagination.total = data.total;
  },
  [AdminsMutationTypes.LOADING](state: State) {
    state.status = Status.LOADING;
    state.error = null;
  },
  [AdminsMutationTypes.SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
    state.error = null;
  },
  [AdminsMutationTypes.ERROR](state: State, error) {
    state.status = Status.FAILED;
    state.error = error;
  },

  [AdminsMutationTypes.DELETE_ADMIN](state: State, id: string) {
    state.data = state.data.filter((admin) => admin._id !== id);
    state.pagination.total -= 1;
  },
  [AdminsMutationTypes.DELETE_ADMINS](state: State, ids: string[]) {
    state.data = state.data.filter((admin) => !ids.includes(admin._id));
    state.pagination.total -= ids.length;
  },
  [AdminsMutationTypes.BLOCK_ADMIN](state: State, id: string) {
    state.data = state.data.map((admin) => {
      if (admin._id === id) {
        return {
          ...admin,
          blocked: true,
        };
      }
      return admin;
    });
  },
  [AdminsMutationTypes.UNBLOCK_ADMIN](state: State, id: string) {
    state.data = state.data.map((admin) => {
      if (admin._id === id) {
        return {
          ...admin,
          blocked: false,
        };
      }
      return admin;
    });
  },
  [AdminsMutationTypes.ADD_ADMIN](state: State, data: IAdmin) {
    state.data = [...state.data, data];
  },
  [AdminsMutationTypes.BLOCK_ADMINS](state: State, ids: string[]) {
    state.data = state.data.map((admin) => {
      if (ids.includes(admin._id)) {
        return {
          ...admin,
          blocked: true,
        };
      }
      return admin;
    });
  },
  [AdminsMutationTypes.UNBLOCK_ADMINS](state: State, ids: string[]) {
    state.data = state.data.map((admin) => {
      if (ids.includes(admin._id)) {
        return {
          ...admin,
          blocked: false,
        };
      }
      return admin;
    });
  },
  [AdminsMutationTypes.EDIT_ADMIN](state: State, data: IAdmin) {
    const filteredAdmins = state.data.filter((admin) => admin._id !== data._id);
    state.data = [...filteredAdmins, data];
  },
};
