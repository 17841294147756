export const PUBLIC_OFFER = {
  name: 'public_offer',
  description: 'PDF-файл до 5 мб.',
  title: 'Публичная оферта',
};

export const USER_AGREEMENT = {
  name: 'user_agreement',
  description: 'PDF-файл до 5 мб.',
  title: 'Пользовательское согл.',
};

export const PRIVACY_POLICY = {
  name: 'privacy_policy',
  description: 'PDF-файл до 5 мб.',
  title: 'Конфиденциальность',
};

export const FAVICON = {
  name: 'favikon',
  title: 'Фавикон',
  description: 'ico или svg размером 16x16 px',
};
export const LOGO = {
  name: 'logo',
  title: 'Логотип франшизы',
  description:
    'Используется на сайте, в меню и на странице авторизации франшизы. PNG или SVG до 5 мб, с разрешением не меньше 256 пикселей по меньшей стороне',
};

export const COVER = {
  name: 'cover',
  title: 'Обложка',
  description:
    'Используется на сайте, на странице авторизации франшизы. JPG PNG до 5 мб, с разрешением 1080x1080 пикселей',
};
