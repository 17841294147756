import { Seo } from '@/@typespaces/interfaces/seo.interface';
import Status from '@/@typespaces/enum/status.enum';

export interface State {
  data: Seo | null;
  status: Status;
}

export const state: State = {
  data: null,
  status: Status.IDLE,
};
