import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import Status from '@/@typespaces/enum/status.enum';
import { State } from './state';

export type Getters = {
  isAuthLoading(state: State): boolean;
  authStatus(state: State): Status;
  isLoggedIn(state: State): boolean;
  getAccessToken(state: State): string | null;
  getAuthEmail(state: State): string | null;
};

export const getters: GetterTree<State, RootState> & Getters = {
  isAuthLoading: (state: State) => state.status === Status.LOADING,
  authStatus: (state: State) => state.status,
  isLoggedIn: (state: State) => !!state.accessToken?.length,
  getAccessToken: (state: State) => state.accessToken,
  getAuthEmail: (state: State) => state.email,
};
