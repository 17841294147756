import { createApp } from 'vue';
import axios from 'axios';
import {
  Button,
  Collapse,
  message,
  Input,
  Menu,
  Layout,
  Divider,
  Table,
  Tag,
  Checkbox,
  Form,
  Switch,
  Card,
  Select,
  Row,
  Col,
  Spin,
  Dropdown,
  Space,
  ConfigProvider,
  Empty,
  Upload,
  Tabs,
  Modal,
  Pagination,
  Tooltip,
  DatePicker,
  AutoComplete,
  Tree,
  Radio,
  InputNumber,
} from 'ant-design-vue';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { abilitiesPlugin, Can } from '@casl/vue';
// @ts-ignore
import Maska from 'maska';
// @ts-ignore
import ColorInput from 'vue-color-input';
import { Franchise } from '@/@typespaces/interfaces/franchise.interface';
import { IProfile } from '@/@typespaces/interfaces/profile.interface';
import { buildAbilityFor } from './services/ability';
import App from './App.vue';
import router from './router';
import store from './store';
import './style/index.css';

const checkMainDomain = async () => {
  let ignoreHosts: string[];
  try {
    ignoreHosts = JSON.parse(process.env.VUE_APP_IGNORE_HOSTS);
  } catch (error) {
    if (error instanceof Error) {
      console.error(`Ошибка парсинга переменной: ${error.message}`);
    }
    return;
  }
  const { host } = window.location;
  if (host && !ignoreHosts.includes(host)) {
    const url = `https://${host}/api/general/franchise`;
    try {
      const response = await axios.get<Franchise>(url);
      const mainDomain = response.data.domain_settings.main_domain.url;
      if (mainDomain && host !== mainDomain) {
        window.location.href = `https://${mainDomain}`;
      }
    } catch (error) {
      console.error('Сетевая ошибка при получении данных франшизы');
    }
  }
};

checkMainDomain();

const ability = buildAbilityFor({} as IProfile);

const app = createApp(App);
app.use(Maska);
app.use(ColorInput);
app.use(ConfigProvider);
app.use(Empty);
app.use(Collapse);
app.use(Layout);
app.use(Row);
app.use(Col);
app.use(Radio);
app.use(Dropdown);
app.use(Space);
app.use(Space);
app.use(Menu);
app.use(Divider);
app.use(Spin);
app.use(Button);
app.use(Tabs);
app.use(Input);
app.use(Table);
app.use(Form);
app.use(Checkbox);
app.use(Tag);
app.use(Switch);
app.use(Modal);
app.use(Card);
app.use(Upload);
app.use(Select);
app.use(store());
app.use(router);
app.use(Pagination);
app.use(Tooltip);
app.use(DatePicker);
app.use(AutoComplete);
app.use(Tree);
app.use(InputNumber);
app.use(abilitiesPlugin, ability);
app.component(Can.name, Can);
app.component('QuillEditor', QuillEditor);
app.mount('#app');
app.config.globalProperties.$message = message;
