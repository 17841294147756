import { ActionContext, ActionTree } from 'vuex';
import { RootState, store } from '@/store';
import { FranchiseActionTypes } from '@/store/modules/franchise/action-types';
import { RestorePassword } from '@/@typespaces/types/restorePassword.types';
import { State } from './state';
import { Mutations } from './mutations';
import { AuthMutationTypes } from './mutation-types';
import { AuthActionTypes } from './action-types';
import AuthClient from '../../../api/auth.api';

const client = new AuthClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [AuthActionTypes.SIGN_IN]({ commit }: AugmentedActionContext, data: { email: string; password: string }): void;

  [AuthActionTypes.LOG_OUT]({ commit }: AugmentedActionContext): void;

  [AuthActionTypes.REFRESH_TOKEN]({ commit }: AugmentedActionContext, token: string): void;

  [AuthActionTypes.RECOVERY_PASSWORD]({ commit }: AugmentedActionContext, payload: string): void;

  [AuthActionTypes.RESTORE_PASSWORD]({ commit }: AugmentedActionContext, payload: RestorePassword): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [AuthActionTypes.SIGN_IN]({ commit }, data) {
    commit(AuthMutationTypes.AUTH_LOADING);
    try {
      const response = await client.signIn(data.email, data.password);
      commit(AuthMutationTypes.SIGN_IN, { ...response, email: data.email });
      commit(AuthMutationTypes.AUTH_SUCCEEDED);
    } catch (error) {
      commit(AuthMutationTypes.AUTH_ERROR, error as string);
      throw new Error(error as string);
    }
  },

  async [AuthActionTypes.RESTORE_PASSWORD]({ commit }, payload: RestorePassword) {
    commit(AuthMutationTypes.AUTH_LOADING);
    try {
      await client.restorePassword(payload);
      commit(AuthMutationTypes.AUTH_SUCCEEDED);
    } catch (error) {
      commit(AuthMutationTypes.AUTH_ERROR, error as string);
      throw new Error(error as string);
    }
  },

  async [AuthActionTypes.RECOVERY_PASSWORD]({ commit }, payload: string) {
    commit(AuthMutationTypes.AUTH_LOADING);
    try {
      await client.recoveryPassword(payload);
      commit(AuthMutationTypes.AUTH_SUCCEEDED);
    } catch (error) {
      commit(AuthMutationTypes.AUTH_ERROR, error as string);
      throw new Error(error as string);
    }
  },

  async [AuthActionTypes.LOG_OUT]({ commit }) {
    await store.dispatch(FranchiseActionTypes.CLEAR_FRANCHISE);
    commit(AuthMutationTypes.LOG_OUT);
  },

  [AuthActionTypes.REFRESH_TOKEN]({ commit }, token) {
    commit(AuthMutationTypes.REFRESH_TOKEN, token);
  },
};
