/* eslint-disable import/no-cycle */
import { createLogger, createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import { AdminsStore, State as IAdminState, store as admins } from '@/store/modules/admins';
import { ServiceStore, State as IServiceState, store as service } from '@/store/modules/service';
import { State as IUsersState, store as users, UsersStore } from '@/store/modules/users';
import { FaqStore, State as IFaqState, store as faq } from '@/store/modules/faq';
import { ProfileStore, ProfileState as IProfileState, store as profile } from '@/store/modules/profile';
import { ReviewsStore, State as IReviewState, store as reviews } from '@/store/modules/reviews';
import { ServicesStore, State as IServicesState, store as services } from '@/store/modules/services';
import { TemplatesStore, State as TableTemplatesDataType, store as templates } from '@/store/modules/templates';
import { AuthStore, State as IAuthState, store as auth } from './modules/auth';
import { FranchisesStore, State as IFranchisesState, store as franchises } from './modules/franchises';
import { KnowledgeStore, KnowledgeBaseState as IKnowledgeStore, store as knowledge } from './modules/knowledge';
import { ProjectsStore, State as IProjectsStore, store as projects } from './modules/projects';
import { ContactsStore, State as IContactsStore, store as contacts } from './modules/contacts';
import { SettingsStore, State as ISettingsStore, store as settings } from './modules/settings';
import { FranchiseStore, State as IFranchiseStore, store as franchise } from './modules/franchise';
import {
  TemplatesCategoriesStore,
  State as ITemplatesCategoriesStore,
  store as templatesCategories,
} from './modules/templatesCategories';
import { IdentityStore, State as IIdentityStore, store as identity } from './modules/identity';
import { MainPageStore, State as IMainPageStore, store as mainPage } from './modules/mainPage';
import { SeoStore, State as ISeoStore, store as seo } from './modules/seo';

export type RootState = {
  auth: IAuthState;
  admins: IAdminState;
  users: IUsersState;
  service: IServiceState;
  faq: IFaqState;
  reviews: IReviewState;
  profile: IProfileState;
  services: IServicesState;
  templates: TableTemplatesDataType;
  franchises: IFranchisesState;
  knowledge: IKnowledgeStore;
  projects: IProjectsStore;
  contacts: IContactsStore;
  settings: ISettingsStore;
  templatesCategories: ITemplatesCategoriesStore;
  franchise: IFranchiseStore;
  identity: IIdentityStore;
  mainPage: IMainPageStore;
  seo: ISeoStore;
};

export type Store = AdminsStore<Pick<RootState, 'admins'>> &
  AuthStore<Pick<RootState, 'auth'>> &
  ProfileStore<Pick<RootState, 'profile'>> &
  ServiceStore<Pick<RootState, 'service'>> &
  UsersStore<Pick<RootState, 'users'>> &
  FaqStore<Pick<RootState, 'faq'>> &
  ReviewsStore<Pick<RootState, 'reviews'>> &
  ServicesStore<Pick<RootState, 'services'>> &
  TemplatesStore<Pick<RootState, 'templates'>> &
  FranchisesStore<Pick<RootState, 'franchises'>> &
  ProjectsStore<Pick<RootState, 'projects'>> &
  ContactsStore<Pick<RootState, 'contacts'>> &
  SettingsStore<Pick<RootState, 'settings'>> &
  TemplatesCategoriesStore<Pick<RootState, 'templatesCategories'>> &
  FranchiseStore<Pick<RootState, 'franchise'>> &
  IdentityStore<Pick<RootState, 'identity'>> &
  MainPageStore<Pick<RootState, 'mainPage'>> &
  SeoStore<Pick<RootState, 'seo'>> &
  KnowledgeStore<Pick<RootState, 'knowledge'>>;

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state: RootState) => ({
    auth: state.auth,
    profile: state.profile,
    franchise: state.franchise,
    service: state.service,
    identity: state.identity,
  }), // only save auth module
});

// Plug in logger when in development environment
const debug = process.env.NODE_ENV !== 'production';
const plugins = debug ? [createLogger({}), vuexLocal.plugin] : [vuexLocal.plugin];

export const store = createStore({
  plugins,
  modules: {
    admins,
    profile,
    users,
    auth,
    service,
    faq,
    reviews,
    services,
    templates,
    franchises,
    knowledge,
    projects,
    contacts,
    settings,
    templatesCategories,
    franchise,
    identity,
    mainPage,
    seo,
  },
});

export default function useStore(): Store {
  return store as Store;
}
