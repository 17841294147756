import { MutationTree } from 'vuex';
import { Settings } from '@/@typespaces/interfaces/settings.interface';
import Status from '@/@typespaces/enum/status.enum';
import { State } from './state';
import { SettingsMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [SettingsMutationTypes.FETCH_SETTINGS](state: S, payload: Settings): void;
  [SettingsMutationTypes.EDIT_SETTING](state: S, payload: Settings): void;

  [SettingsMutationTypes.SETTINGS_LOADING](state: S): void;
  [SettingsMutationTypes.SETTINGS_SUCCEEDED](state: S): void;
  [SettingsMutationTypes.SETTINGS_ERROR](state: S): void;
};
export const mutations: MutationTree<State> & Mutations = {
  [SettingsMutationTypes.FETCH_SETTINGS](state: State, data: Settings) {
    state.data = data;
  },
  [SettingsMutationTypes.EDIT_SETTING](state: State, data: Settings) {
    state.data = data;
  },
  [SettingsMutationTypes.SETTINGS_LOADING](state: State) {
    state.status = Status.LOADING;
  },
  [SettingsMutationTypes.SETTINGS_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
  },
  [SettingsMutationTypes.SETTINGS_ERROR](state: State) {
    state.status = Status.FAILED;
  },
};
