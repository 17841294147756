import { MutationTree } from 'vuex';
import { State } from './state';
import { ServiceMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [ServiceMutationTypes.SET_COLLAPSE_STATE](state: S, payload: boolean): void;
  [ServiceMutationTypes.HIDE_WARNING](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [ServiceMutationTypes.SET_COLLAPSE_STATE](state: State, collapseMenu: boolean) {
    state.collapseMenu = collapseMenu;
  },
  [ServiceMutationTypes.HIDE_WARNING](state: State) {
    state.hide_warning = true;
  },
};
