import { Reviews } from '@/@typespaces/interfaces/review.interface';
import StatusEnum from '@/@typespaces/enum/status.enum';

export interface State {
  data: Reviews | null;
  status: StatusEnum;
  tableStatus: StatusEnum;
  error: string | null;
}

export const state: State = {
  data: null,
  status: StatusEnum.IDLE,
  tableStatus: StatusEnum.IDLE,
  error: null,
};
