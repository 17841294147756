import { MutationTree } from 'vuex';
import { ProjectsMutationTypes } from '@/store/modules/projects/mutation-types';
import {
  ProjectUserData,
  ShortProject,
  ProjectsData,
  Project,
  ShortenedProject,
  ProjectUser,
} from '@/@typespaces/interfaces/project.interface';
import Status from '@/@typespaces/enum/status.enum';
import { EditUserInProject, UserInProjectChangeRole } from '@/@typespaces/types/project.types';
import { IRole } from '@/@typespaces/interfaces/role.interfaces';
import { State } from './state';

export type Mutations<S = State> = {
  [ProjectsMutationTypes.FETCH_PROJECTS](state: S, payload: ProjectsData): void;
  [ProjectsMutationTypes.CREATE_PROJECT](state: S, payload: ShortProject): void;
  [ProjectsMutationTypes.EDIT_PROJECT](state: S, payload: ShortProject): void;
  [ProjectsMutationTypes.DELETE_PROJECT](state: S, payload: string): void;
  [ProjectsMutationTypes.BLOCK_PROJECT](state: S, payload: string): void;
  [ProjectsMutationTypes.UNBLOCK_PROJECT](state: S, payload: string): void;
  [ProjectsMutationTypes.GET_ONE_PROJECT](state: S, payload: Project): void;
  [ProjectsMutationTypes.CLEAR_ONE_PROJECT](state: S): void;

  // Для таблицы пользователей
  [ProjectsMutationTypes.FETCH_USERS](state: S, payload: ProjectUserData): void;
  [ProjectsMutationTypes.ADD_USER_IN_PROJECT](state: S, payload: ProjectUser): void;
  [ProjectsMutationTypes.GET_PROJECT_USERS_ROLES](state: S, payload: IRole[]): void;
  [ProjectsMutationTypes.CHANGE_ROLE_USER_IN_PROJECT](state: S, payload: UserInProjectChangeRole): void;
  [ProjectsMutationTypes.DELETE_USER_IN_CURRENT_PROJECT](state: S, payload: EditUserInProject): void;
  [ProjectsMutationTypes.BLOCK_USER_IN_CURRENT_PROJECT](state: S, payload: EditUserInProject): void;
  [ProjectsMutationTypes.UNBLOCK_USER_IN_CURRENT_PROJECT](state: S, payload: EditUserInProject): void;

  // Status
  [ProjectsMutationTypes.PROJECTS_ERROR](state: S, payload: string): void;
  [ProjectsMutationTypes.PROJECTS_LOADING](state: S): void;
  [ProjectsMutationTypes.PROJECTS_SUCCEEDED](state: S): void;
  [ProjectsMutationTypes.GET_ALL_PROJECT_SHORT](state: S, payload: ShortenedProject[]): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [ProjectsMutationTypes.FETCH_PROJECTS](state: State, data: ProjectsData) {
    state.projects = data.data.projects;
    state.pagination.total = data.total;
    state.pagination.pageSize = data.pageSize;
    state.pagination.page = data.page;
    state.projectsIds = data.data.projectsIds;
  },

  [ProjectsMutationTypes.CREATE_PROJECT](state: State, data: ShortProject) {
    state.projects.push(data);
    state.pagination.total += 1;
  },

  [ProjectsMutationTypes.EDIT_PROJECT](state: State, data: ShortProject) {
    state.projects = state.projects.map((item) => {
      if (item._id === data._id) {
        return data;
      }
      return item;
    });
  },

  [ProjectsMutationTypes.DELETE_PROJECT](state: State, id: string) {
    const removeIndex = state.projects.map((item) => item._id).indexOf(id);
    state.projects.splice(removeIndex, 1);
    state.pagination.total -= 1;
  },

  [ProjectsMutationTypes.BLOCK_PROJECT](state: State, payload: string) {
    state.projects = state.projects.map((item) => {
      if (item._id === payload) {
        return {
          ...item,
          blocked: true,
        };
      }
      return item;
    });
  },

  [ProjectsMutationTypes.UNBLOCK_PROJECT](state: State, payload: string) {
    state.projects = state.projects.map((item) => {
      if (item._id === payload) {
        return {
          ...item,
          blocked: false,
        };
      }
      return item;
    });
  },

  [ProjectsMutationTypes.COPY_PROJECT](state: State, data: ShortProject) {
    state.projects.push(data);
    state.pagination.total += 1;
  },
  [ProjectsMutationTypes.GET_ONE_PROJECT](state: State, data: Project) {
    state.oneProject = data;
  },
  [ProjectsMutationTypes.CLEAR_ONE_PROJECT](state: State) {
    state.oneProject = null;
  },

  // Users Table
  [ProjectsMutationTypes.FETCH_USERS](state: State, data: ProjectUserData) {
    state.projectUsersData.users = data.data.users;
    state.projectUsersData.usersIds = data.data.usersIds;
    state.projectUsersData.pagination.total = data.total;
    state.projectUsersData.pagination.page = data.page;
    state.projectUsersData.pagination.pageSize = data.pageSize;
  },

  [ProjectsMutationTypes.ADD_USER_IN_PROJECT](state: State, data: ProjectUser) {
    state.projectUsersData.users.push(data);
  },

  [ProjectsMutationTypes.GET_PROJECT_USERS_ROLES](state: State, data: IRole[]) {
    state.roles = data;
  },
  [ProjectsMutationTypes.CHANGE_ROLE_USER_IN_PROJECT](state: State, data: UserInProjectChangeRole) {
    const { userId, roleName } = data;
    state.projectUsersData.users = state.projectUsersData.users.map((user) => {
      if (user._id === userId) {
        return {
          ...user,
          role: roleName,
        };
      }
      return user;
    });
  },

  [ProjectsMutationTypes.DELETE_USER_IN_CURRENT_PROJECT](state: State, data: EditUserInProject) {
    const removeIndex = state.projects.map((item) => item._id).indexOf(data.userId);
    state.projectUsersData.users.splice(removeIndex, 1);
    state.projectUsersData.pagination.total -= 1;
  },

  [ProjectsMutationTypes.BLOCK_USER_IN_CURRENT_PROJECT](state: State, data: EditUserInProject) {
    state.projectUsersData.users = state.projectUsersData.users.map((item) => {
      if (item._id === data.userId) {
        return {
          ...item,
          blocked: true,
        };
      }
      return item;
    });
  },

  [ProjectsMutationTypes.UNBLOCK_USER_IN_CURRENT_PROJECT](state: State, data: EditUserInProject) {
    state.projectUsersData.users = state.projectUsersData.users.map((item) => {
      if (item._id === data.userId) {
        return {
          ...item,
          blocked: false,
        };
      }
      return item;
    });
  },

  // Status
  [ProjectsMutationTypes.PROJECTS_LOADING](state) {
    state.status = Status.LOADING;
  },
  [ProjectsMutationTypes.PROJECTS_SUCCEEDED](state) {
    state.status = Status.SUCCEEDED;
  },
  [ProjectsMutationTypes.PROJECTS_ERROR](state: State, error: string) {
    state.error = error;
    state.status = Status.IDLE;
  },
  [ProjectsMutationTypes.GET_ALL_PROJECT_SHORT](state: State, data: ShortenedProject[]) {
    state.shortProjects = data;
  },
};
