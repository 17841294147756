import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { Faq, Content } from '@/@typespaces/interfaces/faq.interface';
import Status from '@/@typespaces/enum/status.enum';
import { State } from './state';

export type Getters = {
  getFaq(state: State): Faq | null;
  getFaqContentById: (state: State) => (id: string) => Content | undefined;
  getFaqStatus(state: State): boolean;
  getFaqTableStatus(state: State): boolean;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getFaq: (state) => state.data,
  getFaqStatus: (state) => state.status === Status.LOADING,
  getFaqTableStatus: (state) => state.tableStatus === Status.LOADING,
  getFaqContentById: (state) => (id: string) => state.data?.content.find((faq) => faq._id === id),
};
