
import { defineComponent, PropType, Ref, ref } from 'vue';
import { FilterOutlined, PlusOutlined } from '@ant-design/icons-vue';
import dayjs from 'dayjs';
import {
  baseQueryOperatorSelectOptions,
  shortQueryOperatorSelectOptions,
} from '@/@constants/queryOperatorSelectOptions.constants';

export default defineComponent({
  name: 'TheFilterButton',
  props: {
    optionsField: {
      type: Array as PropType<
        {
          value: string;
          label: string;
        }[]
      >,
    },
    quantityFilters: {
      type: Number,
    },
    routeName: {
      type: String,
      required: true,
    },
    shortFilter: {
      type: Boolean,
      required: true,
    },
    queryOperators: {
      type: Array as PropType<string[]>,
    },
  },
  emits: ['push-query', 'add-filter-handler', 'selected-status'],
  components: {
    FilterOutlined,
    PlusOutlined,
  },
  setup() {
    type SelectedProps = Array<{ value: string; label: string }>;

    const queryValue = ref<string>('');
    const visible = ref(false);
    const typeField = ref<string>('');
    const statusSelect: Ref<SelectedProps> = ref([
      {
        value: 'active',
        label: 'Активен',
      },
      {
        value: 'blocked',
        label: 'Заблокирован',
      },
      {
        value: 'block-in-project',
        label: 'Блок в проекте',
      },
    ]);

    const blockedSelect: Ref<SelectedProps> = ref([
      {
        value: 'false',
        label: 'Активен',
      },
      {
        value: 'true',
        label: 'Заблокирован',
      },
    ]);

    const publicSelect: Ref<SelectedProps> = ref([
      {
        value: 'false',
        label: 'Не опубликован',
      },
      {
        value: 'true',
        label: 'Опубликован',
      },
    ]);

    return {
      queryParams: ref(''),
      queryOperator: ref(':eq'),
      queryValue,
      visible,
      typeField,
      statusSelect,
      blockedSelect,
      publicSelect,
    };
  },
  watch: {
    queryParams(value) {
      switch (value) {
        case 'status':
          this.typeField = 'status';
          break;
        case 'blocked':
          this.typeField = 'blocked';
          break;
        case 'published':
        case 'available_in_dashboard':
          this.typeField = 'published';
          break;
        case 'activityAt':
        case 'updatedAt':
        case 'createdAt':
        case 'eDate':
          this.typeField = 'date';
          break;
        default:
          this.typeField = 'default';
          break;
      }
    },
  },
  methods: {
    addFilterHandler() {
      const date = new Date(dayjs(this.queryValue, 'YYYY-MM-DD').format()).getTime();
      const key = `_where[${this.quantityFilters}][${this.queryParams}${this.queryOperator}]`;
      const query = {
        ...this.$router.currentRoute.value.query,
        [key]: this.typeField === 'date' ? date : this.queryValue,
      };
      this.$router.replace({
        name: this.routeName,
        query,
      });
      this.$emit('add-filter-handler', query);
      this.visible = false;

      const operator = this.queryOperatorSelectOptions?.find((item) => item.value === this.queryOperator);
      const queryParams = this.optionsField?.find((item) => item.value === this.queryParams);

      const queryData = {
        id: Date.now().toString(36),
        queryParamsLabel: queryParams ? queryParams.label : '',
        operatorLabel: operator ? operator.label : '',
        queryValue: this.queryValue,
        queryParams: this.queryParams,
        operator: this.queryOperator,
      };
      this.queryValue = '';
      this.$emit('push-query', queryData);
    },
    selectedStatus(value: string) {
      this.$emit('selected-status', value);
    },
  },
  created() {
    if (this.optionsField) {
      this.queryParams = this.optionsField[0].value;
    }
  },
  computed: {
    queryOperatorSelectOptions() {
      if (this.queryOperators?.length) {
        const customOperators: { value: string; label: string }[] = [];
        baseQueryOperatorSelectOptions.forEach((item) => {
          if (this.queryOperators?.includes(item.value.replace(':', ''))) {
            customOperators.push(item);
          }
        });

        return customOperators;
      }

      if (this.shortFilter) {
        return shortQueryOperatorSelectOptions;
      }
      return baseQueryOperatorSelectOptions;
    },
  },
});
