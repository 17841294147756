import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { IProfile } from '@/@typespaces/interfaces/profile.interface';
import { ADMINS_GROUP, GOD_ADMIN } from '@/@constants';
import { ProfileState } from './state';

export type Getters = {
  getProfile(state: ProfileState): IProfile | null;
  getGroupName(state: ProfileState): string;
  getIsAdminGroup(state: ProfileState): boolean;
  getIsGodAdmin(state: ProfileState): boolean;
};

export const getters: GetterTree<ProfileState, RootState> & Getters = {
  getProfile: (state: ProfileState) => state.data,
  getGroupName: (state: ProfileState) => state.data?.role.group || '',
  getIsAdminGroup: (state: ProfileState) => state.data?.role.group === ADMINS_GROUP,
  getIsGodAdmin: (state: ProfileState) => state.data?.role.name === GOD_ADMIN,
};
