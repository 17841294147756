import { MutationTree } from 'vuex';
import { ReviewsData } from '@/@typespaces/interfaces/review.interface';
import Status from '@/@typespaces/enum/status.enum';
import { State } from './state';
import { ReviewsMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [ReviewsMutationTypes.FETCH_REVIEWS](state: S, payload: ReviewsData): void;
  [ReviewsMutationTypes.DELETE_REVIEW_CONTENT](state: S, payload: string): void;

  // Status
  [ReviewsMutationTypes.REVIEWS_LOADING](state: S): void;
  [ReviewsMutationTypes.REVIEWS_SUCCEEDED](state: S): void;
  [ReviewsMutationTypes.REVIEWS_ERROR](state: S, payload: string): void;

  [ReviewsMutationTypes.REVIEWS_TABLE_LOADING](state: S): void;
  [ReviewsMutationTypes.REVIEWS_TABLE_SUCCEEDED](state: S): void;
  [ReviewsMutationTypes.REVIEWS_TABLE_ERROR](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [ReviewsMutationTypes.FETCH_REVIEWS](state: State, payload: ReviewsData) {
    state.data = payload.reviews;
  },
  [ReviewsMutationTypes.DELETE_REVIEW_CONTENT](state: State, id: string) {
    if (state.data) {
      const content = state.data.content.filter((item) => item._id !== id);
      state.data = {
        ...state.data,
        content,
      };
    }
  },
  // Status

  [ReviewsMutationTypes.REVIEWS_LOADING](state: State) {
    state.status = Status.LOADING;
  },
  [ReviewsMutationTypes.REVIEWS_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
  },
  [ReviewsMutationTypes.REVIEWS_ERROR](state: State, error: string) {
    state.status = Status.FAILED;
    state.error = error;
  },

  [ReviewsMutationTypes.REVIEWS_TABLE_LOADING](state: State) {
    state.tableStatus = Status.LOADING;
  },
  [ReviewsMutationTypes.REVIEWS_TABLE_SUCCEEDED](state: State) {
    state.tableStatus = Status.SUCCEEDED;
  },
  [ReviewsMutationTypes.REVIEWS_TABLE_ERROR](state: State) {
    state.tableStatus = Status.FAILED;
  },
};
