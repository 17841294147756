import TmapProjectsClient from '@/api/tmap/tmap-projects.api';
import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store';
import MapProjectsClient from '@/api/map/map-projects.api';
import { ShortProject } from '@/@typespaces/interfaces/project.interface';
import { ProjectsMutationTypes } from '@/store/modules/projects/mutation-types';
import { Query } from '@/@typespaces/types/query.types';
import RoleClient from '@/api/role.api';
import { AddUserInProject, EditUserInProject, UserInProjectChangeRole } from '@/@typespaces/types/project.types';
import { UpdateFile } from '@/@typespaces/types/updateFranchisesFile.types';
import { State } from './state';
import { Mutations } from './mutations';
import { ProjectsActionTypes } from './action-types';

const mapProjectClient = new MapProjectsClient();
const tmapProjectClient = new TmapProjectsClient();
const roleClient = new RoleClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [ProjectsActionTypes.FETCH_PROJECTS]({ commit }: AugmentedActionContext, query: Query): void;

  [ProjectsActionTypes.CREATE_PROJECT]({ commit }: AugmentedActionContext, data: ShortProject): void;

  [ProjectsActionTypes.EDIT_PROJECT]({ commit }: AugmentedActionContext, data: ShortProject): void;
  [ProjectsActionTypes.UPDATE_PROJECT_FILE](
    { commit }: AugmentedActionContext,
    payload: { data: UpdateFile; projectId: string }
  ): void;
  [ProjectsActionTypes.REMOVE_PROJECT_FILE](
    { commit }: AugmentedActionContext,
    payload: { data: UpdateFile; projectId: string }
  ): void;

  [ProjectsActionTypes.DELETE_PROJECT]({ commit }: AugmentedActionContext, id: string): void;

  [ProjectsActionTypes.BLOCK_PROJECT]({ commit }: AugmentedActionContext, id: string): void;

  [ProjectsActionTypes.UNBLOCK_PROJECT]({ commit }: AugmentedActionContext, id: string): void;

  [ProjectsActionTypes.GET_ONE_PROJECT]({ commit }: AugmentedActionContext, id: string): void;

  [ProjectsActionTypes.GET_ALL_PROJECT_SHORT]({ commit }: AugmentedActionContext): void;
  [ProjectsActionTypes.CLEAR_ONE_PROJECT]({ commit }: AugmentedActionContext): void;

  // Для таблицы пользователей

  [ProjectsActionTypes.FETCH_USERS]({ commit }: AugmentedActionContext, data: { id: string; query: Query }): void;

  [ProjectsActionTypes.GET_PROJECT_USERS_ROLES]({ commit }: AugmentedActionContext): void;

  [ProjectsActionTypes.ADD_USER_IN_PROJECT]({ commit }: AugmentedActionContext, data: AddUserInProject): void;

  [ProjectsActionTypes.CHANGE_ROLE_USER_IN_PROJECT](
    { commit }: AugmentedActionContext,
    data: UserInProjectChangeRole
  ): void;

  [ProjectsActionTypes.DELETE_USER_IN_CURRENT_PROJECT](
    { commit }: AugmentedActionContext,
    data: EditUserInProject
  ): void;

  [ProjectsActionTypes.UNBLOCK_USER_IN_CURRENT_PROJECT](
    { commit }: AugmentedActionContext,
    data: EditUserInProject
  ): void;

  [ProjectsActionTypes.BLOCK_USER_IN_CURRENT_PROJECT](
    { commit }: AugmentedActionContext,
    data: EditUserInProject
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ProjectsActionTypes.FETCH_PROJECTS]({ commit, getters }, query: any) {
    commit(ProjectsMutationTypes.PROJECTS_LOADING);
    try {
      let data;
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        data = await mapProjectClient.getProjects(query);
      } else {
        data = await tmapProjectClient.getProjects(query);
      }
      commit(ProjectsMutationTypes.FETCH_PROJECTS, data);
      commit(ProjectsMutationTypes.PROJECTS_SUCCEEDED);
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, (error as unknown as Error).message);
      throw new Error((error as unknown as Error).message);
    }
  },
  async [ProjectsActionTypes.CREATE_PROJECT]({ commit, getters }, data: ShortProject) {
    try {
      let response;
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        response = await mapProjectClient.createProject(data);
      } else {
        response = await tmapProjectClient.createProject(data);
      }
      commit(ProjectsMutationTypes.CREATE_PROJECT, response);
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, (error as unknown as Error).message);
      throw new Error((error as unknown as Error).message);
    }
  },
  async [ProjectsActionTypes.GET_ALL_PROJECT_SHORT]({ commit }) {
    try {
      const response = await tmapProjectClient.getAllProjectShort();
      commit(ProjectsMutationTypes.GET_ALL_PROJECT_SHORT, response);
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, (error as unknown as Error).message);
      throw new Error((error as unknown as Error).message);
    }
  },
  async [ProjectsActionTypes.EDIT_PROJECT]({ commit, getters }, data: ShortProject) {
    try {
      let response;
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        response = await mapProjectClient.editProject(data);
      } else {
        response = await tmapProjectClient.editProject(data);
      }
      commit(ProjectsMutationTypes.CREATE_PROJECT, response);
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, (error as unknown as Error).message);
      throw new Error((error as unknown as Error).message);
    }
  },

  async [ProjectsActionTypes.UPDATE_PROJECT_FILE](
    { commit, getters },
    payload: { data: UpdateFile; projectId: string }
  ) {
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapProjectClient.updateProjectFile(payload);
      } else {
        await tmapProjectClient.updateProjectFile(payload);
      }
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, (error as unknown as Error).message);
      throw new Error((error as unknown as Error).message);
    }
  },

  async [ProjectsActionTypes.DELETE_PROJECT]({ commit, getters }, id: string) {
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapProjectClient.deleteProject(id);
      } else {
        await tmapProjectClient.deleteProject(id);
      }
      commit(ProjectsMutationTypes.DELETE_PROJECT, id);
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, (error as unknown as Error).message);
      throw new Error((error as unknown as Error).message);
    }
  },

  async [ProjectsActionTypes.BLOCK_PROJECT]({ commit, getters }, id: string) {
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapProjectClient.blockProject(id);
      } else {
        await tmapProjectClient.blockProject(id);
      }
      commit(ProjectsMutationTypes.BLOCK_PROJECT, id);
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, (error as unknown as Error).message);
      throw new Error((error as unknown as Error).message);
    }
  },

  async [ProjectsActionTypes.UNBLOCK_PROJECT]({ commit, getters }, id: string) {
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapProjectClient.unblockProject(id);
      } else {
        await tmapProjectClient.unblockProject(id);
      }
      commit(ProjectsMutationTypes.UNBLOCK_PROJECT, id);
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, (error as unknown as Error).message);
      throw new Error((error as unknown as Error).message);
    }
  },

  async [ProjectsActionTypes.GET_ONE_PROJECT]({ commit, getters }, id: string) {
    commit(ProjectsMutationTypes.PROJECTS_LOADING);
    try {
      let response;
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        response = await mapProjectClient.getOneProject(id);
      } else {
        response = await tmapProjectClient.getOneProject(id);
      }
      commit(ProjectsMutationTypes.GET_ONE_PROJECT, response);
      commit(ProjectsMutationTypes.PROJECTS_SUCCEEDED);
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, (error as unknown as Error).message);
      throw new Error((error as unknown as Error).message);
    }
  },
  async [ProjectsActionTypes.CLEAR_ONE_PROJECT]({ commit }) {
    commit(ProjectsMutationTypes.CLEAR_ONE_PROJECT);
  },

  // Для таблицы пользователей

  async [ProjectsActionTypes.FETCH_USERS]({ commit, getters }, data: { id: string; query: any }) {
    commit(ProjectsMutationTypes.PROJECTS_LOADING);
    try {
      let response;
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        response = await mapProjectClient.getUsers(data);
      } else {
        response = await tmapProjectClient.getUsers(data);
      }
      commit(ProjectsMutationTypes.FETCH_USERS, response);
      commit(ProjectsMutationTypes.PROJECTS_SUCCEEDED);
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, (error as unknown as Error).message);
      throw new Error((error as unknown as Error).message);
    }
  },
  async [ProjectsActionTypes.GET_PROJECT_USERS_ROLES]({ commit }) {
    try {
      const response = await roleClient.getProjectGroupRoles();
      commit(ProjectsMutationTypes.GET_PROJECT_USERS_ROLES, response);
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, (error as unknown as Error).message);
      throw new Error((error as unknown as Error).message);
    }
  },

  async [ProjectsActionTypes.ADD_USER_IN_PROJECT]({ commit }, data: AddUserInProject) {
    commit(ProjectsMutationTypes.PROJECTS_LOADING);
    try {
      const response = await tmapProjectClient.addUsers(data);
      commit(ProjectsMutationTypes.ADD_USER_IN_PROJECT, response);
      commit(ProjectsMutationTypes.PROJECTS_SUCCEEDED);
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, (error as unknown as Error).message);
      throw new Error((error as unknown as Error).message);
    }
  },
  async [ProjectsActionTypes.CHANGE_ROLE_USER_IN_PROJECT]({ commit, getters }, data: UserInProjectChangeRole) {
    commit(ProjectsMutationTypes.PROJECTS_LOADING);
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapProjectClient.changeRole(data);
      } else {
        await tmapProjectClient.changeRole(data);
      }
      commit(ProjectsMutationTypes.CHANGE_ROLE_USER_IN_PROJECT, data);
      commit(ProjectsMutationTypes.PROJECTS_SUCCEEDED);
    } catch (error: any) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, (error as unknown as Error).message);
      console.log('action', error);
      throw new Error(error);
    }
  },
  async [ProjectsActionTypes.DELETE_USER_IN_CURRENT_PROJECT]({ commit, getters }, data: EditUserInProject) {
    commit(ProjectsMutationTypes.PROJECTS_LOADING);
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapProjectClient.deleteUser(data);
      } else {
        await tmapProjectClient.deleteUser(data);
      }
      commit(ProjectsMutationTypes.DELETE_USER_IN_CURRENT_PROJECT, data);
      commit(ProjectsMutationTypes.PROJECTS_SUCCEEDED);
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, (error as unknown as Error).message);
      throw new Error((error as unknown as Error).message);
    }
  },

  async [ProjectsActionTypes.BLOCK_USER_IN_CURRENT_PROJECT]({ commit, getters }, data: EditUserInProject) {
    commit(ProjectsMutationTypes.PROJECTS_LOADING);
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapProjectClient.blockUser(data);
      } else {
        await tmapProjectClient.blockUser(data);
      }
      commit(ProjectsMutationTypes.BLOCK_USER_IN_CURRENT_PROJECT, data);
      commit(ProjectsMutationTypes.PROJECTS_SUCCEEDED);
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, (error as unknown as Error).message);
      throw new Error((error as unknown as Error).message);
    }
  },

  async [ProjectsActionTypes.UNBLOCK_USER_IN_CURRENT_PROJECT]({ commit, getters }, data: EditUserInProject) {
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapProjectClient.unBlockUser(data);
      } else {
        await tmapProjectClient.unBlockUser(data);
      }
      commit(ProjectsMutationTypes.UNBLOCK_USER_IN_CURRENT_PROJECT, data);
      commit(ProjectsMutationTypes.PROJECTS_SUCCEEDED);
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, (error as unknown as Error).message);
      throw new Error((error as unknown as Error).message);
    }
  },

  async [ProjectsActionTypes.REMOVE_PROJECT_FILE](
    { commit, getters },
    payload: { data: UpdateFile; projectId: string }
  ) {
    try {
      const isGroupAdmin = getters.getIsAdminGroup;
      if (isGroupAdmin) {
        await mapProjectClient.removeProjectFile(payload);
      } else {
        await tmapProjectClient.removeProjectFile(payload);
      }
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, (error as unknown as Error).message);
      throw new Error((error as unknown as Error).message);
    }
  },
};
