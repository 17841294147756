import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store';
import { IProfile } from '@/@typespaces/interfaces/profile.interface';
import ProfileClient from '@/api/profile.api';
import { ProfileState } from './state';
import { Mutations } from './mutations';
import { ProfileMutationTypes } from './mutation-types';
import { ProfileActionTypes } from './action-types';

const client = new ProfileClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<ProfileState, RootState>, 'commit'>;

export interface Actions {
  [ProfileActionTypes.FETCH_PROFILE_DATA]({ commit }: AugmentedActionContext): void;

  [ProfileActionTypes.EDIT_PROFILE]({ commit }: AugmentedActionContext, data: IProfile): void;
}

export const actions: ActionTree<ProfileState, RootState> & Actions = {
  async [ProfileActionTypes.FETCH_PROFILE_DATA]({ commit }) {
    try {
      const data = await client.getProfile();
      commit(ProfileMutationTypes.FETCH_PROFILE_DATA, data);
    } catch (error: any) {
      throw new Error(error);
    }
  },
  async [ProfileActionTypes.EDIT_PROFILE]({ commit }, data: IProfile) {
    try {
      const response = await client.editProfile(data);
      if (response.success) {
        commit(ProfileMutationTypes.EDIT_PROFILE, data);
      }
    } catch (error: any) {
      throw new Error(error);
    }
  },
};
