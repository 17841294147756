import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '@/store';
import SettingsClient from '@/api/settings.api';
import { SettingsPayload } from '@/@typespaces/interfaces/form/settings-form.interface';
import { SettingsMutationTypes } from './mutation-types';
import { State } from './state';
import { Mutations } from './mutations';
import { SettingsActionTypes } from './action-types';

const client = new SettingsClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [SettingsActionTypes.FETCH_SETTINGS]({ commit }: AugmentedActionContext): void;

  [SettingsActionTypes.EDIT_SETTING]({ commit }: AugmentedActionContext, payload: SettingsPayload): void;

  [SettingsActionTypes.UPDATE_SETTINGS_FILE](
    { commit }: AugmentedActionContext,
    payload: { type: string; file_id: string }
  ): void;

  [SettingsActionTypes.REMOVE_SETTINGS_FILE](
    { commit }: AugmentedActionContext,
    payload: { type: string; file_id: string }
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [SettingsActionTypes.FETCH_SETTINGS]({ commit }) {
    commit(SettingsMutationTypes.SETTINGS_LOADING);
    try {
      const data = await client.getSettings();
      commit(SettingsMutationTypes.FETCH_SETTINGS, data);
      commit(SettingsMutationTypes.SETTINGS_SUCCEEDED);
    } catch (error) {
      commit(SettingsMutationTypes.SETTINGS_ERROR);
      throw new Error(error as string);
    }
  },
  async [SettingsActionTypes.EDIT_SETTING]({ commit }, payload: SettingsPayload) {
    try {
      await client.editSettings(payload);
    } catch (error) {
      commit(SettingsMutationTypes.SETTINGS_ERROR);
      throw new Error(error as string);
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async [SettingsActionTypes.UPDATE_SETTINGS_FILE]({ commit }, payload: { type: string; file_id: string }) {
    try {
      await client.updateFile(payload);
    } catch (error) {
      throw new Error(error as string);
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async [SettingsActionTypes.REMOVE_SETTINGS_FILE]({ commit }, payload: { type: string; file_id: string }) {
    try {
      await client.removeFile(payload);
    } catch (error) {
      throw new Error(error as string);
    }
  },
};
