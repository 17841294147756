/* eslint-disable import/no-cycle */
import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import {
  Franchise,
  IFranchise,
  IFranchiseUser,
  ShortenedFranchise,
  ShortFranchise,
} from '@/@typespaces/interfaces/franchise.interface';
import { IRole } from '@/@typespaces/interfaces/role.interfaces';
import Status from '@/@typespaces/enum/status.enum';
import { PaginationData } from '@/@typespaces/types/paginationData';
import { State } from './state';

export type Getters = {
  getFranchises(state: State): ShortFranchise[];
  getOneFranchise(state: State): Franchise | null;
  getFranchisesIds(state: State): string[];
  getFranchisesPagination(state: State): PaginationData;
  getFranchisesStatus(state: State): Status;
  getFranchisesLoading(state: State): boolean;
  getFranchiseById: (state: State) => (id: string) => ShortFranchise | undefined;
  // Для таблицы "Пользователи и роли" в отдельно выбранной франшизе
  getCurrentFranchiseUsers(state: State): null | IFranchiseUser[];
  getCurrentFranchiseUser: (state: State) => (id: string) => IFranchiseUser | undefined;
  getCurrentFranchiseUsersStatus(state: State): Status;
  getCurrentFranchiseUsersLoading(state: State): boolean;
  getCurrentFranchiseUsersPagination(state: State): PaginationData;
  getCurrentFranchiseRoles(state: State): IRole[];
  getShortenedFranchises(state: State): ShortenedFranchise[];
  getFranchisesConnectDomainStatus(state: State): boolean;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getFranchises: (state): ShortFranchise[] => state.data,
  getOneFranchise: (state): Franchise | null => state.oneFranchise,
  getFranchisesIds: (state) => state.franchisesIds,
  getFranchisesPagination: (state) => state.pagination,
  getFranchisesStatus: (state) => state.status,
  getFranchisesLoading: (state) => state.status === Status.LOADING,
  getFranchiseById: (state: State) => (id: string) => state.data.find((franchise) => franchise._id === id),
  // Для таблицы "Пользователи и роли" в отдельно выбранной франшизе
  getCurrentFranchiseUsers: (state) => state.franchiseUsers.users,
  getCurrentFranchiseUser: (state: State) => (id: string) => state.franchiseUsers.users.find((user) => user._id === id),
  getCurrentFranchiseUsersStatus: (state) => state.franchiseUsers.status,
  getCurrentFranchiseUsersLoading: (state) => state.franchiseUsers.status === Status.LOADING,
  getCurrentFranchiseUsersPagination: (state) => state.franchiseUsers.pagination,
  getCurrentFranchiseUsersIds: (state) => state.franchiseUsers.usersIds,
  getCurrentFranchiseRoles: (state) => state.roles,
  getShortenedFranchises: (state) => state.shortFranchises,
  getFranchisesConnectDomainStatus: (state: State) => state.domainStatus === Status.LOADING,
};
