import { MutationTree } from 'vuex';
import { Contacts, ContactsData } from '@/@typespaces/interfaces/contacts.interface';
import Status from '@/@typespaces/enum/status.enum';
import { State } from './state';
import { ContactsMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [ContactsMutationTypes.FETCH_CONTACTS](state: S, payload: ContactsData): void;
  [ContactsMutationTypes.EDIT_CONTACTS](state: S, payload: Contacts): void;

  [ContactsMutationTypes.CONTACTS_LOADING](state: S): void;
  [ContactsMutationTypes.CONTACTS_SUCCEEDED](state: S): void;
  [ContactsMutationTypes.CONTACTS_ERROR](state: S): void;
};
export const mutations: MutationTree<State> & Mutations = {
  [ContactsMutationTypes.FETCH_CONTACTS](state: State, data: ContactsData) {
    state.data = data.contacts;
  },
  [ContactsMutationTypes.EDIT_CONTACTS](state: State, data: Contacts) {
    state.data = data;
  },
  [ContactsMutationTypes.CONTACTS_LOADING](state: State) {
    state.status = Status.LOADING;
  },
  [ContactsMutationTypes.CONTACTS_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
  },
  [ContactsMutationTypes.CONTACTS_ERROR](state: State) {
    state.status = Status.FAILED;
  },
};
