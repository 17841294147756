import { Project, ProjectsData, ShortProject } from '@/@typespaces/interfaces/project.interface';

import { EditUserInProject, UserInProjectChangeRole } from '@/@typespaces/types/project.types';
import { Query } from '@/@typespaces/types/query.types';
import { UpdateFile } from '@/@typespaces/types/updateFranchisesFile.types';
import InstanceHttpClient from '../instance';

export default class MapProjectsClient extends InstanceHttpClient {
  constructor() {
    super('map-projects');
  }

  async getProjects(query: Query): Promise<ProjectsData> {
    try {
      return await this.apiCall({
        method: 'GET',
        params: query,
      });
    } catch (error) {
      throw new Error((error as unknown as Error).message);
    }
  }

  async createProject(body: Partial<ShortProject>): Promise<ShortProject> {
    try {
      return await this.apiCall({
        method: 'POST',
        data: body,
      });
    } catch (error) {
      throw new Error((error as unknown as Error).message);
    }
  }

  async editProject(body: Partial<ShortProject>): Promise<ShortProject> {
    const id = body._id;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: id,
        data: body,
      });
    } catch (error) {
      throw new Error((error as unknown as Error).message);
    }
  }

  async updateProjectFile(payload: { data: UpdateFile; projectId: string }): Promise<ShortProject> {
    const { data, projectId } = payload;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `${projectId}/update-file`,
        data,
      });
    } catch (error) {
      throw new Error((error as unknown as Error).message);
    }
  }

  async removeProjectFile(payload: { data: UpdateFile; projectId: string }) {
    const { data, projectId } = payload;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `${projectId}/delete-file`,
        data,
      });
    } catch (error) {
      throw new Error((error as unknown as Error).message);
    }
  }

  async deleteProject(id: string) {
    try {
      await this.apiCall({
        method: 'DELETE',
        url: id,
      });
    } catch (error) {
      throw new Error((error as unknown as Error).message);
    }
  }

  async blockProject(id: string) {
    const url = `${id}/block`;
    try {
      await this.apiCall({
        method: 'PATCH',
        url,
      });
    } catch (error) {
      throw new Error((error as unknown as Error).message);
    }
  }

  async unblockProject(id: string) {
    const url = `${id}/unblock`;
    try {
      await this.apiCall({
        method: 'PATCH',
        url,
      });
    } catch (error) {
      throw new Error((error as unknown as Error).message);
    }
  }

  async getOneProject(id: string): Promise<Project> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: id,
      });
    } catch (error) {
      throw new Error((error as unknown as Error).message);
    }
  }

  // Для таблицы пользователей

  async getUsers(data: { id: string; query: any }): Promise<any> {
    const { id, query } = data;
    try {
      return await this.apiCall({
        method: 'GET',
        url: `/${id}/users`,
        params: {
          ...query,
        },
      });
    } catch (error) {
      throw new Error((error as unknown as Error).message);
    }
  }

  async addUsers(data: { id: string; body: { email: string; roleId: string } }): Promise<{ success: true }> {
    const { id, body } = data;
    try {
      return await this.apiCall({
        method: 'POST',
        url: `/${id}/users/add-user`,
        data: body,
      });
    } catch (error) {
      throw new Error((error as unknown as Error).message);
    }
  }

  async changeRole(data: UserInProjectChangeRole) {
    const { projectId, userId, roleId } = data;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `/${projectId}/users/${userId}/change-role`,
        data: { roleId },
      });
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async deleteUser(data: EditUserInProject) {
    const { projectId, userId } = data;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `/${projectId}/users/${userId}/remove`,
      });
    } catch (error) {
      throw new Error((error as unknown as Error).message);
    }
  }

  async blockUser(data: EditUserInProject) {
    const { projectId, userId } = data;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `/${projectId}/users/${userId}/block`,
      });
    } catch (error) {
      throw new Error((error as unknown as Error).message);
    }
  }

  async unBlockUser(data: EditUserInProject) {
    const { projectId, userId } = data;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `/${projectId}/users/${userId}/unblock`,
      });
    } catch (error) {
      throw new Error((error as unknown as Error).message);
    }
  }
}
