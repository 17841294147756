import { KnowledgeBaseData } from '@/@typespaces/interfaces/knowledgeBase.interface';
import { KnowledgeContentData } from '@/@typespaces/interfaces/form/knowledge-form.interface';
import { Query } from '@/@typespaces/types/query.types';
import InstanceHttpClient from './instance';

export default class KnowledgeBaseClient extends InstanceHttpClient {
  constructor() {
    super('public-page/knowledge-base');
  }

  async getData(query: Query): Promise<KnowledgeBaseData> {
    try {
      return await this.apiCall({
        method: 'GET',
        params: query,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async editSettings(section_name: string) {
    try {
      return await this.apiCall({
        method: 'PATCH',
        data: { section_name },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async createArticle(): Promise<{ kb_content_id: string }> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: 'content/add',
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async editArticle(payload: { id: string; data: KnowledgeContentData }) {
    const { id, data } = payload;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `content/${id}`,
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async dndArticles(data: { articleId: string; order: number }[]) {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `content/dnd`,
        data: { articles: data },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async deleteFileFromArticle(payload: { kbArticleId: string; type: string; fileId: string }) {
    const { type, fileId, kbArticleId } = payload;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `content/${kbArticleId}/delete-file`,
        data: { fileId, type },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async removeArticle(id: string) {
    try {
      return await this.apiCall({
        method: 'DELETE',
        url: `content/${id}`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async publishArticle(id: string) {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `content/${id}/publish`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async unPublishArticle(id: string) {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `content/${id}/unpublish`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  // Category
  async addCategory(title: string) {
    try {
      return await this.apiCall({
        method: 'POST',
        url: `category/add`,
        data: { title },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async editCategoryPriority(data: { category_id: string; order: string }[]) {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `category/dnd`,
        data: { categories: data },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async editCategory(data: { title: string; id: string }) {
    const { title, id } = data;
    try {
      return await this.apiCall({
        method: 'POST',
        url: `category/${id}`,
        data: { title },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async removeCategory(id: string) {
    try {
      return await this.apiCall({
        method: 'DELETE',
        url: `category/${id}`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }
}
