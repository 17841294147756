import { MutationTree } from 'vuex';
import { User, UserPayload } from '@/@typespaces/interfaces/user.interface';
import { IPayloadProjectsByUserId } from '@/@typespaces/interfaces/payload.interfaces';
import Status from '@/@typespaces/enum/status.enum';
import UserStatus from '@/@typespaces/enum/user-status.enum';
import { IRole } from '@/@typespaces/interfaces/role.interfaces';
import {
  ProjectUserChangeRole,
  UserProjectBlock,
  UserProjectRemove,
  UserProjectsBlock,
  UserProjectsRemove,
  UserProjectsUnblock,
  UserProjectUnblock,
} from '@/@typespaces/types/user.types';
import { State } from './state';
import { UsersMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [UsersMutationTypes.SET_DATA_USERS](state: S, payload: UserPayload): void;
  [UsersMutationTypes.GET_ONE_USER](state: S, payload: User): void;
  [UsersMutationTypes.USER_LOADING](state: S): void;
  [UsersMutationTypes.USER_SUCCEEDED](state: S): void;
  [UsersMutationTypes.USER_ERROR](state: S, payload: string): void;
  [UsersMutationTypes.DELETE_USER](state: S, payload: string): void;
  [UsersMutationTypes.DELETE_USERS](state: S, payload: string[]): void;
  [UsersMutationTypes.BLOCK_USER](state: S, payload: string): void;
  [UsersMutationTypes.UNBLOCK_USER](state: S, payload: string): void;
  [UsersMutationTypes.BLOCK_USERS](state: S, payload: string[]): void;
  [UsersMutationTypes.UNBLOCK_USERS](state: S, payload: string[]): void;
  [UsersMutationTypes.ADD_USER](state: S, payload: User): void;
  [UsersMutationTypes.EDIT_USER](state: S, payload: { id: string; body: Partial<User> }): void;
  // Для таблицы "Проекты" в отдельно выбранном пользователе
  [UsersMutationTypes.SET_USER_PROJECTS](state: S, payload: IPayloadProjectsByUserId): void;
  [UsersMutationTypes.USER_PROJECTS_LOADING](state: S): void;
  [UsersMutationTypes.USER_PROJECTS_SUCCEEDED](state: S): void;
  [UsersMutationTypes.USER_PROJECTS_ERROR](state: S, payload: string): void;
  [UsersMutationTypes.BLOCK_USER_IN_PROJECT](state: S, payload: UserProjectBlock): void;
  [UsersMutationTypes.UNBLOCK_USER_IN_PROJECT](state: S, payload: UserProjectUnblock): void;
  [UsersMutationTypes.BLOCK_USER_IN__ALL_PROJECT](state: S, payload: UserProjectsBlock): void;
  [UsersMutationTypes.UNBLOCK_USER_IN_ALL_PROJECT](state: S, payload: UserProjectsUnblock): void;
  [UsersMutationTypes.REMOVE_USER_FROM_PROJECT](state: S, payload: UserProjectRemove): void;
  [UsersMutationTypes.REMOVE_USER_FROM_ALL_PROJECT](state: S, payload: UserProjectsRemove): void;
  [UsersMutationTypes.CHANGE_ROLE_PROJECT_USER](state: S, payload: ProjectUserChangeRole): void;
  [UsersMutationTypes.GET_FRANCHISE_GROUP_ROLES](state: S, payload: IRole[]): void;
  [UsersMutationTypes.GET_PROJECT_GROUP_ROLES](state: S, payload: IRole[]): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [UsersMutationTypes.SET_DATA_USERS](state: State, data: UserPayload) {
    state.data = data.data.users;
    state.usersIds = data.data.usersIds;
    state.pagination.page = data.page;
    state.pagination.pageSize = data.pageSize;
    state.pagination.total = data.total;
  },

  [UsersMutationTypes.GET_ONE_USER](state: State, data: User) {
    state.user = data;
  },
  [UsersMutationTypes.USER_LOADING](state: State) {
    state.status = Status.LOADING;
    state.error = null;
  },
  [UsersMutationTypes.USER_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
    state.error = null;
  },
  [UsersMutationTypes.USER_ERROR](state: State, error) {
    state.status = Status.FAILED;
    state.error = error;
  },
  [UsersMutationTypes.DELETE_USER](state: State, id: string) {
    state.data = state.data.filter((user) => user._id !== id);
  },
  [UsersMutationTypes.DELETE_USERS](state: State, ids: string[]) {
    state.data = state.data.filter((user) => !ids.includes(user._id));
  },
  [UsersMutationTypes.BLOCK_USER](state: State, id: string) {
    state.data = state.data.map((user) => {
      if (user._id === id) {
        return {
          ...user,
          status: UserStatus.BLOCKED,
        };
      }
      return user;
    });
  },
  [UsersMutationTypes.UNBLOCK_USER](state: State, id: string) {
    state.data = state.data.map((user) => {
      if (user._id === id) {
        return {
          ...user,
          status: UserStatus.ACTIVE,
        };
      }
      return user;
    });
    if (state.user) {
      state.user = { ...state.user, blocked: false };
    }
  },
  [UsersMutationTypes.ADD_USER](state: State, data: User) {
    console.log(state, data);
    // state.data = [...state.data, data];
  },
  [UsersMutationTypes.BLOCK_USERS](state: State, ids: string[]) {
    state.data = state.data.map((user) => {
      if (ids.includes(user._id)) {
        return {
          ...user,
          status: UserStatus.BLOCKED,
        };
      }
      return user;
    });
  },
  [UsersMutationTypes.UNBLOCK_USERS](state: State, ids: string[]) {
    state.data = state.data.map((user) => {
      if (ids.includes(user._id)) {
        return {
          ...user,
          status: UserStatus.ACTIVE,
        };
      }
      return user;
    });
  },
  [UsersMutationTypes.EDIT_USER](state: State, data: { id: string; body: Partial<User> }) {
    const { id, body } = data;
    const filteredUsers = state.data.filter((user) => user._id !== id);
    const findUser = state.data.find((user) => user._id === id);
    const newUser = {
      ...findUser,
      ...body,
    } as User;
    console.log(filteredUsers, newUser);
    // state.data = [...filteredUsers, newUser].sort((a, b) => (+a.identifier > +b.identifier ? 1 : -1));
  },
  // Для таблицы "Проекты" в отдельно выбранном пользователе
  [UsersMutationTypes.SET_USER_PROJECTS](state: State, data: IPayloadProjectsByUserId) {
    state.userProjects.projects = data.data.projects;
    state.userProjects.projectsIds = data.data.projectsIds;
    state.userProjects.projectUsersIds = data.data.projectUsersIds;
    state.userProjects.pagination.page = data.page;
    state.userProjects.pagination.pageSize = data.pageSize;
    state.userProjects.pagination.total = data.total;
  },
  [UsersMutationTypes.USER_PROJECTS_LOADING](state: State) {
    state.userProjects.status = Status.LOADING;
    state.userProjects.error = null;
  },
  [UsersMutationTypes.USER_PROJECTS_SUCCEEDED](state: State) {
    state.userProjects.status = Status.SUCCEEDED;
    state.userProjects.error = null;
  },
  [UsersMutationTypes.USER_PROJECTS_ERROR](state: State, error) {
    state.userProjects.status = Status.FAILED;
    state.userProjects.error = error;
  },
  [UsersMutationTypes.BLOCK_USER_IN_PROJECT](state: State, data: UserProjectBlock) {
    const { projectId } = data;
    state.userProjects.projects = state.userProjects.projects.map((project) => {
      if (project._id === projectId) {
        return {
          ...project,
          blocked: true,
        };
      }
      return project;
    });
  },
  [UsersMutationTypes.UNBLOCK_USER_IN_PROJECT](state: State, data: UserProjectUnblock) {
    const { projectId } = data;
    state.userProjects.projects = state.userProjects.projects.map((project) => {
      if (project._id === projectId) {
        return {
          ...project,
          blocked: false,
        };
      }
      return project;
    });
  },
  [UsersMutationTypes.BLOCK_USER_IN__ALL_PROJECT](state: State, data: UserProjectsBlock) {
    const { projectUsersIds } = data;
    state.userProjects.projects = state.userProjects.projects.map((project) => {
      if (projectUsersIds.includes(project.project_user_id)) {
        return {
          ...project,
          role_raw: {
            ...project.role_raw,
          },
          blocked: true,
        };
      }
      return project;
    });
  },
  [UsersMutationTypes.UNBLOCK_USER_IN_ALL_PROJECT](state: State, data: UserProjectsBlock) {
    const { projectUsersIds } = data;
    state.userProjects.projects = state.userProjects.projects.map((project) => {
      if (projectUsersIds.includes(project.project_user_id)) {
        return {
          ...project,
          role_raw: {
            ...project.role_raw,
          },
          blocked: false,
        };
      }
      return project;
    });
  },
  [UsersMutationTypes.REMOVE_USER_FROM_PROJECT](state: State, data: UserProjectRemove) {
    const { projectId } = data;
    state.userProjects.projects = state.userProjects.projects.filter((project) => project._id !== projectId);
  },
  [UsersMutationTypes.REMOVE_USER_FROM_ALL_PROJECT](state: State, data: UserProjectsRemove) {
    const { projectsIds } = data;
    state.userProjects.projects = state.userProjects.projects.filter((project) => !projectsIds.includes(project._id));
  },
  [UsersMutationTypes.CHANGE_ROLE_PROJECT_USER](state: State, data: ProjectUserChangeRole) {
    const { roleName, projectId } = data;
    state.userProjects.projects = state.userProjects.projects.map((project) => {
      if (project._id === projectId) {
        return {
          ...project,
          role: roleName,
        };
      }
      return project;
    });
  },

  [UsersMutationTypes.GET_FRANCHISE_GROUP_ROLES](state: State, roles: IRole[]) {
    state.roles = roles;
  },
  [UsersMutationTypes.GET_PROJECT_GROUP_ROLES](state: State, roles: IRole[]) {
    state.roles = roles;
  },
};
