import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { Reviews, Content } from '@/@typespaces/interfaces/review.interface';
import Status from '@/@typespaces/enum/status.enum';
import { State } from './state';

export type Getters = {
  getReviews(state: State): Reviews | null;
  getReviewLoading(state: State): boolean;
  getReviewTableLoading(state: State): boolean;
  getReviewContentById: (state: State) => (id: string) => Content | undefined;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getReviews: (state) => state.data,
  getReviewLoading: (state) => state.status === Status.LOADING,
  getReviewTableLoading: (state) => state.tableStatus === Status.LOADING,
  getReviewContentById: (state) => (id: string) => state.data?.content.find((review) => review._id === id),
};
