import { File } from '@/@typespaces/interfaces/file.interface';
import InstanceHttpClient from './instance';

export default class FileClient extends InstanceHttpClient {
  constructor() {
    super('file');
  }

  async addFile(data: FormData): Promise<File> {
    try {
      return await this.apiCall({
        method: 'POST',
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }
}
