// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum AdminsActionTypes {
  GET_ADMINS = 'GET_ADMINS',
  DELETE_ADMIN = 'DELETE_ADMIN',
  DELETE_ADMINS = 'DELETE_ADMINS',
  BLOCK_ADMIN = 'BLOCK_ADMIN',
  BLOCK_ADMINS = 'BLOCK_ADMINS',
  UNBLOCK_ADMINS = 'UNBLOCK_ADMINS',
  UNBLOCK_ADMIN = 'UNBLOCK_ADMIN',
  ADD_ADMIN = 'ADD_ADMIN',
  EDIT_ADMIN = 'EDIT_ADMIN',
}
