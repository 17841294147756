import { Modal } from 'ant-design-vue';

export const blockOne = <T>(item: T, title: string, cb: (item: T) => void): void => {
  Modal.confirm({
    title,
    okText: 'Да, заблокировать',
    cancelText: 'Отменить',
    onOk: () => cb(item),
    onCancel: () => Modal.destroyAll(),
  });
};

export const unBlockOne = <T>(item: T, title: string, cb: (item: any) => void): void => {
  Modal.confirm({
    title,
    okText: 'Да, разблокировать',
    cancelText: 'Отменить',
    onOk: () => cb(item),
    onCancel: () => Modal.destroyAll(),
  });
};

export const deleteOne = <T>(item: T, title: string, cb: (item: T) => void): void => {
  Modal.confirm({
    title,
    content: 'Это действие невозможно отменить',
    okText: 'Да, удалить',
    cancelText: 'Отменить',
    onOk: () => cb(item),
    onCancel: () => Modal.destroyAll(),
  });
};

export const deleteAll = (title: string, cb: () => void): void => {
  Modal.confirm({
    title,
    content: 'Это действие невозможно отменить',
    okText: 'Да, удалить',
    cancelText: 'Отменить',
    onOk: () => cb(),
    onCancel: () => Modal.destroyAll(),
  });
};

export const blockAll = (title: string, cb: () => void): void => {
  Modal.confirm({
    title,
    okText: 'Да, заблокировать',
    cancelText: 'Отменить',
    onOk: () => cb(),
    onCancel: () => Modal.destroyAll(),
  });
};

export const unBlockAll = (title: string, cb: () => void): void => {
  Modal.confirm({
    title,
    okText: 'Да, разблокировать',
    cancelText: 'Отменить',
    onOk: () => cb(),
    onCancel: () => Modal.destroyAll(),
  });
};

export const copyOne = <T>(item: T, title: string, cb: (item: T) => void): void => {
  Modal.confirm({
    title,
    okText: 'Да, дублировать',
    cancelText: 'Отменить',
    onOk: () => cb(item),
    onCancel: () => Modal.destroyAll(),
  });
};

export const removeOne = <T>(item: T, title: string, cb: (item: T) => void): void => {
  Modal.confirm({
    title,
    content: 'Это действие невозможно отменить',
    okText: 'Да, убрать',
    cancelText: 'Отменить',
    onOk: () => cb(item),
    onCancel: () => Modal.destroyAll(),
  });
};

export const removeAll = (title: string, cb: () => void): void => {
  Modal.confirm({
    title,
    content: 'Это действие невозможно отменить',
    okText: 'Да, убрать',
    cancelText: 'Отменить',
    onOk: () => cb(),
    onCancel: () => Modal.destroyAll(),
  });
};

export const publicOne = <T>(item: T, title: string, cb: (item: T) => void): void => {
  Modal.confirm({
    title,
    okText: 'Да, опубликовать',
    cancelText: 'Отменить',
    onOk: () => cb(item),
    onCancel: () => Modal.destroyAll(),
  });
};

export const unPublicOne = <T>(item: T, title: string, cb: (item: any) => void): void => {
  Modal.confirm({
    title,
    okText: 'Да, снять с публикации',
    cancelText: 'Отменить',
    onOk: () => cb(item),
    onCancel: () => Modal.destroyAll(),
  });
};

export const publicAll = (title: string, cb: () => void): void => {
  Modal.confirm({
    title,
    okText: 'Да, опубликовать',
    cancelText: 'Отменить',
    onOk: () => cb(),
    onCancel: () => Modal.destroyAll(),
  });
};

export const unPublicAll = (title: string, cb: () => void): void => {
  Modal.confirm({
    title,
    okText: 'Да, снять с публикации',
    cancelText: 'Отменить',
    onOk: () => cb(),
    onCancel: () => Modal.destroyAll(),
  });
};

export const leavePage = (cb: (arg?: boolean) => void) => {
  Modal.confirm({
    title: 'Вы уверены, что хотите покинуть страницу?',
    content: 'Данные будут потеряны',
    okText: 'Да, покинуть',
    cancelText: 'Отменить',
    onOk: () => cb(),
    onCancel: () => {
      cb(false);
      Modal.destroyAll();
    },
  });
};
