import Status from '@/@typespaces/enum/status.enum';
import { TemplateCategory } from '@/@typespaces/interfaces/templates.interface';

export interface State {
  data: TemplateCategory[];
  status: Status;
  error: string | null;
  changeableCategory: TemplateCategory | null;
}

export const state: State = {
  data: [],
  status: Status.IDLE,
  error: null,
  changeableCategory: null,
};
