import { SeoData, Seo } from '@/@typespaces/interfaces/seo.interface';
import { RemoveSeoFile } from '@/@typespaces/types/forms/seo.type';
import InstanceHttpClient from './instance';

export default class SeoClient extends InstanceHttpClient {
  constructor() {
    super('public-page/seo');
  }

  async getSeo(): Promise<SeoData> {
    try {
      return await this.apiCall({
        method: 'GET',
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async editSeo(data: Seo) {
    try {
      await this.apiCall({
        method: 'PATCH',
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async removeSeoFile(data: RemoveSeoFile) {
    try {
      await this.apiCall({
        method: 'PATCH',
        url: '/delete-file',
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }
}
