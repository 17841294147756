// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum FranchisesMutationTypes {
  GET_ALL_FRANCHISES = 'GET_ALL_FRANCHISES',
  SET_DATA_FRANCHISES = 'SET_DATA_FRANCHISES',
  GET_ONE_FRANCHISE = 'GET_ONE_FRANCHISE',
  CLEAR_ONE_FRANCHISE = 'CLEAR_ONE_FRANCHISE',
  FRANCHISES_LOADING = 'FRANCHISES_LOADING',
  FRANCHISES_SUCCEEDED = 'FRANCHISES_SUCCEEDED',
  FRANCHISES_ERROR = 'FRANCHISES_ERROR',
  DELETE_FRANCHISE = 'DELETE_FRANCHISE',
  BLOCK_FRANCHISE = 'BLOCK_FRANCHISE',
  UNBLOCK_FRANCHISE = 'UNBLOCK_FRANCHISE',
  ADD_FRANCHISE = 'ADD_FRANCHISE',
  EDIT_FRANCHISE = 'EDIT_FRANCHISE',
  // Для таблицы "Пользователи и роли" в отдельно выбранной франшизе
  SET_FRANCHISE_USERS = 'SET_FRANCHISE_USERS',
  GET_FRANCHISE_USERS_ROLES = 'GET_FRANCHISE_USERS_ROLES',
  USERS_FRANCHISE_LOADING = 'USERS_FRANCHISE_LOADING',
  USERS_FRANCHISE_SUCCEEDED = 'USERS_FRANCHISE_SUCCEEDED',
  USERS_FRANCHISE_ERROR = 'USERS_FRANCHISE_ERROR',
  USER_DELETE_FRANCHISE = 'USER_DELETE_FRANCHISE',
  DELETE_FRANCHISE_USER = 'DELETE_FRANCHISE_USER',
  CHANGE_ROLE_FRANCHISE_USER = 'CHANGE_ROLE_FRANCHISE_USER',
  GET_ALL_FRANCHISES_SHORT = 'GET_ALL_FRANCHISES_SHORT',

  // Domain
  CONNECT_FRANCHISES_DOMAIN = 'CONNECT_FRANCHISES_DOMAIN',
  CONNECT_SSL_TO_DOMAIN = 'CONNECT_SSL_TO_DOMAIN',
  CHECK_FRANCHISES_HTTPS_ACCESS = 'CHECK_FRANCHISES_HTTPS_ACCESS',
  REMOVE_FRANCHISES_DOMAIN = 'REMOVE_FRANCHISES_DOMAIN',
  REMOVE_CERT_OR_KEY_FILE = 'REMOVE_CERT_OR_KEY_FILE',
  CLEAR_FRANCHISES_DOMAIN_STORE = 'CLEAR_FRANCHISES_DOMAIN_STORE',
  CHECK_DOMAIN = 'CHECK_DOMAIN',
  // Domain status
  DOMAIN_FRANCHISES_LOADING = 'DOMAIN_FRANCHISES_LOADING',
  DOMAIN_FRANCHISES_SUCCEEDED = 'DOMAIN_FRANCHISES_SUCCEEDED',
  DOMAIN_FRANCHISES_ERROR = 'DOMAIN_FRANCHISES_ERROR',
}
