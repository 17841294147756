import { MutationTree } from 'vuex';
import { ServicesMutationTypes } from '@/store/modules/services/mutation-types';
import { ServicesData } from '@/@typespaces/interfaces/services.interface';
import Status from '@/@typespaces/enum/status.enum';
import { State } from './state';

export type Mutations<S = State> = {
  [ServicesMutationTypes.FETCH_SERVICES](state: S, payload: ServicesData): void;
  [ServicesMutationTypes.DELETE_SERVICE_CONTENT](state: S, payload: string): void;

  // Status
  [ServicesMutationTypes.SERVICES_ERROR](state: S, payload: string): void;
  [ServicesMutationTypes.SERVICES_LOADING](state: S): void;
  [ServicesMutationTypes.SERVICES_SUCCEEDED](state: S): void;

  [ServicesMutationTypes.SERVICE_TABLE_LOADING](state: S): void;
  [ServicesMutationTypes.SERVICE_TABLE_SUCCEEDED](state: S): void;
  [ServicesMutationTypes.SERVICE_TABLE_ERROR](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [ServicesMutationTypes.FETCH_SERVICES](state: State, payload: ServicesData) {
    state.data = payload.services;
  },

  [ServicesMutationTypes.DELETE_SERVICE_CONTENT](state: State, id: string) {
    if (state.data) {
      const content = state.data.content.filter((item) => item._id !== id);
      state.data = {
        ...state.data,
        content,
      };
    }
  },

  // Status
  [ServicesMutationTypes.SERVICES_LOADING](state) {
    state.status = Status.LOADING;
  },
  [ServicesMutationTypes.SERVICES_SUCCEEDED](state) {
    state.status = Status.SUCCEEDED;
  },
  [ServicesMutationTypes.SERVICES_ERROR](state: State, error: string) {
    state.error = error;
    state.status = Status.IDLE;
  },
  [ServicesMutationTypes.SERVICE_TABLE_LOADING](state: State) {
    state.tableStatus = Status.LOADING;
  },
  [ServicesMutationTypes.SERVICE_TABLE_SUCCEEDED](state: State) {
    state.tableStatus = Status.SUCCEEDED;
  },
  [ServicesMutationTypes.SERVICE_TABLE_ERROR](state: State) {
    state.tableStatus = Status.FAILED;
  },
};
