// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum ServicesMutationTypes {
  FETCH_SERVICES = 'FETCH_SERVICES',
  DELETE_SERVICE_CONTENT = 'DELETE_SERVICE_CONTENT',

  // Status
  SERVICES_LOADING = 'SERVICES_LOADING',
  SERVICES_SUCCEEDED = 'SERVICES_SUCCEEDED',
  SERVICES_ERROR = 'SERVICES_ERROR',

  SERVICE_TABLE_LOADING = 'SERVICE_LOADING',
  SERVICE_TABLE_SUCCEEDED = 'SERVICE_SUCCEEDED',
  SERVICE_TABLE_ERROR = 'SERVICE_ERROR',
}
