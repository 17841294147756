import { MutationTree } from 'vuex';
import { KnowledgeMutationTypes } from '@/store/modules/knowledge/mutation-types';
import { Category, KnowledgeBaseData } from '@/@typespaces/interfaces/knowledgeBase.interface';
import Status from '@/@typespaces/enum/status.enum';
import { KnowledgeBaseState } from './state';

export type Mutations<S = KnowledgeBaseState> = {
  [KnowledgeMutationTypes.FETCH_KNOWLEDGE_DATA](state: S, payload: KnowledgeBaseData): void;
  [KnowledgeMutationTypes.REMOVE_ARTICLE](state: S, payload: string): void;
  [KnowledgeMutationTypes.PUBLISH_ARTICLE](state: S, payload: string): void;
  [KnowledgeMutationTypes.UN_PUBLISH_ARTICLE](state: S, payload: string): void;
  [KnowledgeMutationTypes.DND_KB_ARTICLES](state: S, payload: { articleId: string; order: number }[]): void;
  [KnowledgeMutationTypes.KNOWLEDGE_ARTICLES_LOADING](state: S): void;
  [KnowledgeMutationTypes.KNOWLEDGE_ARTICLES_SUCCEEDED](state: S): void;

  [KnowledgeMutationTypes.CREATE_KNOWLEDGE_CATEGORY](state: S, payload: any): void;
  [KnowledgeMutationTypes.REMOVE_KNOWLEDGE_CATEGORY](state: S, payload: string): void;
  [KnowledgeMutationTypes.SET_CURRENT_CATEGORY](state: S, payload: Category | null): void;

  // Status
  [KnowledgeMutationTypes.KNOWLEDGE_DATA_LOADING](state: S): void;
  [KnowledgeMutationTypes.KNOWLEDGE_DATA_SUCCEEDED](state: S): void;
  [KnowledgeMutationTypes.KNOWLEDGE_DATA_ERROR](state: S, payload: string): void;

  [KnowledgeMutationTypes.KNOWLEDGE_CATEGORIES_LOADING](state: S): void;
  [KnowledgeMutationTypes.KNOWLEDGE_CATEGORIES_SUCCEEDED](state: S): void;
};

export const mutations: MutationTree<KnowledgeBaseState> & Mutations = {
  [KnowledgeMutationTypes.FETCH_KNOWLEDGE_DATA](state: KnowledgeBaseState, payload: KnowledgeBaseData) {
    state.data = payload.knowledge_base;
    state.categories = payload.knowledge_base.categories;
    state.articles = payload.knowledge_base.articles;
  },
  [KnowledgeMutationTypes.REMOVE_ARTICLE](state: KnowledgeBaseState, payload: string) {
    state.articles = state.articles.filter((item) => item._id !== payload);
  },
  [KnowledgeMutationTypes.PUBLISH_ARTICLE](state: KnowledgeBaseState, id: string) {
    state.articles = state.articles.map((item) => {
      if (item._id === id) {
        return { ...item, publish: true };
      }
      return item;
    });
    state.total += 1;
  },
  [KnowledgeMutationTypes.UN_PUBLISH_ARTICLE](state: KnowledgeBaseState, id: string) {
    state.articles = state.articles.map((item) => {
      if (item._id === id) {
        return { ...item, publish: false };
      }
      return item;
    });
  },
  [KnowledgeMutationTypes.CREATE_KNOWLEDGE_CATEGORY](state: KnowledgeBaseState, payload: any) {
    state.categories.push(payload);
  },

  [KnowledgeMutationTypes.REMOVE_KNOWLEDGE_CATEGORY](state: KnowledgeBaseState, payload: string) {
    state.categories = state.categories.filter((item) => item._id !== payload);
  },
  // Status

  [KnowledgeMutationTypes.KNOWLEDGE_DATA_LOADING](state: KnowledgeBaseState) {
    state.status = Status.LOADING;
  },
  [KnowledgeMutationTypes.KNOWLEDGE_DATA_SUCCEEDED](state: KnowledgeBaseState) {
    state.status = Status.SUCCEEDED;
  },
  [KnowledgeMutationTypes.KNOWLEDGE_DATA_ERROR](state: KnowledgeBaseState, error: string) {
    state.status = Status.IDLE;
    state.error = error;
  },
  [KnowledgeMutationTypes.KNOWLEDGE_CATEGORIES_LOADING](state: KnowledgeBaseState) {
    state.categoriesStatus = Status.LOADING;
  },
  [KnowledgeMutationTypes.KNOWLEDGE_CATEGORIES_SUCCEEDED](state: KnowledgeBaseState) {
    state.categoriesStatus = Status.SUCCEEDED;
  },
  [KnowledgeMutationTypes.SET_CURRENT_CATEGORY](state: KnowledgeBaseState, payload: Category | null) {
    state.currentCategory = payload;
  },
  [KnowledgeMutationTypes.KNOWLEDGE_ARTICLES_LOADING](state: KnowledgeBaseState) {
    state.articlesStatus = Status.LOADING;
  },
  [KnowledgeMutationTypes.KNOWLEDGE_ARTICLES_SUCCEEDED](state: KnowledgeBaseState) {
    state.articlesStatus = Status.SUCCEEDED;
  },
  [KnowledgeMutationTypes.DND_KB_ARTICLES](state: KnowledgeBaseState, payload: { articleId: string; order: number }[]) {
    state.articles = state.articles.map((article) => {
      const findArticle = payload.find((a) => a.articleId === article._id);
      if (findArticle) {
        return {
          ...article,
          order: findArticle.order,
        };
      }
      return article;
    });
  },
};
