export enum FranchiseUserModalTitles {
  BLOCK = 'Вы уверены, что хотите заблокировать выбранного пользователя?',
  UNBLOCK = 'Вы уверены, что хотите разблокировать выбранного пользователя?',
  BLOCK_ALL = 'Вы уверены, что хотите заблокировать выбранных пользователей?',
  UNBLOCK_ALL = 'Вы уверены, что хотите разблокировать выбранных пользователей?',
  DELETE = 'Вы уверены, что хотите удалить выбранного пользователя?',
  DELETE_ALL = 'Вы уверены, что хотите удалить выбранных пользователей?',
}

export enum FranchiseModalTitles {
  BLOCK = 'Вы уверены, что хотите заблокировать выбранную франшизу?',
  UNBLOCK = 'Вы уверены, что хотите разблокировать выбранную франшизу?',
  BLOCK_ALL = 'Вы уверены, что хотите заблокировать выбранные франшизы?',
  UNBLOCK_ALL = 'Вы уверены, что хотите разблокировать выбранные франшизы?',
  DELETE = 'Вы уверены, что хотите удалить выбранную франшизу?',
  DELETE_ALL = 'Вы уверены, что хотите удалить выбранные франшизы?',
  DISCONNECT_DOMAIN = 'Вы уверены, что хотите удалить и отключить домен?',
}

export enum UserModalTitles {
  BLOCK = 'Вы уверены, что хотите заблокировать пользователя?',
  UNBLOCK_CURRENT_USER = 'Вы уверены, что хотите разблокировать выбранного пользователя?',
  UNBLOCK_USER = 'Вы уверены, что хотите разблокировать пользователя?',
  BLOCK_ALL = 'Вы уверены, что хотите заблокировать выбранных пользователей?',
  UNBLOCK_ALL = 'Вы уверены, что хотите разблокировать выбранных пользователей?',
  DELETE = 'Вы уверены, что хотите удалить выбранного пользователя?',
  DELETE_ALL = 'Вы уверены, что хотите удалить выбранных пользователей?',
  DELETE_CURRENT_USER = 'Вы уверены, что хотите удалить пользователя?',
  BLOCK_USER_IN_PROJECT = 'Вы уверены, что хотите заблокировать пользователя в проекте?',
  UNBLOCK_USER_IN_PROJECT = 'Вы уверены, что хотите разблокировать пользователя в проекте?',
  BLOCK_USER_IN_ALL_PROJECT = 'Вы уверены, что хотите заблокировать пользователя во всех проектах?',
  UNBLOCK_USER_IN_ALL_PROJECT = 'Вы уверены, что хотите разблокировать пользователя во всех проектах?',
  REMOVE_USER_FROM_PROJECT = 'Вы уверены, что хотите убрать пользователя из проекта?',
  REMOVE_USER_FROM_ALL_PROJECT = 'Вы уверены, что хотите убрать пользователя из всех проекта?',
}

export enum ProjectModalTitles {
  BLOCK = 'Вы уверены, что хотите заблокировать проект?',
  UNBLOCK = 'Вы уверены, что хотите разблокировать проект?',
  BLOCK_ALL = 'Вы уверены, что хотите заблокировать выбранные проекты?',
  UNBLOCK_ALL = 'Вы уверены, что хотите разблокировать выбранные проекты?',
  DELETE = 'Вы уверены, что хотите удалить проект?',
  DELETE_ALL = 'Вы уверены, что хотите удалить выбранные проекты?',
  COPY = 'Вы уверены, что хотите дублировать проект?',
}

export enum ReviewsModalTitles {
  DELETE = 'Вы уверены, что хотите удалить отзыв?',
}
export enum ContactsModalTitles {
  DELETE_CONTACT = 'Вы уверены, что хотите удалить контакт?',
  DELETE_ADDRESS = 'Вы уверены, что хотите удалить адрес?',
  DELETE_EMAIL = 'Вы уверены, что хотите удалить дополнительный email?',
}

export enum ServicesModalTitles {
  DELETE = 'Вы уверены, что хотите удалить услугу?',
}

export enum FaqModalTitles {
  DELETE = 'Вы уверены, что хотите удалить вопрос?',
}

export enum KnowledgeArticlesModalTitles {
  PUBLIC = 'Вы уверены, что хотите опубликовать статью?',
  UN_PUBLIC = 'Вы уверены, что хотите снять с публикации статью?',
  UN_PUBLIC_ALL = 'Вы уверены, что хотите снять с публикации выбранные статьи?',
  PUBLIC_ALL = 'Вы уверены, что хотите опубликовать выбранные статьи?',
  DELETE = 'Вы уверены, что хотите удалить статью?',
  DELETE_ALL = 'Вы уверены, что хотите удалить выбранные статьи?',
  DELETE_CATEGORY = 'Вы уверены, что хотите удалить категорию?',
}
export enum TemplatesModalTitles {
  COPY = 'Вы уверены, что хотите дублировать шаблон?',
  PUBLIC = 'Вы уверены, что хотите опубликовать шаблон?',
  UN_PUBLIC = 'Вы уверены, что хотите снять с публикации шаблон?',
  UN_PUBLIC_ALL = 'Вы уверены, что хотите снять с публикации выбранные шаблоны?',
  PUBLIC_ALL = 'Вы уверены, что хотите опубликовать выбранные шаблоны?',
  DELETE = 'Вы уверены, что хотите удалить шаблон?',
  DELETE_ALL = 'Вы уверены, что хотите удалить выбранные шаблоны?',
}

export enum TemplatesCategoriesModalTitles {
  DELETE = 'Вы уверены, что хотите удалить категорию?',
  DELETE_ALL = 'Вы уверены, что хотите удалить выбранные категории?',
}
export enum MainPageModalTitles {
  DELETE_ADVANTAGE = 'Вы уверены, что хотите удалить преимущество?',
  DELETE_CAPABILITY = 'Вы уверены, что хотите удалить возможность?',
}
