import { Content, ServicesData } from '@/@typespaces/interfaces/services.interface';
import {
  ServicesMainPayload,
  ServicesUpdateFilePayload,
  UpdateServiceContent,
} from '@/@typespaces/interfaces/form/services-form.interface';
import InstanceHttpClient from './instance';

export default class ServicesClient extends InstanceHttpClient {
  constructor() {
    super('public-page/services');
  }

  async getServices(): Promise<ServicesData> {
    try {
      return await this.apiCall({
        method: 'GET',
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async editServices(data: ServicesMainPayload) {
    try {
      await this.apiCall({
        method: 'PATCH',
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async addServiceContent(): Promise<{ service_content_id: string }> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: 'service/add',
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async deleteServiceContent(id: string) {
    try {
      await this.apiCall({
        method: 'DELETE',
        url: `content/${id}`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async editServiceContent(payload: { id: string; data: UpdateServiceContent }) {
    const { id, data } = payload;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `content/${id}`,
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async editPriority(data: Content[]) {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: 'content/dnd',
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async updateServiceFile(data: ServicesUpdateFilePayload) {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: 'update-file',
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async removeServiceFile(data: ServicesUpdateFilePayload) {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: 'delete-file',
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }
}
