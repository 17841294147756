import { Url } from '@/@typespaces/interfaces/domain.interface';
import { MutationTree } from 'vuex';
import Status from '@/@typespaces/enum/status.enum';
import { PayloadFranchise } from '@/@typespaces/interfaces/payload.interfaces';
import {
  Franchise,
  IFranchiseUser,
  IPayloadCurrentFranchise,
  ShortenedFranchise,
  ShortFranchise,
} from '@/@typespaces/interfaces/franchise.interface';
import { FranchiseChangeRoleUser, FranchiseRemoveUser } from '@/@typespaces/types/franchise.types';
import { IRole } from '@/@typespaces/interfaces/role.interfaces';
import { FranchisesMutationTypes } from './mutation-types';
import { State } from './state';

export type Mutations<S = State> = {
  [FranchisesMutationTypes.SET_DATA_FRANCHISES](state: S, payload: PayloadFranchise): void;
  [FranchisesMutationTypes.GET_ALL_FRANCHISES](state: S, payload: ShortFranchise[]): void;
  [FranchisesMutationTypes.GET_ONE_FRANCHISE](state: S, payload: Franchise): void;
  [FranchisesMutationTypes.CLEAR_ONE_FRANCHISE](state: S): void;
  [FranchisesMutationTypes.DELETE_FRANCHISE](state: S, payload: string): void;
  [FranchisesMutationTypes.BLOCK_FRANCHISE](state: S, payload: string): void;
  [FranchisesMutationTypes.UNBLOCK_FRANCHISE](state: S, payload: string): void;
  [FranchisesMutationTypes.ADD_FRANCHISE](state: S, payload: Franchise): void;
  [FranchisesMutationTypes.EDIT_FRANCHISE](state: S, payload: Franchise): void;

  // Для таблицы "Пользователи и роли" в отдельно выбранной франшизе
  [FranchisesMutationTypes.SET_FRANCHISE_USERS](state: S, payload: IPayloadCurrentFranchise): void;
  [FranchisesMutationTypes.USER_DELETE_FRANCHISE](state: S): void;
  [FranchisesMutationTypes.GET_FRANCHISE_USERS_ROLES](state: S, payload: IRole[]): void;
  [FranchisesMutationTypes.DELETE_FRANCHISE_USER](state: S, payload: FranchiseRemoveUser): void;
  [FranchisesMutationTypes.CHANGE_ROLE_FRANCHISE_USER](state: S, payload: FranchiseChangeRoleUser): void;
  [FranchisesMutationTypes.GET_ALL_FRANCHISES_SHORT](state: S, payload: ShortenedFranchise[]): void;

  // Domain
  [FranchisesMutationTypes.CONNECT_FRANCHISES_DOMAIN](state: S, payload: Franchise): void;
  [FranchisesMutationTypes.CONNECT_SSL_TO_DOMAIN](state: S, payload: Franchise): void;
  [FranchisesMutationTypes.CHECK_FRANCHISES_HTTPS_ACCESS](state: S, payload: Url): void;
  [FranchisesMutationTypes.REMOVE_FRANCHISES_DOMAIN](state: S, payload: Franchise): void;

  // Domain status
  [FranchisesMutationTypes.DOMAIN_FRANCHISES_SUCCEEDED](state: S): void;
  [FranchisesMutationTypes.DOMAIN_FRANCHISES_LOADING](state: S): void;
  [FranchisesMutationTypes.DOMAIN_FRANCHISES_ERROR](state: S, payload: string): void;
  [FranchisesMutationTypes.DOMAIN_FRANCHISES_ERROR](state: S, payload: string): void;
  [FranchisesMutationTypes.REMOVE_CERT_OR_KEY_FILE](state: S, payload: 'cert' | 'key'): void;

  // Status
  [FranchisesMutationTypes.FRANCHISES_LOADING](state: S): void;
  [FranchisesMutationTypes.FRANCHISES_SUCCEEDED](state: S): void;
  [FranchisesMutationTypes.FRANCHISES_ERROR](state: S, payload: string): void;
  [FranchisesMutationTypes.USERS_FRANCHISE_LOADING](state: S): void;
  [FranchisesMutationTypes.USERS_FRANCHISE_SUCCEEDED](state: S): void;
  [FranchisesMutationTypes.USERS_FRANCHISE_ERROR](state: S, payload: string): void;
  [FranchisesMutationTypes.CHECK_DOMAIN](
    state: S,
    payload: {
      checkDomain: string;
      cname: boolean;
      https: boolean;
      aRecord: boolean;
    }
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [FranchisesMutationTypes.SET_DATA_FRANCHISES](state: State, data: PayloadFranchise) {
    state.data = data.data.franchises;
    state.franchisesIds = data.data.franchisesIds;
    state.pagination.page = data.page;
    state.pagination.pageSize = data.pageSize;
    state.pagination.total = data.total;
  },
  [FranchisesMutationTypes.GET_ONE_FRANCHISE](state: State, data: Franchise) {
    state.oneFranchise = data;
  },
  [FranchisesMutationTypes.CLEAR_ONE_FRANCHISE](state: State) {
    state.oneFranchise = null;
  },
  [FranchisesMutationTypes.GET_ALL_FRANCHISES](state: State, data: ShortFranchise[]) {
    state.data = data;
  },
  [FranchisesMutationTypes.DELETE_FRANCHISE](state: State, id: string) {
    state.data = state.data.filter((franchise) => franchise._id !== id);
  },

  [FranchisesMutationTypes.BLOCK_FRANCHISE](state: State, id: string) {
    state.data = state.data.map((franchise) => {
      if (franchise._id === id) {
        return {
          ...franchise,
          blocked: true,
        };
      }
      return franchise;
    });
  },
  [FranchisesMutationTypes.UNBLOCK_FRANCHISE](state: State, id: string) {
    state.data = state.data.map((franchise) => {
      if (franchise._id === id) {
        return {
          ...franchise,
          blocked: false,
        };
      }
      return franchise;
    });
  },
  [FranchisesMutationTypes.ADD_FRANCHISE](state: State, data: Franchise) {
    state.data = [
      ...state.data,
      {
        _id: data._id,
        title: data.title,
        expiry_date: data.expiry_date,
        blocked: data.blocked,
        quantity_users: data.quantity_users,
        quantity_projects: data.quantity_projects,
      },
    ];
  },

  [FranchisesMutationTypes.EDIT_FRANCHISE](state: State, data: Franchise) {
    const filteredFranchises = state.data.filter((franchise) => franchise._id !== data._id);
    state.data = [...filteredFranchises, data];
  },
  // Для таблицы "Пользователи и роли" в отдельно выбранной франшизе
  [FranchisesMutationTypes.SET_FRANCHISE_USERS](state: State, data: IPayloadCurrentFranchise) {
    state.franchiseUsers.users = data.data.users;
    state.franchiseUsers.usersIds = data.data.usersIds;
    state.franchiseUsers.pagination.page = data.page;
    state.franchiseUsers.pagination.pageSize = data.pageSize;
    state.franchiseUsers.pagination.total = data.total;
  },

  [FranchisesMutationTypes.GET_FRANCHISE_USERS_ROLES](state: State, payload: IRole[]) {
    state.roles = payload;
  },
  [FranchisesMutationTypes.USER_DELETE_FRANCHISE](state: State) {
    state.franchiseUsers.users = [];
    state.franchiseUsers.status = Status.IDLE;
    state.franchiseUsers.error = null;
  },

  [FranchisesMutationTypes.DELETE_FRANCHISE_USER](state: State, data: FranchiseRemoveUser) {
    state.franchiseUsers.users = state.franchiseUsers.users.filter((franchise) => franchise._id !== data.userId);
  },
  [FranchisesMutationTypes.CHANGE_ROLE_FRANCHISE_USER](state: State, data: FranchiseChangeRoleUser) {
    state.franchiseUsers.users = state.franchiseUsers.users.map((user) => {
      if (user._id === data.userId) {
        return {
          ...user,
          role: data.roleName,
        };
      }
      return user;
    }) as IFranchiseUser[];
  },
  [FranchisesMutationTypes.GET_ALL_FRANCHISES_SHORT](state: State, data: ShortenedFranchise[]) {
    state.shortFranchises = data;
  },

  // Domain
  [FranchisesMutationTypes.CONNECT_FRANCHISES_DOMAIN](state: State, payload: Franchise) {
    if (state.oneFranchise) {
      state.oneFranchise = payload;
    }
  },
  [FranchisesMutationTypes.CONNECT_SSL_TO_DOMAIN](state: State, payload: Franchise) {
    if (state.oneFranchise) {
      state.oneFranchise = {
        ...state.oneFranchise,
        ...payload,
      };
    }
  },
  [FranchisesMutationTypes.CHECK_DOMAIN](
    state: State,
    payload: {
      checkDomain: string;
      cname: boolean;
      https: boolean;
      aRecord: boolean;
    }
  ) {
    if (state.oneFranchise) {
      state.oneFranchise = {
        ...state.oneFranchise,
        domain_settings: {
          ...state.oneFranchise.domain_settings,
          candidate_domain: payload.checkDomain,
          cname: payload.cname,
          https: payload.https,
          aRecord: payload.aRecord,
        },
      };
    }
    if (state.franchise.data) {
      state.franchise.data.domain_settings = {
        ...state.franchise.data.domain_settings,
        candidate_domain: payload.checkDomain,
        cname: payload.cname,
        https: payload.https,
      };
    }
  },
  [FranchisesMutationTypes.REMOVE_CERT_OR_KEY_FILE](state: State, payload: 'cert' | 'key') {
    if (state.oneFranchise) {
      state.oneFranchise.domain_settings[payload] = null;
    }
  },
  [FranchisesMutationTypes.CHECK_FRANCHISES_HTTPS_ACCESS](state: State, payload: Url) {
    if (state.oneFranchise) {
      state.oneFranchise = {
        ...state.oneFranchise,
        domain_settings: {
          ...state.oneFranchise.domain_settings,
          main_domain: {
            url: payload.url,
          },
        },
      };
    }
  },
  [FranchisesMutationTypes.REMOVE_FRANCHISES_DOMAIN](state: State, payload: Franchise) {
    if (state.oneFranchise) {
      state.oneFranchise = payload;
    }
  },

  // Statuses
  [FranchisesMutationTypes.FRANCHISES_LOADING](state: State) {
    state.status = Status.LOADING;
    state.error = null;
  },
  [FranchisesMutationTypes.FRANCHISES_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
    state.error = null;
  },
  [FranchisesMutationTypes.FRANCHISES_ERROR](state: State, error) {
    state.status = Status.FAILED;
    state.error = error;
  },

  [FranchisesMutationTypes.USERS_FRANCHISE_LOADING](state: State) {
    state.franchiseUsers.status = Status.LOADING;
    state.franchiseUsers.error = null;
  },
  [FranchisesMutationTypes.USERS_FRANCHISE_SUCCEEDED](state: State) {
    state.franchiseUsers.status = Status.SUCCEEDED;
    state.franchiseUsers.error = null;
  },
  [FranchisesMutationTypes.USERS_FRANCHISE_ERROR](state: State, error) {
    state.franchiseUsers.status = Status.FAILED;
    state.franchiseUsers.error = error;
  },

  // Domain status

  [FranchisesMutationTypes.DOMAIN_FRANCHISES_LOADING](state: State) {
    state.domainStatus = Status.LOADING;
    state.error = null;
  },
  [FranchisesMutationTypes.DOMAIN_FRANCHISES_SUCCEEDED](state: State) {
    state.domainStatus = Status.SUCCEEDED;
    state.error = null;
  },
  [FranchisesMutationTypes.DOMAIN_FRANCHISES_ERROR](state: State, error) {
    state.domainStatus = Status.FAILED;
    state.error = error;
  },
};
