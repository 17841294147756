import { Franchise } from '@/@typespaces/interfaces/franchise.interface';
import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { State } from '@/store/modules/franchise/state';
import { ExpiryNotification } from '@/@typespaces/types/expiry-notification.types';
import Status from '@/@typespaces/enum/status.enum';

export type Getters = {
  getPublicPageAvailable(state: State): boolean;
  getExpiryFranchise(state: State): ExpiryNotification | null;
  getCurrentFranchise(state: State): Franchise | null;
  getCurrentFranchiseTenant(state: State): string | undefined;
  getFranchiseConnectDomainStatus(state: State): boolean;
  getFranchiseStatus(state: State): boolean;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getPublicPageAvailable: (state: State) => state.franchise?.public_page_available || false,
  getExpiryFranchise: (state: State) => {
    if (state.franchise) {
      const now = new Date(Date.now());
      const expiry_date = new Date(state.franchise.expiry_date);
      const diff_ms = expiry_date.getTime() - now.getTime();
      const diff_day = Math.floor(diff_ms / (1000 * 3600 * 24));
      return {
        title: state.franchise.title,
        expiry_days: diff_day,
        isWarning: diff_day > 0 && diff_day <= state.franchise.notice_of_terminations_days,
      };
    }
    return null;
  },
  getCurrentFranchise: (state: State) => state.franchise,
  getCurrentFranchiseTenant: (state: State) => state.franchise?.franchise_tenant_id,
  getFranchiseConnectDomainStatus: (state: State) => state.domainStatus === Status.LOADING,
  getFranchiseStatus: (state: State) => state.status === Status.LOADING,
};
