/* eslint-disable import/no-cycle */
import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { User, UserProjects, ShortUser } from '@/@typespaces/interfaces/user.interface';
import { IRole } from '@/@typespaces/interfaces/role.interfaces';
import Status from '@/@typespaces/enum/status.enum';
import { PaginationData } from '@/@typespaces/types/paginationData';
import { State } from './state';

export type Getters = {
  getUsers(state: State): ShortUser[];
  getUsersIds(state: State): string[];
  getUserRoles(state: State): IRole[];
  getUsersLoading(state: State): boolean;
  getUsersPagination(state: State): PaginationData;
  getUserById: (state: State) => (id: string) => ShortUser | undefined;
  getOneUser: (state: State) => User | null;
  // Для таблицы "Проекты" в отдельно выбранном пользователе
  getCurrentUserProjects(state: State): UserProjects[];
  getCurrentUserProjectsLoading(state: State): boolean;
  getCurrentUserProjectsIds(state: State): string[];
  getCurrentUserProjectUsersIds(state: State): string[];
  getCurrentUserProjectsPagination(state: State): PaginationData;
  getCurrentUserFranchisesTitles(state: State): string;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getUsers: (state) => state.data,
  getUsersIds: (state) => state.usersIds,
  getUserRoles: (state) => state.roles,
  getUsersLoading: (state) => state.status === Status.LOADING,
  getUsersPagination: (state) => state.pagination,
  getOneUser: (state) => state.user,
  getUserById: (state) => (id: string) => state.data.find((user) => user._id === id),
  getCurrentUserProjects: (state) => state.userProjects.projects,
  getCurrentUserProjectsLoading: (state) => state.userProjects.status === Status.LOADING,
  getCurrentUserProjectsIds: (state) => state.userProjects.projectsIds,
  getCurrentUserProjectUsersIds: (state) => state.userProjects.projectUsersIds,
  getCurrentUserProjectsPagination: (state: State) => state.userProjects.pagination,
  getCurrentUserFranchisesTitles: (state: State) => {
    const franchisesTitles = state.user?.franchises.map((item) => item.franchise_title);
    if (franchisesTitles) {
      return franchisesTitles.join(', ');
    }
    return '';
  },
};
