export const ADMINS_LISTING = ['администратор', 'администратора', 'администраторов'] as [
  'администратор',
  'администратора',
  'администраторов'
];
export const USERS_LISTING = ['пользователь', 'пользователя', 'пользователей'] as [
  'пользователь',
  'пользователя',
  'пользователей'
];
export const FRANCHISE_LISTING = ['франшиза', 'франшизы', 'франшиз'] as ['франшиза', 'франшизы', 'франшиз'];
export const PROJECT_LISTING = ['проект', 'проекта', 'проектов'] as ['проект', 'проекта', 'проектов'];
export const TEMPLATE_LISTING = ['шаблон', 'шаблона', 'шаблонов'] as ['шаблон', 'шаблона', 'шаблонов'];
export const DAY_LISTING = ['день', 'дня', 'дней'] as ['день', 'дня', 'дней'];
