import Status from '@/@typespaces/enum/status.enum';
import {
  ShortFranchise,
  IFranchise,
  IFranchiseUser,
  ShortenedFranchise,
  Franchise,
} from '@/@typespaces/interfaces/franchise.interface';
import { IRole } from '@/@typespaces/interfaces/role.interfaces';

export interface State {
  data: ShortFranchise[];
  oneFranchise: Franchise | null;
  franchisesIds: string[];
  roles: IRole[];
  status: Status;
  error: null | string;
  pagination: {
    page: number;
    pageSize: number;
    total: number;
  };
  franchise: {
    data: IFranchise | null;
    status: Status;
    error: null | string;
  };
  franchiseUsers: {
    users: IFranchiseUser[];
    usersIds: string[];
    status: Status;
    error: null | string;
    pagination: {
      page: number;
      pageSize: number;
      total: number;
    };
  };
  shortFranchises: ShortenedFranchise[];

  domainStatus: Status;
}

export const state: State = {
  data: [],
  oneFranchise: null,
  franchisesIds: [],
  roles: [],
  status: Status.IDLE,
  error: null,
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
  },
  franchise: {
    data: null,
    status: Status.IDLE,
    error: null,
  },
  franchiseUsers: {
    users: [],
    usersIds: [],
    status: Status.IDLE,
    error: null,
    pagination: {
      page: 1,
      pageSize: 10,
      total: 0,
    },
  },
  shortFranchises: [],
  domainStatus: Status.IDLE,
};
