// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum FranchisesActionTypes {
  GET_FRANCHISES = 'GET_FRANCHISES',
  GET_ALL_FRANCHISES = 'GET_ALL_FRANCHISES',
  GET_ONE_FRANCHISE = 'GET_ONE_FRANCHISE',
  CLEAR_ONE_FRANCHISE = 'CLEAR_ONE_FRANCHISE',
  DELETE_FRANCHISE = 'DELETE_FRANCHISE',
  BLOCK_FRANCHISE = 'BLOCK_FRANCHISE',
  UNBLOCK_FRANCHISE = 'UNBLOCK_FRANCHISE',
  ADD_FRANCHISE = 'ADD_FRANCHISE',
  EDIT_FRANCHISE = 'EDIT_FRANCHISE',
  UPDATE_FRANCHISES_FILE = 'UPDATE_FRANCHISES_FILE',
  REMOVE_FRANCHISES_FILE = 'REMOVE_FRANCHISES_FILE',
  // Для таблицы "Пользователи и роли" в отдельно выбранной франшизе
  GET_FRANCHISE_USERS = 'GET_FRANCHISE_USERS',
  GET_FRANCHISE_USERS_ROLES = 'GET_FRANCHISE_USERS_ROLES',
  ADD_FRANCHISE_USERS = 'ADD_FRANCHISE_USERS',
  DELETE_FRANCHISE_USER = 'DELETE_FRANCHISE_USER',
  CHANGE_ROLE_FRANCHISE_USER = 'CHANGE_ROLE_FRANCHISE_USER',
  GET_ALL_FRANCHISES_SHORT = 'GET_ALL_FRANCHISES_SHORT',

  // Domain

  CONNECT_FRANCHISES_DOMAIN = 'CONNECT_FRANCHISES_DOMAIN',
  CONNECT_SSL_TO_DOMAIN = 'CONNECT_SSL_TO_DOMAIN',
  CHECK_FRANCHISES_HTTPS_ACCESS = 'CHECK_FRANCHISES_HTTPS_ACCESS',
  REMOVE_FRANCHISES_DOMAIN = 'REMOVE_FRANCHISES_DOMAIN',
  REMOVE_CERT_OR_KEY_FILE = 'REMOVE_CERT_OR_KEY_FILE',
  CLEAR_FRANCHISES_DOMAIN_STORE = 'CLEAR_FRANCHISES_DOMAIN_STORE',
  CHECK_EXIST_DOMAIN = 'CHECK_EXIST_DOMAIN',
  CHECK_DOMAIN = 'CHECK_DOMAIN',
  CHECK_WILDCARD_CERT_FILE = 'CHECK_WILDCARD_CERT_FILE',
  CHECK_EXPIRY_DATE_DOMAIN = 'CHECK_EXPIRY_DATE_DOMAIN',
  CHANGE_HTTPS_ACCESS_STATE_DOMAIN = 'CHANGE_HTTPS_ACCESS_STATE_DOMAIN',
}
