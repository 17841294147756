// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum UsersMutationTypes {
  SET_DATA_USERS = 'SET_DATA_USERS',
  GET_ONE_USER = 'GET_ONE_USER',
  USER_LOADING = 'USER_LOADING',
  USER_SUCCEEDED = 'USER_SUCCEEDED',
  USER_ERROR = 'USER_ERROR',
  DELETE_USER = 'DELETE_USER',
  DELETE_USERS = 'DELETE_USERS',
  BLOCK_USER = 'BLOCK_USER',
  UNBLOCK_USER = 'UNBLOCK_USER',
  BLOCK_USERS = 'BLOCK_USERS',
  UNBLOCK_USERS = 'UNBLOCK_USERS',
  ADD_USER = 'ADD_USER',
  EDIT_USER = 'EDIT_USER',
  // Для таблицы "Проекты" в отдельно выбранном пользователе
  SET_USER_PROJECTS = 'SET_USER_PROJECTS',
  USER_PROJECTS_LOADING = 'USER_PROJECTS_LOADING',
  USER_PROJECTS_SUCCEEDED = 'USER_PROJECTS_SUCCEEDED',
  USER_PROJECTS_ERROR = 'USER_PROJECTS_ERROR',
  BLOCK_USER_IN_PROJECT = 'BLOCK_USER_IN_PROJECT',
  UNBLOCK_USER_IN_PROJECT = 'UNBLOCK_USER_IN_PROJECT',
  REMOVE_USER_FROM_PROJECT = 'REMOVE_USER_FROM_PROJECT',
  BLOCK_USER_IN__ALL_PROJECT = 'BLOCK_USER_IN__ALL_PROJECT',
  UNBLOCK_USER_IN_ALL_PROJECT = 'UNBLOCK_USER_IN_ALL_PROJECT',
  REMOVE_USER_FROM_ALL_PROJECT = 'REMOVE_USER_FROM_ALL_PROJECT',
  CHANGE_ROLE_PROJECT_USER = 'CHANGE_ROLE_PROJECT_USER',
  GET_FRANCHISE_GROUP_ROLES = 'GET_FRANCHISE_GROUP_ROLES',
  GET_PROJECT_GROUP_ROLES = 'GET_PROJECT_GROUP_ROLES',
}
