import { Contacts, ContactsData } from '@/@typespaces/interfaces/contacts.interface';
import InstanceHttpClient from './instance';

export default class ContactsClient extends InstanceHttpClient {
  constructor() {
    super('public-page/contacts');
  }

  async getContacts(): Promise<ContactsData> {
    try {
      return await this.apiCall({
        method: 'GET',
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async editContact(body: Partial<Contacts>) {
    try {
      await this.apiCall({
        method: 'PATCH',
        data: body,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }
}
