// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum MainPageActionTypes {
  FETCH_MAIN_PAGE = 'FETCH_MAIN_PAGE',
  EDIT_MAIN_PAGE = 'EDIT_MAIN_PAGE',
  ADD_BENEFIT = 'ADD_BENEFIT',
  ADD_CAPABILITY = 'ADD_CAPABILITY',
  MAIN_PAGE_UPDATE_FILE = 'MAIN_PAGE_UPDATE_FILE',
  MAIN_PAGE_REMOVE_FILE = 'MAIN_PAGE_REMOVE_FILE',
  DELETE_BENEFIT = 'DELETE_BENEFIT',
  DELETE_CAPABILITY = 'DELETE_CAPABILITY',
  UPDATE_BENEFITS = 'UPDATE_BENEFITS',
  UPDATE_CAPABILITIES = 'UPDATE_CAPABILITIES',
}
