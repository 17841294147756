import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '@/store';
import SeoClient from '@/api/seo.api';
import { Seo } from '@/@typespaces/interfaces/seo.interface';
import { RemoveSeoFile } from '@/@typespaces/types/forms/seo.type';
import { SeoMutationTypes } from './mutation-types';
import { State } from './state';
import { Mutations } from './mutations';
import { SeoActionTypes } from './action-types';

const client = new SeoClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [SeoActionTypes.FETCH_SEO]({ commit }: AugmentedActionContext): void;

  [SeoActionTypes.EDIT_SEO]({ commit }: AugmentedActionContext, data: Seo): void;

  [SeoActionTypes.REMOVE_SEO_FILE]({ commit }: AugmentedActionContext, payload: RemoveSeoFile): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [SeoActionTypes.FETCH_SEO]({ commit }) {
    commit(SeoMutationTypes.SEO_LOADING);
    try {
      const data = await client.getSeo();
      commit(SeoMutationTypes.SEO_SUCCEEDED);
      commit(SeoMutationTypes.FETCH_SEO, data);
    } catch (e) {
      commit(SeoMutationTypes.SEO_ERROR);
      throw new Error(e as string);
    }
  },
  async [SeoActionTypes.EDIT_SEO]({ commit }, payload: Seo) {
    try {
      await client.editSeo(payload);
    } catch (e) {
      commit(SeoMutationTypes.SEO_ERROR);
      throw new Error(e as string);
    }
  },
  async [SeoActionTypes.REMOVE_SEO_FILE]({ commit }, payload: RemoveSeoFile) {
    try {
      await client.removeSeoFile(payload);
    } catch (e) {
      commit(SeoMutationTypes.SEO_ERROR);
      throw new Error(e as string);
    }
  },
};
