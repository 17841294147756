// Core
import { computed, reactive, ref, Ref, watch } from 'vue';
import { RouteRecordName, useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

// Types
import { Query } from '@/@typespaces/types/query.types';
import { TableProps } from 'ant-design-vue';
import { Key } from 'ant-design-vue/es/_util/type';
import { PaginationData } from '@/@typespaces/types/paginationData';

type RouterDataType = {
  name: RouteRecordName | undefined;
  query: Query;
  params?: { id: string | undefined };
};

const useTable = <T>(
  actionType: string,
  paginationData?: Ref<PaginationData> | null,
  data?: Ref | null,
  id?: string
) => {
  const router = useRouter();
  const route = useRoute();
  const store = useStore();
  const pagination = computed(() => ({
    current: paginationData?.value.page,
    pageSize: paginationData?.value.pageSize,
    showSizeChanger: true,
    size: 'large',
    pageSizeOptions: ['10', '20', '50', '100'],
    position: ['bottomCenter'],
    total: paginationData?.value.total,
  }));
  const selectedState = reactive<{ selectedRowKeys: Key[] | string[]; selectedElements: T[] }>({
    selectedRowKeys: [],
    selectedElements: [],
  });

  const getRouterName = () => {
    if (route.name) {
      return route.name;
    }
    return undefined;
  };

  const createRequest = async (query: Query) => {
    const routerData: RouterDataType = {
      name: getRouterName(),
      query,
      params: { id },
    };

    if (id) {
      await store.dispatch(actionType, { id, query });
    } else {
      delete routerData.params;
      await store.dispatch(actionType, query);
    }

    await router.replace(routerData);
  };

  const rowSelection = ref({
    onChange: (selectedRowKeys: Key[] | string[], selectedRows: any[]) => {
      selectedState.selectedRowKeys = selectedRowKeys;
      selectedState.selectedElements = selectedRows;
    },
    onSelectAll: () => {
      selectedState.selectedRowKeys = data?.value.map((e: T, i: number) => i.toString());
    },
  });

  const onChange: TableProps<T>['onChange'] = async (pagination: any, filters: any, sorter: any) => {
    selectedState.selectedRowKeys = [];
    let order;
    switch (sorter.order) {
      case 'descend':
        order = 'DESC';
        break;
      case 'ascend':
        order = 'ASC';
        break;
      default:
        order = '';
        break;
    }
    const query: Query = {
      ...router.currentRoute.value.query,
      page: pagination.current,
      pageSize: pagination.pageSize,
    };
    if (sorter.order) {
      query._sort = `${sorter.field}:${order}`;
    }
    await createRequest(query);
  };

  const handleClickRow = (cd: () => void) => ({
    onClick: (event: PointerEvent) => {
      const element = event.target as HTMLDivElement;
      if (element.closest('.actions')) {
        return;
      }
      cd();
    },
  });

  watch(
    () => data?.value,
    async (data) => {
      if (paginationData?.value && !data.length && paginationData?.value.page > 1) {
        const query: Query = {
          ...router.currentRoute.value.query,
          page: (paginationData.value.page - 1).toString(),
          pageSize: paginationData.value.pageSize.toString(),
        };
        await createRequest(query);
      }
    }
  );

  return {
    onChange,
    rowSelection,
    selectedState,
    pagination,
    handleClickRow,
  };
};

export default useTable;
