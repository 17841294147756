import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { ShortTemplate, Template } from '@/@typespaces/interfaces/templates.interface';
import Status from '@/@typespaces/enum/status.enum';
import { PaginationData } from '@/@typespaces/types/paginationData';
import { State } from './state';

export type Getters = {
  getTemplates(state: State): ShortTemplate[];
  getTemplatesStatus(state: State): boolean;
  getTemplatesById: (state: State) => (id: string) => ShortTemplate | undefined;
  getOneTemplate(state: State): Template | null;
  getTemplatesPagination(state: State): PaginationData;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getTemplates: (state) => state.data,
  getTemplatesById: (state) => (id: string) => state.data.find((template) => template._id === id),
  getOneTemplate: (state) => state.oneTemplate,
  getTemplatesPagination: (state) => state.pagination,
  getTemplatesStatus: (state) => state.status === Status.LOADING,
};
