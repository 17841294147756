// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum FranchiseMutationTypes {
  GET_FRANCHISE = 'GET_FRANCHISE',
  CLEAR_FRANCHISE = 'CLEAR_FRANCHISE',
  UPDATE_FRANCHISE = 'UPDATE_FRANCHISE',
  FRANCHISE_ERROR = 'FRANCHISE_ERROR',

  // Domain
  CONNECT_FRANCHISE_DOMAIN = 'CONNECT_FRANCHISE_DOMAIN',
  CONNECT_SSL_TO_DOMAIN = 'CONNECT_SSL_TO_DOMAIN',
  REMOVE_CERT_OR_KEY_FILE = 'REMOVE_CERT_OR_KEY_FILE',
  CHECK_FRANCHISE_HTTPS_ACCESS = 'CHECK_FRANCHISE_HTTPS_ACCESS',
  REMOVE_FRANCHISE_DOMAIN = 'REMOVE_FRANCHISE_DOMAIN',
  CLEAR_FRANCHISE_DOMAIN_STORE = 'CLEAR_FRANCHISE_DOMAIN_STORE',
  // Domain status
  DOMAIN_FRANCHISE_LOADING = 'DOMAIN_FRANCHISE_LOADING',
  DOMAIN_FRANCHISE_SUCCEEDED = 'DOMAIN_FRANCHISE_SUCCEEDED',
  DOMAIN_FRANCHISE_ERROR = 'DOMAIN_FRANCHISE_ERROR',

  FRANCHISE_LOADING = 'FRANCHISE_LOADING',
  FRANCHISE_SUCCEEDED = 'FRANCHISE_SUCCEEDED',
}
