type TableAdminDataType = {
  key: string;
  id: string;
  fio: string;
  email: string;
  status: boolean;
  actions: string;
};

export type TableUserDataType = {
  key: string;
  id: string;
  fio: string;
  email: string;
  role: string;
  franchise: string;
  quantityProjects: number;
  status: boolean;
  actions: string;
  activity: string;
};

export type TableFranchiseDataType = {
  key: string;
  id: string;
  franchise: string;
  owner: string;
  eDate: string;
  role: string;
  quantityProjects: number;
  quantityUsers: number;
  status: boolean;
  actions: string;
};

export type TableProjectDataType = {
  key: string;
  id: string;
  title: string;
  domain: string;
  franchise: string;
  bDate: string;
  uDate: string;
  visitors: number;
  status: boolean;
  actions: string;
  role?: string;
};

export type TableTemplatesDataType = {
  key: string;
  id: string;
  title: string;
  category: string;
  quantityProjects: number;
  uDate: string;
  price: string;
  status: boolean;
  actions: string;
};

export const adminData: TableAdminDataType[] = [
  {
    key: '1',
    id: '1',
    fio: 'Иванов Иван Иванович',
    email: 'ivanov@companyname.com',
    status: true,
    actions: '...',
  },
  {
    key: '2',
    id: '2',
    fio: 'Константинопольский Константин Константинович',
    email: 'konstantin@gmail.com',
    status: true,
    actions: '...',
  },
  {
    key: '3',
    id: '3',
    fio: 'Сергеева Анастасия Константиновна',
    email: 'nastyasunny@yandex.ru',
    status: false,
    actions: '...',
  },
  {
    key: '4',
    id: '4',
    fio: 'Макарова Ольга Владимировна',
    email: 'makarova@eshop.ru',
    status: true,
    actions: '...',
  },
  {
    key: '5',
    id: '5',
    fio: 'Калинкина Екатерина Тарановна',
    email: 'kaylynnkipshutz@gmail.com',
    status: true,
    actions: '...',
  },
  {
    key: '6',
    id: '6',
    fio: 'Сергеев Борис Иванович',
    email: 'brandonbator@mail.ru',
    status: true,
    actions: '...',
  },
  {
    key: '7',
    id: '7',
    fio: 'Линкольн Роман Сергеевич',
    email: 'linkoln@mail.ru',
    status: true,
    actions: '...',
  },
  {
    key: '8',
    id: '8',
    fio: 'Кальцоне Евгений Викторовнич',
    email: 'calzoni23@mail.ru',
    status: true,
    actions: '...',
  },
  {
    key: '9',
    id: '9',
    fio: 'Саймон Иванович Петров',
    email: 'simon1991@mail.ru',
    status: true,
    actions: '...',
  },
  {
    key: '10',
    id: '10',
    fio: 'Аминоф Елизавета Викторовна',
    email: 'aminoff@mail.ru',
    status: false,
    actions: '...',
  },
];

export const userData: TableUserDataType[] = [
  {
    key: '1',
    id: '1',
    fio: 'Иванов И.И.',
    email: 'ivanov@companyname.com',
    role: 'Владелец',
    franchise: 'SuperFranchise',
    quantityProjects: 4,
    status: true,
    actions: '...',
    activity: new Date().toISOString(),
  },
  {
    key: '2',
    id: '2',
    fio: 'Константинопольский К.К.',
    email: 'konstantin@gmail.com',
    role: 'Владелец',
    franchise: 'SuperFranchise',
    quantityProjects: 2,
    status: true,
    actions: '...',
    activity: new Date().toISOString(),
  },
  {
    key: '3',
    id: '3',
    fio: 'Сергеева А.К.',
    email: 'nastyasunny@yandex.ru',
    role: 'Владелец',
    franchise: 'SuperFranchise',
    quantityProjects: 2,
    status: false,
    actions: '...',
    activity: new Date().toISOString(),
  },
  {
    key: '4',
    id: '4',
    fio: 'Макарова О.В.',
    email: 'makarova@eshop.ru',
    role: 'Владелец',
    franchise: 'SuperFranchise',
    quantityProjects: 3,
    status: true,
    actions: '...',
    activity: new Date().toISOString(),
  },
  {
    key: '5',
    id: '5',
    fio: 'Калинкина Е.Т.',
    email: 'kaylynnkipshutz@gmail.com',
    role: 'Владелец',
    franchise: '-',
    quantityProjects: 1,
    status: true,
    actions: '...',
    activity: new Date().toISOString(),
  },
  {
    key: '6',
    id: '6',
    fio: 'Сергеев Б.И.',
    email: 'brandonbator@mail.ru',
    role: 'Владелец',
    franchise: 'SuperFranchise',
    quantityProjects: 0,
    status: true,
    actions: '...',
    activity: new Date().toISOString(),
  },
  {
    key: '7',
    id: '7',
    fio: 'Линкольн Р.С.',
    email: 'linkoln@mail.ru',
    role: 'Владелец',
    franchise: 'SuperFranchise',
    quantityProjects: 12,
    status: true,
    actions: '...',
    activity: new Date().toISOString(),
  },
  {
    key: '8',
    id: '8',
    fio: 'Кальцоне Е.В.',
    email: 'calzoni23@mail.ru',
    role: 'Владелец',
    franchise: 'SuperFranchise',
    quantityProjects: 5,
    status: true,
    actions: '...',
    activity: new Date().toISOString(),
  },
  {
    key: '9',
    id: '9',
    fio: 'Саймон И.П.',
    email: 'simon1991@mail.ru',
    status: true,
    role: 'Владелец',
    franchise: 'SuperFranchise',
    quantityProjects: 2,
    actions: '...',
    activity: new Date().toISOString(),
  },
  {
    key: '10',
    id: '10',
    fio: 'Аминоф Е.В.',
    email: 'aminoff@mail.ru',
    role: 'Владелец',
    franchise: 'SuperFranchise',
    quantityProjects: 1,
    status: true,
    actions: '...',
    activity: new Date().toISOString(),
  },
];

export const franchiseData: TableFranchiseDataType[] = [
  {
    key: '1',
    id: '1',
    owner: 'Иванов И.И.',
    role: 'Владелец',
    franchise: 'SuperFranchise',
    eDate: '08.06.2022',
    quantityProjects: 4,
    quantityUsers: 2,
    status: true,
    actions: '...',
  },
  {
    key: '2',
    id: '2',
    owner: 'Константинопольский К.К.',
    role: 'Владелец',
    franchise: 'Somo Int',
    eDate: '29.11.2022',
    quantityProjects: 2,
    quantityUsers: 2,
    status: true,
    actions: '...',
  },
  {
    key: '3',
    id: '3',
    owner: 'Сергеева А.К.',
    role: 'Владелец',
    franchise: 'MarketShare',
    eDate: '08.06.2022',
    quantityProjects: 2,
    quantityUsers: 2,
    status: false,
    actions: '...',
  },
  {
    key: '4',
    id: '4',
    owner: 'Макарова О.В.',
    role: 'Владелец',
    franchise: 'Copmanyname',
    eDate: '29.03.2022',
    quantityProjects: 3,
    quantityUsers: 2,
    status: true,
    actions: '...',
  },
  {
    key: '5',
    id: '5',
    owner: '-',
    role: 'Владелец',
    franchise: '-',
    eDate: '29.01.2022',
    quantityProjects: 1,
    quantityUsers: 2,
    status: true,
    actions: '...',
  },
  {
    key: '6',
    id: '6',
    owner: 'Сергеев Б.И.',
    role: 'Владелец',
    franchise: 'YaCMS',
    eDate: '29.04.2021',
    quantityProjects: 0,
    quantityUsers: 2,
    status: true,
    actions: '...',
  },
  {
    key: '7',
    id: '7',
    owner: 'Линкольн Р.С.',
    role: 'Владелец',
    franchise: 'DellCMS',
    eDate: '29.08.2022',
    quantityProjects: 12,
    quantityUsers: 2,
    status: true,
    actions: '...',
  },
  {
    key: '8',
    id: '8',
    owner: 'Кальцоне Е.В.',
    role: 'Владелец',
    franchise: 'Soprano',
    eDate: '29.08.2021',
    quantityProjects: 5,
    quantityUsers: 2,
    status: true,
    actions: '...',
  },
  {
    key: '9',
    id: '9',
    owner: 'Саймон И.П.',
    status: true,
    role: 'Владелец',
    franchise: 'VaxMedia',
    eDate: '15.06.2021',
    quantityProjects: 2,
    quantityUsers: 2,
    actions: '...',
  },
  {
    key: '10',
    id: '10',
    owner: 'Аминоф Е.В.',
    eDate: '15.10.2022',
    role: 'Владелец',
    franchise: 'Copmany',
    quantityProjects: 1,
    quantityUsers: 2,
    status: true,
    actions: '...',
  },
];

export const projectData: TableProjectDataType[] = [
  {
    key: '1',
    id: '1',
    title: 'yaldex',
    domain: 'yaldex.ru',
    franchise: 'SuperFranchise',
    bDate: '08.06.2022',
    uDate: '08.06.2022',
    visitors: 686988,
    status: true,
    actions: '...',
    role: 'Владелец',
  },
  {
    key: '2',
    id: '2',
    title: 'moogle',
    domain: 'moogle.ru',
    franchise: 'SuperFranchise',
    bDate: '08.06.2022',
    uDate: '08.06.2022',
    visitors: 342235,
    status: true,
    actions: '...',
    role: 'Владелец',
  },
  {
    key: '3',
    id: '3',
    title: 'ample',
    domain: 'ample.ru',
    franchise: 'SuperFranchise',
    bDate: '08.06.2022',
    uDate: '08.06.2022',
    visitors: 423483,
    status: true,
    actions: '...',
    role: 'Владелец',
  },
  {
    key: '4',
    id: '4',
    title: 'kanopu',
    domain: 'kanopu.ru',
    franchise: 'SuperFranchise',
    bDate: '08.06.2022',
    uDate: '08.06.2022',
    visitors: 786766,
    status: true,
    actions: '...',
    role: 'Владелец',
  },
  {
    key: '5',
    id: '5',
    title: 'wikigame',
    domain: 'wikigame.ru',
    franchise: 'SuperFranchise',
    bDate: '08.06.2022',
    uDate: '08.06.2022',
    visitors: 234543,
    status: true,
    actions: '...',
    role: 'Владелец',
  },
  {
    key: '6',
    id: '6',
    title: 'n-video',
    domain: 'n-video.ru',
    franchise: 'SuperFranchise',
    bDate: '08.06.2022',
    uDate: '08.06.2022',
    visitors: 53264,
    status: true,
    actions: '...',
    role: 'Владелец',
  },
  {
    key: '7',
    id: '7',
    title: 'simondance-new',
    domain: 'simondance-new.ru',
    franchise: 'SuperFranchise',
    bDate: '08.06.2022',
    uDate: '08.06.2022',
    visitors: 65687,
    status: true,
    actions: '...',
    role: 'Владелец',
  },
  {
    key: '8',
    id: '8',
    title: 'vaxmedia',
    domain: 'vaxmedia.ru',
    franchise: 'SuperFranchise',
    bDate: '08.06.2022',
    uDate: '08.06.2022',
    visitors: 56574,
    status: true,
    actions: '...',
    role: 'Владелец',
  },
  {
    key: '9',
    id: '9',
    title: 'company-new',
    domain: 'company-new.ru',
    franchise: 'SuperFranchise',
    bDate: '08.06.2022',
    uDate: '08.06.2022',
    visitors: 23453,
    status: true,
    actions: '...',
    role: 'Владелец',
  },
  {
    key: '10',
    id: '10',
    title: 'test-test',
    domain: 'test-test.ru',
    franchise: 'SuperFranchise',
    bDate: '08.06.2022',
    uDate: '08.06.2022',
    visitors: 23452,
    status: true,
    actions: '...',
    role: 'Владелец',
  },
];

export const templatesData = [
  {
    _id: '1',
    identifier: 1,
    title: 'Основной',
    category: 'Интернет-магазин',
    quantityProjects: 4,
    updatedAt: '2022-08-09T11:57:38.351Z',
    price: 'Бесплатно',
    published: true,
    actions: '',
  },
  {
    _id: '2',
    identifier: 2,
    title: 'Красивый',
    category: 'Мода, красота',
    quantityProjects: 2,
    updatedAt: '2022-08-09T11:57:38.351Z',
    price: 'Бесплатно',
    published: true,
    actions: '',
  },
  {
    _id: '3',
    identifier: 3,
    title: 'Модный',
    category: 'Мода',
    quantityProjects: 2,
    updatedAt: '2022-08-09T11:57:38.351Z',
    price: 'Бесплатно',
    published: true,
    actions: '',
  },
  {
    _id: '4',
    identifier: 4,
    title: 'Современный',
    category: 'Мода, красота',
    quantityProjects: 5,
    updatedAt: '2022-08-09T11:57:38.351Z',
    price: 'Бесплатно',
    published: true,
    actions: '',
  },
  {
    _id: '5',
    identifier: 5,
    title: 'Десткий',
    category: 'Интернет-магазин, одежда',
    quantityProjects: 4,
    updatedAt: '2022-08-09T11:57:38.351Z',
    price: 'Бесплатно',
    published: false,
    actions: '',
  },
  {
    _id: '6',
    identifier: 6,
    title: 'Женский',
    category: 'Красота',
    quantityProjects: 3,
    updatedAt: '2022-08-09T11:57:38.351Z',
    price: 'Бесплатно',
    published: true,
    actions: '',
  },
  {
    _id: '7',
    identifier: 7,
    title: 'Посадочная',
    category: 'Интернет-магазин',
    quantityProjects: 4,
    updatedAt: '2022-08-09T11:57:38.351Z',
    price: 'Бесплатно',
    published: true,
    actions: '',
  },
  {
    _id: '8',
    identifier: 8,
    title: 'Основной',
    category: 'Интернет-магазин, книги',
    quantityProjects: 1,
    updatedAt: '2022-08-09T11:57:38.351Z',
    price: 'Бесплатно',
    published: false,
    actions: '',
  },
  {
    _id: '9',
    identifier: 9,
    title: 'Магазин',
    category: 'Интернет-магазин',
    quantityProjects: 4,
    updatedAt: '2022-08-09T11:57:38.351Z',
    price: 'Бесплатно',
    published: true,
    actions: '',
  },
  {
    _id: '10',
    identifier: 10,
    title: 'Основной',
    category: 'Интернет-магазин',
    quantityProjects: 4,
    updatedAt: '2022-08-09T11:57:38.351Z',
    price: 'Бесплатно',
    published: true,
    actions: '',
  },
];

export const templatesCategoriesMock = [
  {
    createdAt: '2022-08-10T10:59:36.120Z',
    deletedAt: null,
    priority: 0,
    quantityTemplate: 4,
    title: 'Интернет-магазин',
    updatedAt: '2022-08-10T10:59:36.120Z',
    _id: '62f38f986a5d4efa7c8b3d6a',
  },
  {
    createdAt: '2022-08-10T10:59:36.120Z',
    deletedAt: null,
    priority: 0,
    quantityTemplate: 15,
    title: 'Мода, Красота',
    updatedAt: '2022-08-10T10:59:36.120Z',
    _id: '62f38f986a5d4efa7c8b3d644354a',
  },
  {
    createdAt: '2022-08-10T10:59:36.120Z',
    deletedAt: null,
    priority: 0,
    quantityTemplate: 18,
    title: 'Мода',
    updatedAt: '2022-08-10T10:59:36.120Z',
    _id: '62f38f98dfd6a5d4efa7c8b3d644354a',
  },
  {
    createdAt: '2022-08-10T10:59:36.120Z',
    deletedAt: null,
    priority: 0,
    quantityTemplate: 2,
    title: 'Интернет-магазин, Одежда',
    updatedAt: '2022-08-10T10:59:36.120Z',
    _id: '62f38f98ddfdffd6a5d4efa7c8b3d644354a',
  },
];
export const categoriesTreeDataMock = [
  {
    key: '0',
    title: 'Все категории',
    children: [
      {
        key: '1',
        title: 'Детские товары',
      },
      {
        key: '2',
        title: 'Дом и дача',
      },
      {
        key: '3',
        title: 'Детские товары',
      },
      {
        key: '4',
        title: 'Досуг, развлечения, хобби',
      },
      {
        key: '5',
        title: 'Еда и продукты',
      },
      {
        key: '6',
        title: 'Здоровье и медицина',
      },
      {
        key: '7',
        title: 'Зоотовары',
      },
    ],
  },
];
