import {
  ProjectUser,
  Project,
  ProjectsData,
  ShortenedProject,
  ShortProject,
} from '@/@typespaces/interfaces/project.interface';

import { AddUserInProject, EditUserInProject, UserInProjectChangeRole } from '@/@typespaces/types/project.types';
import { Query } from '@/@typespaces/types/query.types';
import { UpdateFile } from '@/@typespaces/types/updateFranchisesFile.types';
import InstanceHttpClient from '../instance';

export default class TmapProjectsClient extends InstanceHttpClient {
  constructor() {
    super('tmap-projects');
  }

  async getAllProjectShort(): Promise<ShortenedProject[]> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: 'all-short',
      });
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async getProjects(query: Query): Promise<ProjectsData> {
    try {
      return await this.apiCall({
        method: 'GET',
        params: query,
      });
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async createProject(body: Partial<ShortProject>): Promise<ShortProject> {
    try {
      return await this.apiCall({
        method: 'POST',
        data: body,
      });
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async editProject(body: Partial<ShortProject>): Promise<ShortProject> {
    const id = body._id;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: id,
        data: body,
      });
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async updateProjectFile(payload: { data: UpdateFile; projectId: string }): Promise<ShortProject> {
    const { data, projectId } = payload;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `${projectId}/update-file`,
        data,
      });
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async removeProjectFile(payload: { data: UpdateFile; projectId: string }) {
    const { data, projectId } = payload;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `${projectId}/delete-file`,
        data,
      });
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async deleteProject(id: string) {
    try {
      await this.apiCall({
        method: 'DELETE',
        url: id,
      });
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async blockProject(id: string) {
    const url = `${id}/block`;
    try {
      await this.apiCall({
        method: 'PATCH',
        url,
      });
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async unblockProject(id: string) {
    const url = `${id}/unblock`;
    try {
      await this.apiCall({
        method: 'PATCH',
        url,
      });
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async getOneProject(id: string): Promise<Project> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: id,
      });
    } catch (error: any) {
      throw new Error(error);
    }
  }

  // Для таблицы пользователей

  async getUsers(data: { id: string; query: Query }): Promise<any> {
    const { id, query } = data;
    try {
      return await this.apiCall({
        method: 'GET',
        url: `/${id}/users`,
        params: {
          ...query,
        },
      });
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async addUsers(data: AddUserInProject): Promise<ProjectUser> {
    const { projectId, email, roleId } = data;
    try {
      return await this.apiCall({
        method: 'POST',
        url: `/${projectId}/users/add-user`,
        data: {
          email,
          roleId,
        },
      });
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async changeRole(data: UserInProjectChangeRole) {
    const { projectId, userId, roleId } = data;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `/${projectId}/users/${userId}/change-role`,
        data: { roleId },
      });
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async deleteUser(data: EditUserInProject) {
    const { projectId, userId } = data;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `/${projectId}/users/${userId}/remove`,
      });
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async blockUser(data: EditUserInProject) {
    const { projectId, userId } = data;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `/${projectId}/users/${userId}/block`,
      });
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async unBlockUser(data: EditUserInProject) {
    const { projectId, userId } = data;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `/${projectId}/users/${userId}/unblock`,
      });
    } catch (error: any) {
      throw new Error(error);
    }
  }
}
