// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum FaqMutationTypes {
  FETCH_FAQS = 'FETCH_FAQS',
  DELETE_FAQ_SOLUTIONS = 'DELETE_FAQ_SOLUTIONS',

  // Status
  FAQ_LOADING = 'FAQ_LOADING',
  FAQ_SUCCEEDED = 'FAQ_SUCCEEDED',
  FAQ_ERROR = 'FAQ_ERROR',

  FAQ_TABLE_LOADING = 'FAQ_TABLE_LOADING',
  FAQ_TABLE_SUCCEEDED = 'FAQ_TABLE_SUCCEEDED',
  FAQ_TABLE_ERROR = 'FAQ_TABLE_ERROR',
}
