import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { MainPage } from '@/@typespaces/interfaces/mainPageInterface';
import Status from '@/@typespaces/enum/status.enum';
import { State } from './state';

export type Getters = {
  getMainPageData(state: State): MainPage | null;
  getMainPageLoading(state: State): boolean;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getMainPageData: (state) => state.data,
  getMainPageLoading: (state) => state.status === Status.LOADING,
};
