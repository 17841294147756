import {
  AppstoreOutlined,
  BookOutlined,
  ContactsOutlined,
  FolderOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  StarOutlined,
  TeamOutlined,
  MonitorOutlined,
  HomeOutlined,
} from '@ant-design/icons-vue';

// eslint-disable-next-line import/prefer-default-export
export const defaultRouteNames = [
  {
    name: 'Admins',
    title: 'Администраторы',
    icon: StarOutlined,
    doIt: 'read',
    subject: 'FranchiseUsers',
  },
  {
    name: 'Users',
    title: 'Пользователи',
    icon: TeamOutlined,
    doIt: 'read',
    subject: 'Users',
  },
  {
    name: 'Franchise',
    title: 'Франшиза',
    icon: ShopOutlined,
    doIt: 'manage',
    subject: 'Projects',
  },
  {
    name: 'Franchises',
    title: 'Франшизы',
    icon: ShopOutlined,
    doIt: 'manage',
    subject: 'Franchise',
  },
  {
    name: 'Projects',
    title: 'Проекты',
    icon: FolderOutlined,
    doIt: 'manage',
    subject: 'Projects',
  },
  {
    name: 'Templates',
    title: 'Шаблоны',
    icon: AppstoreOutlined,
    doIt: 'read',
    subject: 'Templates',
  },
];

export const publicPartRouteNames = [
  {
    name: 'KnowledgeBase',
    title: 'База знаний',
    icon: BookOutlined,
    divider: true,
    doIt: 'read',
    subject: 'Knowledge_Base',
  },
  {
    name: 'SettingSite',
    title: 'Настройка сайта',
    icon: SettingOutlined,
    doIt: 'read',
    subject: 'Site_Settings',
  },
  {
    name: 'MainPage',
    title: 'Главная страница',
    icon: HomeOutlined,
    doIt: 'read',
    subject: 'Site_Settings',
  },
  {
    name: 'Service',
    title: 'Услуги',
    icon: ShoppingCartOutlined,
    doIt: 'read',
    subject: 'Services',
  },
  {
    name: 'Faq',
    title: 'Поддержка',
    icon: QuestionCircleOutlined,
    doIt: 'read',
    subject: 'Faq',
  },
  {
    name: 'Reviews',
    title: 'Отзывы',
    icon: StarOutlined,
    doIt: 'read',
    subject: 'Reviews',
  },
  {
    name: 'Contacts',
    title: 'Контакты',
    icon: ContactsOutlined,
    doIt: 'read',
    subject: 'Contacts',
  },
  {
    name: 'Seo',
    title: 'SEO',
    icon: MonitorOutlined,
    doIt: 'read',
    subject: 'Seo',
  },
];
