import { RestorePassword } from '@/@typespaces/types/restorePassword.types';
import InstanceHttpClient from './instance';

export default class AuthClient extends InstanceHttpClient {
  constructor() {
    super('auth');
  }

  async signIn(email: string, password: string): Promise<{ access_token: string }> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: 'map-tmap/login',
        data: {
          email,
          password,
        },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async recoveryPassword(data: string) {
    try {
      return await this.apiCall({
        method: 'POST',
        url: 'map-tmap/recovery-password',
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async restorePassword(data: RestorePassword) {
    try {
      return await this.apiCall({
        method: 'POST',
        url: 'map-tmap/restore-password',
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }
}
