import { Query } from '@/@typespaces/types/query.types';
import { Template, TemplatesData } from '@/@typespaces/interfaces/templates.interface';
import { DuplicateTemplateData } from '@/@typespaces/types/template.type';
import InstanceHttpClient from '../instance';

export default class TmapTemplatesClient extends InstanceHttpClient {
  constructor() {
    super('tmap-templates');
  }

  async getTemplates(query: Query): Promise<TemplatesData> {
    try {
      return await this.apiCall({
        method: 'GET',
        params: query,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async getOneTemplate(id: string): Promise<Template> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: id,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async createTemplate(body: any): Promise<Template> {
    try {
      return await this.apiCall({
        method: 'POST',
        data: body,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async editTemplate(body: Template): Promise<Template> {
    const id = body._id;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: id,
        data: body,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async deleteTemplate(id: string) {
    try {
      await this.apiCall({
        method: 'DELETE',
        url: id,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async duplicateTemplate(data: DuplicateTemplateData): Promise<Template> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: 'duplicate',
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async publishTemplate(id: string) {
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `${id}/publish`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async unPublishTemplate(id: string) {
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `${id}/unpublish`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }
}
