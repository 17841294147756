import { MutationTree } from 'vuex';
import { TemplatesMutationTypes } from '@/store/modules/templates/mutation-types';
import Status from '@/@typespaces/enum/status.enum';
import { ShortTemplate, Template, TemplatesData } from '@/@typespaces/interfaces/templates.interface';
import { State } from './state';

export type Mutations<S = State> = {
  [TemplatesMutationTypes.FETCH_TEMPLATES](state: S, payload: TemplatesData): void;
  [TemplatesMutationTypes.FETCH_ONE_TEMPLATE](state: S, payload: Template): void;
  [TemplatesMutationTypes.CLEAR_ONE_TEMPLATE](state: S): void;
  [TemplatesMutationTypes.CREATE_TEMPLATE](state: S, payload: Template): void;
  [TemplatesMutationTypes.PUBLISH_TEMPLATE](state: S, payload: string): void;
  [TemplatesMutationTypes.UN_PUBLISH_TEMPLATE](state: S, payload: string): void;
  [TemplatesMutationTypes.DELETE_TEMPLATE](state: S, payload: string): void;
  [TemplatesMutationTypes.EDIT_TEMPLATE](state: S, payload: Template): void;
  [TemplatesMutationTypes.DUPLICATE_TEMPLATE](state: S, payload: ShortTemplate): void;

  // Status
  [TemplatesMutationTypes.TEMPLATES_ERROR](state: S, payload: string): void;
  [TemplatesMutationTypes.TEMPLATES_LOADING](state: S): void;
  [TemplatesMutationTypes.TEMPLATES_SUCCEEDED](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [TemplatesMutationTypes.FETCH_TEMPLATES](state: State, templateData: TemplatesData) {
    state.data = templateData.data.templates;
    state.pagination.total = templateData.total;
    state.pagination.page = templateData.page;
    state.pagination.pageSize = templateData.pageSize;
  },
  [TemplatesMutationTypes.FETCH_ONE_TEMPLATE](state: State, data: Template) {
    state.oneTemplate = data;
  },
  [TemplatesMutationTypes.CREATE_TEMPLATE](state: State, data: any) {
    state.data.push(data);
    state.pagination.total += 1;
    state.oneTemplate = data;
  },

  [TemplatesMutationTypes.PUBLISH_TEMPLATE](state: State, payload: string) {
    state.data = state.data.map((item) => {
      if (item._id === payload) {
        return {
          ...item,
          available_in_dashboard: true,
        };
      }
      return item;
    });
  },

  [TemplatesMutationTypes.UN_PUBLISH_TEMPLATE](state: State, payload: string) {
    state.data = state.data.map((item) => {
      if (item._id === payload) {
        return {
          ...item,
          available_in_dashboard: false,
        };
      }
      return item;
    });
  },

  [TemplatesMutationTypes.EDIT_TEMPLATE](state: State, template: any) {
    state.data = state.data.map((item) => {
      if (item._id === template._id) {
        return template;
      }
      return item;
    });
  },
  [TemplatesMutationTypes.DELETE_TEMPLATE](state: State, id: string) {
    const removeIndex = state.data.map((item) => item._id).indexOf(id);
    state.data.splice(removeIndex, 1);
    state.pagination.total -= 1;
  },
  [TemplatesMutationTypes.DUPLICATE_TEMPLATE](state: State, payload: ShortTemplate) {
    state.data.push(payload);
  },
  [TemplatesMutationTypes.CLEAR_ONE_TEMPLATE](state: State) {
    state.oneTemplate = null;
  },

  // Status
  [TemplatesMutationTypes.TEMPLATES_LOADING](state) {
    state.status = Status.LOADING;
  },
  [TemplatesMutationTypes.TEMPLATES_SUCCEEDED](state) {
    state.status = Status.SUCCEEDED;
  },
  [TemplatesMutationTypes.TEMPLATES_ERROR](state: State, error: string) {
    state.error = error;
    state.status = Status.IDLE;
  },
};
