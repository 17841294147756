import { mask } from 'maska';
import { MOBILE_MASK, CITY_PHONE_MASK } from '@/@constants';

// eslint-disable-next-line import/prefer-default-export
export const convertValueByMask = (value?: string): string => {
  if (value) {
    if (value.length > 7) {
      return mask(value, MOBILE_MASK, undefined);
    }
    return mask(value, CITY_PHONE_MASK, undefined);
  }
  return '';
};
