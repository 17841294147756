// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum ReviewsActionTypes {
  FETCH_REVIEWS = 'FETCH_REVIEWS',
  ADD_REVIEW_CONTENT = 'ADD_REVIEW_CONTENT',
  EDIT_REVIEWS = 'EDIT_REVIEWS',
  DELETE_REVIEW_CONTENT = 'DELETE_REVIEW_CONTENT',
  EDIT_REVIEW_CONTENT = 'EDIT_REVIEW_CONTENT',
  REVIEW_EDIT_PRIORITY = 'REVIEW_EDIT_PRIORITY',
  UPDATE_REVIEWS_FILE = 'UPDATE_REVIEWS_FILE',
  REMOVE_REVIEWS_FILE = 'REMOVE_REVIEWS_FILE',
}
