import { SettingsPayload } from '@/@typespaces/interfaces/form/settings-form.interface';
import { Settings } from '@/@typespaces/interfaces/settings.interface';
import InstanceHttpClient from './instance';

export default class SettingsClient extends InstanceHttpClient {
  constructor() {
    super('public-page/settings');
  }

  async getSettings(): Promise<Settings> {
    try {
      return await this.apiCall({
        method: 'GET',
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async editSettings(data: SettingsPayload) {
    try {
      return await this.apiCall({
        method: 'PATCH',
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async updateFile(data: { type: string; file_id: string }) {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: 'update-file',
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async removeFile(data: { type: string; file_id: string }) {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: 'delete-file',
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }
}
