import { MutationTree } from 'vuex';
import { IAuth } from '@/@typespaces/interfaces/auth.interfaces';
import Status from '@/@typespaces/enum/status.enum';
import { State } from './state';
import { AuthMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [AuthMutationTypes.SIGN_IN](state: S, payload: IAuth): void;
  [AuthMutationTypes.LOG_OUT](state: S): void;
  [AuthMutationTypes.AUTH_LOADING](state: S): void;
  [AuthMutationTypes.AUTH_SUCCEEDED](state: S): void;
  [AuthMutationTypes.AUTH_ERROR](state: S, payload: string): void;
  [AuthMutationTypes.REFRESH_TOKEN](state: S, payload: string): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [AuthMutationTypes.SIGN_IN](state: State, payload) {
    state.accessToken = payload.access_token;
    state.email = payload.email;
  },
  [AuthMutationTypes.LOG_OUT](state: State) {
    state.accessToken = null;
  },
  [AuthMutationTypes.REFRESH_TOKEN](state: State, payload: string) {
    state.accessToken = payload;
  },

  // Status

  [AuthMutationTypes.AUTH_LOADING](state: State) {
    state.status = Status.LOADING;
  },
  [AuthMutationTypes.AUTH_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
  },
  [AuthMutationTypes.AUTH_ERROR](state: State, payload: string) {
    state.status = Status.FAILED;
    state.error = payload;
  },
};
