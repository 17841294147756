import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { Contacts } from '@/@typespaces/interfaces/contacts.interface';
import Status from '@/@typespaces/enum/status.enum';
import { State } from './state';

export type Getters = {
  getContacts(state: State): Contacts | null;
  getContactsLoading(state: State): boolean;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getContacts: (state) => state.data,
  getContactsLoading: (state) => state.status === Status.LOADING,
};
