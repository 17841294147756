// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum TemplatesCategoriesMutationTypes {
  FETCH_TEMPLATES_CATEGORIES = 'FETCH_TEMPLATES_CATEGORIES',
  CREATE_TEMPLATES_CATEGORY = 'CREATE_TEMPLATES_CATEGORY',
  EDIT_TEMPLATES_CATEGORY = 'EDIT_TEMPLATES_CATEGORY',
  REMOVE_TEMPLATES_CATEGORY = 'REMOVE_TEMPLATES_CATEGORY',
  // Status
  TEMPLATES_CATEGORY_LOADING = 'TEMPLATES_CATEGORY_LOADING',
  TEMPLATES_CATEGORY_SUCCEEDED = 'TEMPLATES_CATEGORY_SUCCEEDED',
  TEMPLATES_CATEGORY_ERROR = 'TEMPLATES_CATEGORY_ERROR',
}
