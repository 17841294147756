<template>
  <div class="container">
    <span class="title">{{ title }}</span>
    <a v-if="url" :href="url" class="text" target="_blank">{{ text }}</a>
    <span v-else class="text">{{ text }}</span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HeaderInfoItem',
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    link: {
      type: Boolean,
    },
    url: {
      type: String,
    },
  },
});
</script>

<style scoped>
.container {
  margin-right: 48px;
}

.title,
.text {
  font: var(--bodyRegular);
}

.title {
  margin: 0 12px 0 0;
}
</style>
