// eslint-disable-next-line import/prefer-default-export
import { message } from 'ant-design-vue';
import BlotFormatter from 'quill-blot-formatter';
// @ts-ignore
import ImageUploader from 'quill-image-uploader';
import FileClient from '@/api/file.api';

export const EDITOR_OPTIONS = {
  modules: {
    toolbar: [
      ['bold', 'italic'],
      [{ color: [] }],
      [{ align: '' }],
      [{ align: 'center' }],
      [{ align: 'right' }],
      ['link', 'image', 'video'],
      ['clean'],
    ],
  },
};
export const FAQ_EDITOR_OPTIONS = {
  modules: {
    toolbar: [['bold', 'italic'], ['link']],
  },
};

const api = new FileClient();

export const EDITOR_MODULES = [
  {
    name: 'imageUploader',
    module: ImageUploader,
    options: {
      upload: (file: File) => {
        const isLt2M = file.size / 1024 / 1024 < 5;

        if (!isLt2M) {
          message.error('Превышен максимально допустимый размер файла');
          return Promise.reject();
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('filename', file.name);
        return api
          .addFile(formData)
          .then((data: any) => data.url)
          .catch(() => {
            message.error('Не удалось загрузить изображение');
          });
      },
    },
  },
  {
    name: 'blotFormatter',
    module: BlotFormatter,
    options: {},
  },
];

export const EDITOR_TOOLBAR = [
  ['bold', 'italic'],
  [{ color: [] }],
  [{ align: '' }],
  [{ align: 'center' }],
  [{ align: 'right' }],
  ['link', 'image', 'video'],
  ['clean'],
];
