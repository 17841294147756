// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum ServicesActionTypes {
  FETCH_SERVICES = 'FETCH_SERVICES',
  CREATE_SERVICE_CONTENT = 'CREATE_SERVICE_CONTENT',
  EDIT_SERVICES = 'EDIT_SERVICES',
  DELETE_SERVICE_CONTENT = 'DELETE_SERVICE_CONTENT',
  EDIT_SERVICE_CONTENT = 'EDIT_SERVICE_CONTENT',
  EDIT_PRIORITY = 'EDIT_PRIORITY',
  UPDATE_SERVICES_FILE = 'UPDATE_SERVICES_FILE',
  REMOVE_SERVICES_FILE = 'REMOVE_SERVICES_FILE',
}
