import { ReviewsData } from '@/@typespaces/interfaces/review.interface';
import { ReviewContentPriority, UpdateReviewContent } from '@/@typespaces/interfaces/form/reviews-form.interface';
import InstanceHttpClient from './instance';

export default class ReviewsClient extends InstanceHttpClient {
  constructor() {
    super('public-page/reviews');
  }

  async getReviews(): Promise<ReviewsData> {
    try {
      return await this.apiCall({
        method: 'GET',
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async editReviews(section_name: string) {
    try {
      await this.apiCall({
        method: 'PATCH',
        data: { section_name },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async addReviewContent(): Promise<{ review_content_id: string }> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: 'review/add',
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async deleteReviewContent(id: string) {
    try {
      await this.apiCall({
        method: 'DELETE',
        url: `review/${id}`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async editReviewContent(payload: { id: string; data: UpdateReviewContent }) {
    const { id, data } = payload;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `review/${id}`,
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async editPriority(data: { reviews: ReviewContentPriority[] }) {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: 'review/dnd',
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async updateReviewFile(data: { content_id: string; file_id: string }) {
    const { content_id, file_id } = data;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `review/${content_id}/update-file`,
        data: { file_id },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async removeReviewFile(data: { content_id: string; file_id: string }) {
    const { content_id, file_id } = data;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `review/${content_id}/delete-file`,
        data: { file_id },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }
}
