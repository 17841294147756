import { ShortProject, Project, ShortenedProject, ProjectUser } from '@/@typespaces/interfaces/project.interface';
import Status from '@/@typespaces/enum/status.enum';
import { IRole } from '@/@typespaces/interfaces/role.interfaces';

export interface State {
  projects: ShortProject[];
  projectsIds: string[];
  oneProject: Project | null;
  projectUsersData: {
    users: ProjectUser[];
    usersIds: string[];
    roles: IRole[];
    pagination: {
      page: number;
      pageSize: number;
      total: number;
    };
  };
  projectId: string | null;
  status: Status;
  error: null | string;
  pagination: {
    page: number;
    pageSize: number;
    total: number;
  };
  shortProjects: ShortenedProject[];
  roles: IRole[];
}

export const state: State = {
  projects: [],
  roles: [],
  projectsIds: [],
  oneProject: null,
  projectId: null,
  status: Status.IDLE,
  error: null,
  pagination: {
    page: 0,
    pageSize: 0,
    total: 0,
  },
  projectUsersData: {
    users: [],
    usersIds: [],
    roles: [],
    pagination: {
      page: 0,
      pageSize: 0,
      total: 0,
    },
  },
  shortProjects: [],
};
