import { ADMINS_GROUP } from '@/@constants';
import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store';
import TemplatesCategoriesClient from '@/api/templates-categories.api';
import TmapTemplatesCategoriesClient from '@/api/tmap-templates-categories.api';
import { TemplatesCategoriesActionTypes } from '@/store/modules/templatesCategories/action-types';
import { CreateTemplateCategory, TemplateCategory } from '@/@typespaces/interfaces/templates.interface';
import { TemplatesCategoriesMutationTypes } from '@/store/modules/templatesCategories/mutation-types';
import { Query } from '@/@typespaces/types/query.types';
import { State } from './state';
import { Mutations } from './mutations';

const mapClient = new TemplatesCategoriesClient();
const tmapClient = new TmapTemplatesCategoriesClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [TemplatesCategoriesActionTypes.FETCH_TEMPLATES_CATEGORIES]({ commit }: AugmentedActionContext, query: Query): void;

  [TemplatesCategoriesActionTypes.EDIT_TEMPLATES_CATEGORY](
    { commit }: AugmentedActionContext,
    payload: TemplateCategory
  ): void;

  [TemplatesCategoriesActionTypes.CREATE_TEMPLATES_CATEGORY](
    { commit }: AugmentedActionContext,
    payload: CreateTemplateCategory
  ): void;

  [TemplatesCategoriesActionTypes.REMOVE_TEMPLATES_CATEGORY]({ commit }: AugmentedActionContext, payload: string): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [TemplatesCategoriesActionTypes.FETCH_TEMPLATES_CATEGORIES]({ commit, getters }, query: Query) {
    commit(TemplatesCategoriesMutationTypes.TEMPLATES_CATEGORY_LOADING);
    try {
      let data;
      const isGroupAdmin = getters.getGroupName;
      if (isGroupAdmin === ADMINS_GROUP) {
        data = await mapClient.getTemplatesCategories(query);
      } else {
        data = await tmapClient.getTemplatesCategories(query);
      }
      commit(TemplatesCategoriesMutationTypes.TEMPLATES_CATEGORY_SUCCEEDED);
      commit(TemplatesCategoriesMutationTypes.FETCH_TEMPLATES_CATEGORIES, data);
    } catch (error: any) {
      commit(TemplatesCategoriesMutationTypes.TEMPLATES_CATEGORY_ERROR, error);
      throw new Error(error);
    }
  },

  async [TemplatesCategoriesActionTypes.CREATE_TEMPLATES_CATEGORY](
    { commit, getters }: AugmentedActionContext,
    payload: CreateTemplateCategory
  ) {
    commit(TemplatesCategoriesMutationTypes.TEMPLATES_CATEGORY_LOADING);
    try {
      let data;
      const isGroupAdmin = getters.getGroupName;
      if (isGroupAdmin === ADMINS_GROUP) {
        data = await mapClient.createTemplateCategory(payload);
      } else {
        data = await tmapClient.createTemplateCategory(payload);
      }
      commit(TemplatesCategoriesMutationTypes.TEMPLATES_CATEGORY_SUCCEEDED);
      commit(TemplatesCategoriesMutationTypes.CREATE_TEMPLATES_CATEGORY, data);
    } catch (error: any) {
      commit(TemplatesCategoriesMutationTypes.TEMPLATES_CATEGORY_ERROR, error);
      throw new Error(error);
    }
  },
  async [TemplatesCategoriesActionTypes.EDIT_TEMPLATES_CATEGORY](
    { commit, getters }: AugmentedActionContext,
    payload: TemplateCategory
  ) {
    commit(TemplatesCategoriesMutationTypes.TEMPLATES_CATEGORY_LOADING);
    try {
      const isGroupAdmin = getters.getGroupName;
      if (isGroupAdmin === ADMINS_GROUP) {
        await mapClient.editTemplateCategory(payload);
      } else {
        await tmapClient.editTemplateCategory(payload);
      }
      commit(TemplatesCategoriesMutationTypes.TEMPLATES_CATEGORY_SUCCEEDED);
      commit(TemplatesCategoriesMutationTypes.EDIT_TEMPLATES_CATEGORY, payload);
    } catch (error: any) {
      commit(TemplatesCategoriesMutationTypes.TEMPLATES_CATEGORY_ERROR, error);
      throw new Error(error.message);
    }
  },

  async [TemplatesCategoriesActionTypes.REMOVE_TEMPLATES_CATEGORY](
    { commit, getters }: AugmentedActionContext,
    id: string
  ) {
    commit(TemplatesCategoriesMutationTypes.TEMPLATES_CATEGORY_LOADING);
    try {
      const isGroupAdmin = getters.getGroupName;
      if (isGroupAdmin === ADMINS_GROUP) {
        await mapClient.deleteTemplateCategory(id);
      } else {
        await tmapClient.deleteTemplateCategory(id);
      }
      commit(TemplatesCategoriesMutationTypes.TEMPLATES_CATEGORY_SUCCEEDED);
      commit(TemplatesCategoriesMutationTypes.REMOVE_TEMPLATES_CATEGORY, id);
    } catch (error: any) {
      commit(TemplatesCategoriesMutationTypes.TEMPLATES_CATEGORY_ERROR, error);
      throw new Error(error);
    }
  },
};
