// Core
import { GetterTree } from 'vuex';

// Root store
import { RootState } from '@/store';

// Interfaces
import { Article, Category, KnowledgeBase } from '@/@typespaces/interfaces/knowledgeBase.interface';

// Enums
import Status from '@/@typespaces/enum/status.enum';

// Types
import { PaginationData } from '@/@typespaces/types/paginationData';
import { KnowledgeBaseState } from './state';

export type Getters = {
  getKnowledgeArticles(state: KnowledgeBaseState): Article[];
  getKnowledgeArticlesByCategory(state: KnowledgeBaseState): (id: string) => Article[];
  getKnowledge(state: KnowledgeBaseState): KnowledgeBase | null;
  getKnowledgeStatus(state: KnowledgeBaseState): Status;
  getKnowledgeCategoriesLoading(state: KnowledgeBaseState): boolean;
  getKnowledgeArticlesLoading(state: KnowledgeBaseState): boolean;
  getKnowledgeCategories(state: KnowledgeBaseState): Category[];
  getKnowledgePaginationInfo(state: KnowledgeBaseState): PaginationData;
  getKnowledgeArticleById: (state: KnowledgeBaseState) => (id: string) => Article | undefined;
  getCurrentCategory: (state: KnowledgeBaseState) => Category | null;
};

export const getters: GetterTree<KnowledgeBaseState, RootState> & Getters = {
  getKnowledgeArticles: (state) => state.articles,
  getKnowledgeArticlesByCategory: (state) => (id: string) =>
    state.articles.filter((a) => a.category_id === id).sort((a, b) => (a.order > b.order ? 1 : -1)),
  getKnowledge: (state) => state.data,
  getKnowledgeStatus: (state) => state.status,
  getKnowledgeCategories: (state) => state.categories,
  getKnowledgePaginationInfo: (state) => ({
    total: state.total,
    page: state.page,
    pageSize: state.pageSize,
  }),
  getKnowledgeArticleById: (state) => (id: string) => state.articles.find((item) => item._id === id),
  getKnowledgeCategoriesLoading: (state) => state.categoriesStatus === Status.LOADING,
  getKnowledgeArticlesLoading: (state) => state.articlesStatus === Status.LOADING,
  getCurrentCategory: (state) => state.currentCategory,
};
