import { IAdmin } from '@/@typespaces/interfaces/admin.interface';
import Status from '@/@typespaces/enum/status.enum';
import { IRole } from '@/@typespaces/interfaces/role.interfaces';

export interface State {
  data: IAdmin[];
  usersIds: string[];
  roles: IRole[];
  status: Status;
  error: null | string;
  pagination: {
    page: number;
    pageSize: number;
    total: number;
  };
}

export const state: State = {
  data: [],
  usersIds: [],
  roles: [],
  status: Status.IDLE,
  error: null,
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
  },
};
