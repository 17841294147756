import { MutationTree } from 'vuex';
import Status from '@/@typespaces/enum/status.enum';
import { MainPage } from '@/@typespaces/interfaces/mainPageInterface';
import { MainPageMutationTypes } from './mutation-types';
import { State } from './state';

export type Mutations<S = State> = {
  [MainPageMutationTypes.FETCH_MAIN_PAGE](state: S, payload: MainPage): void;

  // Status
  [MainPageMutationTypes.MAIN_PAGE_LOADING](state: S): void;
  [MainPageMutationTypes.MAIN_PAGE_SUCCEEDED](state: S): void;
  [MainPageMutationTypes.MAIN_PAGE_ERROR](state: S): void;
};
export const mutations: MutationTree<State> & Mutations = {
  [MainPageMutationTypes.FETCH_MAIN_PAGE](state: State, payload: MainPage) {
    state.data = payload;
  },
  [MainPageMutationTypes.MAIN_PAGE_LOADING](state: State) {
    state.status = Status.LOADING;
  },
  [MainPageMutationTypes.MAIN_PAGE_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
  },
  [MainPageMutationTypes.MAIN_PAGE_ERROR](state: State) {
    state.status = Status.FAILED;
  },
};
