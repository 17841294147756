// Core
import { ActionContext, ActionTree } from 'vuex';

// Root store
import { RootState } from '@/store';

// Client API
import KnowledgeBaseClient from '@/api/knowledgeBase.api';

// Interfaces
import { Category } from '@/@typespaces/interfaces/knowledgeBase.interface';

// Mutations types
import { KnowledgeMutationTypes } from '@/store/modules/knowledge/mutation-types';
import { Query } from '@/@typespaces/types/query.types';
import { KnowledgeContentData } from '@/@typespaces/interfaces/form/knowledge-form.interface';

// Mutations
import { Mutations } from './mutations';

// Actions Types
import { KnowledgeActionTypes } from './action-types';

// State
import { KnowledgeBaseState } from './state';

const client = new KnowledgeBaseClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<KnowledgeBaseState, RootState>, 'commit'>;

export interface Actions {
  [KnowledgeActionTypes.FETCH_KNOWLEDGE_DATA]({ commit }: AugmentedActionContext, query: Query): void;
  [KnowledgeActionTypes.EDIT_KNOWLEDGE_SETTING]({ commit }: AugmentedActionContext, payload: string): void;

  // Articles
  [KnowledgeActionTypes.CREATE_ARTICLE]({ commit }: AugmentedActionContext): void;
  [KnowledgeActionTypes.EDIT_ARTICLE](
    { commit }: AugmentedActionContext,
    payload: { id: string; data: KnowledgeContentData }
  ): void;
  [KnowledgeActionTypes.REMOVE_ARTICLE]({ commit }: AugmentedActionContext, payload: string): void;
  [KnowledgeActionTypes.PUBLISH_ARTICLE]({ commit }: AugmentedActionContext, payload: string): void;
  [KnowledgeActionTypes.UN_PUBLISH_ARTICLE]({ commit }: AugmentedActionContext, payload: string): void;
  [KnowledgeActionTypes.DELETE_FILE_FROM_KB_ARTICLES](
    { commit }: AugmentedActionContext,
    payload: {
      fileId: string;
      type: string;
      kbArticleId: string;
    }
  ): void;
  [KnowledgeActionTypes.DND_KB_ARTICLES](
    { commit }: AugmentedActionContext,
    payload: { articleId: string; order: number }[]
  ): void;

  // Category
  [KnowledgeActionTypes.CREATE_KNOWLEDGE_CATEGORY]({ commit }: AugmentedActionContext, payload: string): void;
  [KnowledgeActionTypes.EDIT_KNOWLEDGE_CATEGORY_PRIORITY](
    { commit }: AugmentedActionContext,
    payload: { category_id: string; order: string }[]
  ): void;
  [KnowledgeActionTypes.EDIT_KNOWLEDGE_CATEGORY](
    { commit }: AugmentedActionContext,
    payload: { title: string; id: string }
  ): void;
  [KnowledgeActionTypes.REMOVE_KNOWLEDGE_CATEGORY]({ commit }: AugmentedActionContext, id: string): void;
  [KnowledgeActionTypes.SET_CURRENT_CATEGORY]({ commit }: AugmentedActionContext, element: Category | null): void;
}

export const actions: ActionTree<KnowledgeBaseState, RootState> & Actions = {
  async [KnowledgeActionTypes.FETCH_KNOWLEDGE_DATA]({ commit }, query: Query) {
    commit(KnowledgeMutationTypes.KNOWLEDGE_DATA_LOADING);
    try {
      const data = await client.getData(query);
      commit(KnowledgeMutationTypes.FETCH_KNOWLEDGE_DATA, data);
      commit(KnowledgeMutationTypes.KNOWLEDGE_DATA_SUCCEEDED);
    } catch (error: any) {
      commit(KnowledgeMutationTypes.KNOWLEDGE_DATA_ERROR, error);
      throw new Error(error);
    }
  },
  async [KnowledgeActionTypes.EDIT_KNOWLEDGE_SETTING]({ commit }, payload: string) {
    try {
      await client.editSettings(payload);
    } catch (error: any) {
      commit(KnowledgeMutationTypes.KNOWLEDGE_DATA_ERROR, error);
      throw new Error(error);
    }
  },

  async [KnowledgeActionTypes.CREATE_ARTICLE]({ commit }) {
    try {
      return await client.createArticle();
    } catch (error: any) {
      commit(KnowledgeMutationTypes.KNOWLEDGE_DATA_ERROR, error);
      throw new Error(error);
    }
  },

  async [KnowledgeActionTypes.EDIT_ARTICLE]({ commit }, payload: { id: string; data: KnowledgeContentData }) {
    try {
      await client.editArticle(payload);
    } catch (error: any) {
      commit(KnowledgeMutationTypes.KNOWLEDGE_DATA_ERROR, error);
      throw new Error(error);
    }
  },

  async [KnowledgeActionTypes.REMOVE_ARTICLE]({ commit }, payload: string) {
    commit(KnowledgeMutationTypes.KNOWLEDGE_DATA_LOADING);
    try {
      await client.removeArticle(payload);
      commit(KnowledgeMutationTypes.REMOVE_ARTICLE, payload);
      commit(KnowledgeMutationTypes.KNOWLEDGE_DATA_SUCCEEDED);
    } catch (error: any) {
      commit(KnowledgeMutationTypes.KNOWLEDGE_DATA_ERROR, error);
      throw new Error(error);
    }
  },

  async [KnowledgeActionTypes.PUBLISH_ARTICLE]({ commit }, payload: string) {
    commit(KnowledgeMutationTypes.KNOWLEDGE_DATA_LOADING);
    try {
      await client.publishArticle(payload);
      commit(KnowledgeMutationTypes.PUBLISH_ARTICLE, payload);
      commit(KnowledgeMutationTypes.KNOWLEDGE_DATA_SUCCEEDED);
    } catch (error: any) {
      commit(KnowledgeMutationTypes.KNOWLEDGE_DATA_ERROR);
      throw new Error(error);
    }
  },
  async [KnowledgeActionTypes.UN_PUBLISH_ARTICLE]({ commit }, payload: string) {
    commit(KnowledgeMutationTypes.KNOWLEDGE_DATA_LOADING);
    try {
      await client.unPublishArticle(payload);
      commit(KnowledgeMutationTypes.UN_PUBLISH_ARTICLE, payload);
      commit(KnowledgeMutationTypes.KNOWLEDGE_DATA_SUCCEEDED);
    } catch (error: any) {
      commit(KnowledgeMutationTypes.KNOWLEDGE_DATA_ERROR);
      throw new Error(error);
    }
  },
  async [KnowledgeActionTypes.DELETE_FILE_FROM_KB_ARTICLES](
    { commit },
    payload: {
      fileId: string;
      type: string;
      kbArticleId: string;
    }
  ) {
    commit(KnowledgeMutationTypes.KNOWLEDGE_DATA_LOADING);
    try {
      await client.deleteFileFromArticle(payload);
    } catch (error: any) {
      commit(KnowledgeMutationTypes.KNOWLEDGE_DATA_ERROR);
      throw new Error(error);
    }
  },
  async [KnowledgeActionTypes.DND_KB_ARTICLES]({ commit }, payload: { articleId: string; order: number }[]) {
    commit(KnowledgeMutationTypes.KNOWLEDGE_ARTICLES_LOADING);
    try {
      await client.dndArticles(payload);
      commit(KnowledgeMutationTypes.DND_KB_ARTICLES, payload);
    } catch (error: any) {
      throw new Error(error);
    } finally {
      commit(KnowledgeMutationTypes.KNOWLEDGE_ARTICLES_SUCCEEDED);
    }
  },

  // Category
  async [KnowledgeActionTypes.CREATE_KNOWLEDGE_CATEGORY]({ commit }, payload: string) {
    try {
      const response = await client.addCategory(payload);
      commit(KnowledgeMutationTypes.CREATE_KNOWLEDGE_CATEGORY, response);
    } catch (error: any) {
      commit(KnowledgeMutationTypes.KNOWLEDGE_DATA_ERROR);
      throw new Error(error);
    }
  },

  async [KnowledgeActionTypes.EDIT_KNOWLEDGE_CATEGORY_PRIORITY](
    { commit },
    payload: { category_id: string; order: string }[]
  ) {
    commit(KnowledgeMutationTypes.KNOWLEDGE_CATEGORIES_LOADING);
    try {
      await client.editCategoryPriority(payload);
      commit(KnowledgeMutationTypes.KNOWLEDGE_CATEGORIES_SUCCEEDED);
    } catch (error: any) {
      commit(KnowledgeMutationTypes.KNOWLEDGE_DATA_ERROR);
      throw new Error(error);
    }
  },

  async [KnowledgeActionTypes.EDIT_KNOWLEDGE_CATEGORY]({ commit }, payload: any) {
    try {
      await client.editCategory(payload);
    } catch (error: any) {
      commit(KnowledgeMutationTypes.KNOWLEDGE_DATA_ERROR);
      throw new Error(error);
    }
  },

  async [KnowledgeActionTypes.REMOVE_KNOWLEDGE_CATEGORY]({ commit }, payload: string) {
    try {
      await client.removeCategory(payload);
      commit(KnowledgeMutationTypes.REMOVE_KNOWLEDGE_CATEGORY, payload);
    } catch (error: any) {
      commit(KnowledgeMutationTypes.KNOWLEDGE_DATA_ERROR);
      throw new Error(error);
    }
  },
  async [KnowledgeActionTypes.SET_CURRENT_CATEGORY]({ commit }, payload: Category | null) {
    try {
      commit(KnowledgeMutationTypes.SET_CURRENT_CATEGORY, payload);
    } catch (error: any) {
      commit(KnowledgeMutationTypes.KNOWLEDGE_DATA_ERROR, error);
      throw new Error(error);
    }
  },
};
