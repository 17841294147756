import { PayloadFranchise } from '@/@typespaces/interfaces/payload.interfaces';
import {
  Franchise,
  IFranchise,
  IPayloadCurrentFranchise,
  ShortenedFranchise,
  ShortFranchise,
} from '@/@typespaces/interfaces/franchise.interface';
import { FranchiseChangeRoleUser, FranchiseRemoveUser } from '@/@typespaces/types/franchise.types';
import { Query } from '@/@typespaces/types/query.types';
import { User } from '@/@typespaces/interfaces/user.interface';
import { UpdateFile } from '@/@typespaces/types/updateFranchisesFile.types';
import { CheckHttpsPayload, RemoveFranchiseDomain } from '@/@typespaces/types/domain.types';
import { Url } from '@/@typespaces/interfaces/domain.interface';
import InstanceHttpClient from './instance';

export default class FranchiseClient extends InstanceHttpClient {
  constructor() {
    super('franchises');
  }

  async getFranchises(query: Query): Promise<PayloadFranchise> {
    try {
      return await this.apiCall({
        method: 'GET',
        params: query,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async getAllFranchiseShort(): Promise<ShortenedFranchise[]> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: 'all-short',
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async getAllFranchises(): Promise<ShortFranchise[]> {
    try {
      return await this.apiCall({
        method: 'GET',
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async getOneFranchise(id: string): Promise<Franchise> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: id,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async addFranchise(body: Partial<IFranchise>): Promise<Franchise> {
    try {
      return await this.apiCall({
        method: 'POST',
        data: body,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async editFranchise(data: Partial<Franchise>): Promise<Franchise> {
    const id = data._id;
    const body = data;
    delete body._id;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: id,
        data: body,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async blockFranchise(id?: string): Promise<void> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `${id}/block`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async ubBlockFranchise(id?: string): Promise<void> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `${id}/unblock`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async updateFileFranchise(payload: { data: UpdateFile; franchiseId: string }): Promise<void> {
    const { franchiseId, data } = payload;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `${franchiseId}/update-file`,
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async removeFranchiseFile(payload: { data: UpdateFile; franchiseId: string }) {
    const { franchiseId, data } = payload;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `${franchiseId}/delete-file`,
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async deleteFranchise(id: string): Promise<void> {
    try {
      return await this.apiCall({
        method: 'DELETE',
        url: id,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  // Методы для таблицы юзеров во франшизе

  async getFranchise(data: { id: string; query: any }): Promise<IPayloadCurrentFranchise> {
    const { id, query } = data;
    try {
      return await this.apiCall({
        method: 'GET',
        url: `/${id}/users`,
        params: {
          ...query,
        },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async addUserInFranchise(data: { franchiseId: string; email: string; roleId: string }) {
    const { franchiseId, email, roleId } = data;
    try {
      return await this.apiCall({
        method: 'POST',
        url: `/${franchiseId}/users/add-user`,
        data: { email, roleId },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async getFranchiseUser(data: { franchiseId: string; userId: string }): Promise<User> {
    const { franchiseId, userId } = data;
    try {
      return await this.apiCall({
        method: 'GET',
        url: `${franchiseId}/users/${userId}`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async deleteFranchiseUser(data: FranchiseRemoveUser) {
    try {
      const { franchiseId, userId } = data;
      await this.apiCall({
        method: 'DELETE',
        url: `${franchiseId}/users/${userId}/remove`,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async changeRoleFranchiseUser(data: FranchiseChangeRoleUser) {
    try {
      const { franchiseId, userId, roleId } = data;
      await this.apiCall({
        method: 'PATCH',
        url: `${franchiseId}/users/${userId}/change-role`,
        data: {
          roleId,
        },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async checkHttpsAccess(body: CheckHttpsPayload): Promise<Url> {
    const { franchiseId, data } = body;
    try {
      return await this.apiCall({
        method: 'POST',
        url: `${franchiseId}/check-https-access`,
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async removeDomain(body: RemoveFranchiseDomain): Promise<Franchise> {
    try {
      return this.apiCall({
        method: 'PATCH',
        url: `${body.franchiseId}/remove-domain`,
        data: body,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }
}
