// eslint-disable-next-line import/prefer-default-export
export const checkInputPhoneDate = (event: KeyboardEvent) => {
  const { code, shiftKey, ctrlKey, metaKey } = event;
  const accessKey = [
    'Digit1',
    'Digit2',
    'Digit3',
    'Digit4',
    'Digit5',
    'Digit6',
    'Digit7',
    'Digit8',
    'Digit9',
    'Digit0',
    'Space',
    'ArrowLeft',
    'ArrowUp',
    'ArrowDown',
    'ArrowRight',
    'Backspace',
    'Tab',
  ];
  const numbersWithShift = ['Digit9', 'Digit0', 'Minus'];
  const acvxKey = ['KeyA', 'KeyC', 'KeyV', 'KeyX'];
  const setOfAccessKey = new Set(accessKey);
  const setOfNumberOfShift = new Set(numbersWithShift);
  const setOfAcvxKey = new Set(acvxKey);
  const isInputPlus = code === 'Equal' && shiftKey;
  const isInputMinus = code === 'Minus' && !shiftKey;
  const isInputServiceAction = setOfAcvxKey.has(code) && (metaKey || ctrlKey);
  const isInputNotAccessKey = !setOfAccessKey.has(code) || (!setOfNumberOfShift.has(code) && event.shiftKey);

  if (isInputPlus || isInputMinus || isInputServiceAction) return;
  if (isInputNotAccessKey) event.preventDefault();
};
