import { Query } from '@/@typespaces/types/query.types';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const useFilterActions = (actionType: string, id?: string) => {
  const router = useRouter();
  const store = useStore();
  const routerName = router.currentRoute.value.name as string;

  const createRequest = async (query: Query) => {
    if (id) {
      await store.dispatch(actionType, { id, query });
    } else {
      await store.dispatch(actionType, query);
    }
    await router.replace({
      name: routerName,
      query,
    });
  };

  const addFilterHandler = async (query: Query) => {
    await createRequest(query);
  };
  const deleteFilterHandler = async (query: Query) => {
    await store.dispatch(actionType, query);
  };
  const clearSearchHandler = async () => {
    const query = { ...router.currentRoute.value.query } as { [key: string]: string };
    delete query._search;
    await router.replace({ name: routerName, query: { ...query } });
    await createRequest(query);
  };

  const searchClickHandler = async (value: string) => {
    const query: Query = {
      ...router.currentRoute.value.query,
      page: '1',
    };
    if (value.trim().length > 0) {
      query._search = value.replace(/\s/gi, '+');
      await router.replace({
        name: routerName,
        query,
      });
    } else {
      delete query._search;
      await router.push({
        name: routerName,
        query,
      });
    }
    await createRequest(query);
  };

  return {
    addFilterHandler,
    deleteFilterHandler,
    clearSearchHandler,
    searchClickHandler,
  };
};

export default useFilterActions;
