import DomainClient from '@/api/domain.api';
import { FranchiseMutationTypes } from '@/store/modules/franchise/mutation-types';
import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store';
import { FranchisesActionTypes } from '@/store/modules/franchises/action-types';
import FranchiseClient from '@/api/franchises.api';
import { Franchise, IFranchise } from '@/@typespaces/interfaces/franchise.interface';
import { FranchiseChangeRoleUser, FranchiseRemoveUser } from '@/@typespaces/types/franchise.types';
import RoleClient from '@/api/role.api';
import { UpdateFile } from '@/@typespaces/types/updateFranchisesFile.types';
import { CheckHttpsPayload, RemoveFranchiseDomain } from '@/@typespaces/types/domain.types';
import { State } from './state';
import { Mutations } from './mutations';
import { FranchisesMutationTypes } from './mutation-types';

const client = new FranchiseClient();
const roleClient = new RoleClient();
const domainClient = new DomainClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [FranchisesActionTypes.GET_FRANCHISES]({ commit }: AugmentedActionContext, data: any): void;

  [FranchisesActionTypes.GET_ALL_FRANCHISES]({ commit }: AugmentedActionContext): void;

  [FranchisesActionTypes.DELETE_FRANCHISE]({ commit }: AugmentedActionContext, id: string): void;

  [FranchisesActionTypes.GET_ONE_FRANCHISE]({ commit }: AugmentedActionContext, id: string): void;

  [FranchisesActionTypes.GET_ALL_FRANCHISES_SHORT]({ commit }: AugmentedActionContext): void;

  [FranchisesActionTypes.BLOCK_FRANCHISE]({ commit }: AugmentedActionContext, id: string): void;

  [FranchisesActionTypes.UNBLOCK_FRANCHISE]({ commit }: AugmentedActionContext, id: string): void;

  [FranchisesActionTypes.ADD_FRANCHISE]({ commit }: AugmentedActionContext, data: IFranchise): void;

  [FranchisesActionTypes.EDIT_FRANCHISE]({ commit }: AugmentedActionContext, data: Partial<Franchise>): void;

  [FranchisesActionTypes.UPDATE_FRANCHISES_FILE](
    { commit }: AugmentedActionContext,
    payload: { data: UpdateFile; franchiseId: string }
  ): void;

  [FranchisesActionTypes.REMOVE_FRANCHISES_FILE](
    { commit }: AugmentedActionContext,
    payload: { data: UpdateFile; franchiseId: string }
  ): void;

  [FranchisesActionTypes.CLEAR_ONE_FRANCHISE]({ commit }: AugmentedActionContext): void;

  // Для таблицы "Пользователи и роли" в отдельно выбранной франшизе
  [FranchisesActionTypes.GET_FRANCHISE_USERS](
    { commit }: AugmentedActionContext,
    data: { id: string; query: any }
  ): void;

  [FranchisesActionTypes.ADD_FRANCHISE_USERS](
    { commit }: AugmentedActionContext,
    data: { franchiseId: string; email: string; roleId: string }
  ): void;

  [FranchisesActionTypes.DELETE_FRANCHISE_USER]({ commit }: AugmentedActionContext, data: FranchiseRemoveUser): void;

  [FranchisesActionTypes.CHANGE_ROLE_FRANCHISE_USER](
    { commit }: AugmentedActionContext,
    data: FranchiseChangeRoleUser
  ): void;

  [FranchisesActionTypes.GET_FRANCHISE_USERS_ROLES]({ commit }: AugmentedActionContext): void;

  // Domain

  [FranchisesActionTypes.CONNECT_FRANCHISES_DOMAIN](
    { commit }: AugmentedActionContext,
    payload: {
      franchiseId: string;
      body: FormData;
    }
  ): void;

  [FranchisesActionTypes.CONNECT_SSL_TO_DOMAIN](
    { commit }: AugmentedActionContext,
    payload: {
      franchiseId: string;
      body: FormData;
    }
  ): void;

  [FranchisesActionTypes.CHECK_FRANCHISES_HTTPS_ACCESS](
    { commit }: AugmentedActionContext,
    payload: CheckHttpsPayload
  ): void;

  [FranchisesActionTypes.REMOVE_FRANCHISES_DOMAIN](
    { commit }: AugmentedActionContext,
    payload: RemoveFranchiseDomain
  ): void;

  [FranchisesActionTypes.CHECK_DOMAIN](
    { commit }: AugmentedActionContext,
    payload: {
      franchiseId: string;
      domain: string;
    }
  ): Promise<{
    cname: boolean;
    wcCname: boolean;
    https: boolean;
  }>;

  [FranchisesActionTypes.CHECK_EXIST_DOMAIN](
    { commit }: AugmentedActionContext,
    payload: {
      franchiseId: string;
      domain: string;
    }
  ): Promise<boolean>;

  [FranchisesActionTypes.CHECK_WILDCARD_CERT_FILE](
    { commit }: AugmentedActionContext,
    payload: {
      franchiseId: string;
      body: FormData;
    }
  ): void;

  [FranchisesActionTypes.CHECK_EXPIRY_DATE_DOMAIN](
    { commit }: AugmentedActionContext,
    payload: {
      franchiseId: string;
      domain: string;
    }
  ): Promise<any>;

  [FranchisesActionTypes.CHANGE_HTTPS_ACCESS_STATE_DOMAIN](
    { commit }: AugmentedActionContext,
    payload: { franchiseId: string; domain: string }
  ): void;

  [FranchisesActionTypes.REMOVE_CERT_OR_KEY_FILE](
    { commit }: AugmentedActionContext,
    payload: { type: 'cert' | 'key'; franchiseId: string }
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [FranchisesActionTypes.GET_ALL_FRANCHISES]({ commit }) {
    commit(FranchisesMutationTypes.FRANCHISES_LOADING);
    try {
      const franchise = await client.getAllFranchises();
      commit(FranchisesMutationTypes.FRANCHISES_SUCCEEDED);
      commit(FranchisesMutationTypes.GET_ALL_FRANCHISES, franchise);
    } catch (error: any) {
      commit(FranchisesMutationTypes.FRANCHISES_ERROR, error);
    }
  },
  async [FranchisesActionTypes.GET_FRANCHISES]({ commit }, data) {
    commit(FranchisesMutationTypes.FRANCHISES_LOADING);
    try {
      const franchise = await client.getFranchises(data);
      commit(FranchisesMutationTypes.FRANCHISES_SUCCEEDED);
      commit(FranchisesMutationTypes.SET_DATA_FRANCHISES, franchise);
    } catch (error: any) {
      commit(FranchisesMutationTypes.FRANCHISES_ERROR, error);
    }
  },
  async [FranchisesActionTypes.GET_ONE_FRANCHISE]({ commit }, data) {
    try {
      const franchise = await client.getOneFranchise(data);
      commit(FranchisesMutationTypes.GET_ONE_FRANCHISE, franchise);
    } catch (error: any) {
      commit(FranchisesMutationTypes.FRANCHISES_ERROR, error);
    }
  },
  async [FranchisesActionTypes.CLEAR_ONE_FRANCHISE]({ commit }) {
    commit(FranchisesMutationTypes.CLEAR_ONE_FRANCHISE);
  },
  async [FranchisesActionTypes.DELETE_FRANCHISE]({ commit }, id: string) {
    try {
      await client.deleteFranchise(id);
      commit(FranchisesMutationTypes.DELETE_FRANCHISE, id);
    } catch (error: any) {
      commit(FranchisesMutationTypes.FRANCHISES_ERROR, error);
      throw new Error(error);
    }
  },

  async [FranchisesActionTypes.BLOCK_FRANCHISE]({ commit }, id: string) {
    try {
      await client.blockFranchise(id);
      commit(FranchisesMutationTypes.BLOCK_FRANCHISE, id);
    } catch (error: any) {
      commit(FranchisesMutationTypes.FRANCHISES_ERROR, error);
      throw new Error(error);
    }
  },
  async [FranchisesActionTypes.GET_ALL_FRANCHISES_SHORT]({ commit }) {
    try {
      const response = await client.getAllFranchiseShort();
      commit(FranchisesMutationTypes.GET_ALL_FRANCHISES_SHORT, response);
    } catch (error: any) {
      commit(FranchisesMutationTypes.FRANCHISES_ERROR, error);
      throw new Error(error);
    }
  },
  async [FranchisesActionTypes.UNBLOCK_FRANCHISE]({ commit }, id: string) {
    try {
      await client.ubBlockFranchise(id);
      commit(FranchisesMutationTypes.UNBLOCK_FRANCHISE, id);
    } catch (error: any) {
      commit(FranchisesMutationTypes.FRANCHISES_ERROR, error);
      throw new Error(error);
    }
  },

  async [FranchisesActionTypes.ADD_FRANCHISE]({ commit }, data: IFranchise) {
    try {
      const response = await client.addFranchise(data);
      commit(FranchisesMutationTypes.ADD_FRANCHISE, response);
    } catch (error: any) {
      commit(FranchisesMutationTypes.FRANCHISES_ERROR, error);
      throw new Error(error);
    }
  },
  async [FranchisesActionTypes.EDIT_FRANCHISE]({ commit }, data: Partial<Franchise>) {
    try {
      await client.editFranchise(data);
    } catch (error: any) {
      commit(FranchisesMutationTypes.FRANCHISES_ERROR, error);
      throw new Error(error);
    }
  },
  async [FranchisesActionTypes.UPDATE_FRANCHISES_FILE]({ commit }, payload: { data: UpdateFile; franchiseId: string }) {
    try {
      await client.updateFileFranchise(payload);
    } catch (error: any) {
      commit(FranchisesMutationTypes.FRANCHISES_ERROR, error);
      throw new Error(error);
    }
  },
  async [FranchisesActionTypes.REMOVE_FRANCHISES_FILE]({ commit }, payload: { data: UpdateFile; franchiseId: string }) {
    try {
      await client.removeFranchiseFile(payload);
    } catch (error: any) {
      commit(FranchisesMutationTypes.FRANCHISES_ERROR, error);
      throw new Error(error);
    }
  },
  // Для таблицы "Пользователи и роли" в отдельно выбранной франшизе
  async [FranchisesActionTypes.GET_FRANCHISE_USERS]({ commit }, data: { id: string; query: any }) {
    commit(FranchisesMutationTypes.USERS_FRANCHISE_LOADING);
    try {
      const franchise = await client.getFranchise(data);
      commit(FranchisesMutationTypes.USERS_FRANCHISE_SUCCEEDED);
      commit(FranchisesMutationTypes.SET_FRANCHISE_USERS, franchise);
    } catch (error: any) {
      commit(FranchisesMutationTypes.USERS_FRANCHISE_ERROR, error);
      throw new Error(error);
    }
  },

  async [FranchisesActionTypes.GET_FRANCHISE_USERS_ROLES]({ commit }) {
    try {
      const roles = await roleClient.getFranchiseGroupRoles();
      commit(FranchisesMutationTypes.GET_FRANCHISE_USERS_ROLES, roles);
    } catch (error: any) {
      commit(FranchisesMutationTypes.USERS_FRANCHISE_ERROR, error);
      throw new Error(error);
    }
  },
  async [FranchisesActionTypes.ADD_FRANCHISE_USERS](
    { commit },
    data: { franchiseId: string; email: string; roleId: string }
  ) {
    try {
      await client.addUserInFranchise(data);
    } catch (error: any) {
      commit(FranchisesMutationTypes.USERS_FRANCHISE_ERROR, error);
      throw new Error(error);
    }
  },

  async [FranchisesActionTypes.DELETE_FRANCHISE_USER]({ commit }, data: FranchiseRemoveUser) {
    try {
      await client.deleteFranchiseUser(data);
      commit(FranchisesMutationTypes.DELETE_FRANCHISE_USER, data);
    } catch (error: any) {
      commit(FranchisesMutationTypes.USERS_FRANCHISE_ERROR, error);
      throw new Error(error);
    }
  },

  async [FranchisesActionTypes.CHANGE_ROLE_FRANCHISE_USER]({ commit }, data: FranchiseChangeRoleUser) {
    try {
      await client.changeRoleFranchiseUser(data);
      commit(FranchisesMutationTypes.CHANGE_ROLE_FRANCHISE_USER, data);
    } catch (error: any) {
      commit(FranchisesMutationTypes.USERS_FRANCHISE_ERROR, error);
      throw new Error(error);
    }
  },

  // Domain

  async [FranchisesActionTypes.CONNECT_FRANCHISES_DOMAIN](
    { commit },
    payload: {
      franchiseId: string;
      body: FormData;
    }
  ) {
    commit(FranchisesMutationTypes.DOMAIN_FRANCHISES_LOADING);
    try {
      const response = await domainClient.connectDomain(payload);
      commit(FranchisesMutationTypes.DOMAIN_FRANCHISES_SUCCEEDED);
      commit(FranchisesMutationTypes.CONNECT_FRANCHISES_DOMAIN, response);
      // @ts-ignore
      commit(FranchiseMutationTypes.CONNECT_FRANCHISE_DOMAIN, response);
    } catch (error: any) {
      commit(FranchisesMutationTypes.DOMAIN_FRANCHISES_ERROR, error);
      throw new Error(error);
    }
  },

  async [FranchisesActionTypes.CONNECT_SSL_TO_DOMAIN](
    { commit },
    payload: {
      franchiseId: string;
      body: FormData;
    }
  ) {
    commit(FranchisesMutationTypes.DOMAIN_FRANCHISES_LOADING);
    try {
      const response = await domainClient.connectSSLToDomain(payload);
      commit(FranchisesMutationTypes.CONNECT_SSL_TO_DOMAIN, response);
      // @ts-ignore
      commit(FranchiseMutationTypes.CONNECT_SSL_TO_DOMAIN, response);
      commit(FranchisesMutationTypes.DOMAIN_FRANCHISES_SUCCEEDED);
    } catch (error: any) {
      commit(FranchisesMutationTypes.DOMAIN_FRANCHISES_ERROR, error);
      throw new Error(error);
    }
  },

  async [FranchisesActionTypes.CHECK_FRANCHISES_HTTPS_ACCESS]({ commit }, payload: CheckHttpsPayload) {
    commit(FranchisesMutationTypes.DOMAIN_FRANCHISES_LOADING);
    try {
      const response = await client.checkHttpsAccess(payload);
      commit(FranchisesMutationTypes.CHECK_FRANCHISES_HTTPS_ACCESS, response);
      commit(FranchisesMutationTypes.DOMAIN_FRANCHISES_SUCCEEDED);
    } catch (error: any) {
      commit(FranchisesMutationTypes.DOMAIN_FRANCHISES_ERROR, error);
      throw new Error(error);
    }
  },

  async [FranchisesActionTypes.REMOVE_FRANCHISES_DOMAIN]({ commit }, payload: RemoveFranchiseDomain) {
    commit(FranchisesMutationTypes.DOMAIN_FRANCHISES_LOADING);
    try {
      const result = await client.removeDomain(payload);
      commit(FranchisesMutationTypes.REMOVE_FRANCHISES_DOMAIN, result);
      // @ts-ignore
      commit(FranchiseMutationTypes.REMOVE_FRANCHISE_DOMAIN, result);
      commit(FranchisesMutationTypes.DOMAIN_FRANCHISES_SUCCEEDED);
      return result;
    } catch (error: any) {
      commit(FranchisesMutationTypes.DOMAIN_FRANCHISES_ERROR, error);
      throw new Error(error);
    }
  },
  async [FranchisesActionTypes.CHECK_EXIST_DOMAIN](_, payload) {
    try {
      return domainClient.checkExistDomain(payload);
    } catch (error: any) {
      throw new Error(error);
    }
  },

  async [FranchisesActionTypes.CHECK_DOMAIN]({ commit }, payload) {
    try {
      const result = await domainClient.checkDomain(payload);
      commit(FranchisesMutationTypes.CHECK_DOMAIN, {
        checkDomain: payload.domain,
        cname: result.cname,
        https: result.https,
        aRecord: result.aRecord,
      });
      return result;
    } catch (error: any) {
      throw new Error(error);
    }
  },
  async [FranchisesActionTypes.CHECK_WILDCARD_CERT_FILE]({ commit }, payload) {
    try {
      return domainClient.checkWildcardCert(payload);
    } catch (error: any) {
      throw new Error(error);
    }
  },

  async [FranchisesActionTypes.CHECK_EXPIRY_DATE_DOMAIN]({ commit }, payload) {
    try {
      return domainClient.checkExpiryDate(payload);
    } catch (error: any) {
      throw new Error(error);
    }
  },

  async [FranchisesActionTypes.CHANGE_HTTPS_ACCESS_STATE_DOMAIN]({ commit }, payload) {
    try {
      return domainClient.changeHttpsStatus(payload);
    } catch (error: any) {
      throw new Error(error);
    }
  },
  async [FranchisesActionTypes.REMOVE_CERT_OR_KEY_FILE](
    { commit },
    payload: { type: 'cert' | 'key'; franchiseId: string }
  ) {
    try {
      const response = await domainClient.removeCertOrKey(payload);
      commit(FranchisesMutationTypes.REMOVE_CERT_OR_KEY_FILE, payload.type);
      // @ts-ignore
      commit(FranchiseMutationTypes.REMOVE_CERT_OR_KEY_FILE, payload.type);
      return response;
    } catch (error: any) {
      throw new Error(error);
    }
  },
};
