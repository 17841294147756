export const shortQueryOperatorSelectOptions = [
  {
    value: ':eq',
    label: 'равно',
  },
  {
    value: ':ne',
    label: 'не равно',
  },
  {
    value: ':contains',
    label: 'содержит',
  },
];

export const baseQueryOperatorSelectOptions = [
  {
    value: ':eq',
    label: 'равно',
  },
  {
    value: ':ne',
    label: 'не равно',
  },
  {
    value: ':contains',
    label: 'содержит',
  },
  {
    value: ':lt',
    label: 'меньше чем',
  },
  {
    value: ':gt',
    label: 'больше чем',
  },
];

export const fullOperatorSelectOptions = [
  {
    value: '_eq',
    label: 'равно',
  },
  {
    value: '_ne',
    label: 'не равно',
  },
  {
    value: '_lt',
    label: 'меньше чем',
  },
  {
    value: '_lte',
    label: 'меньше или равно',
  },
  {
    value: '_gt',
    label: 'больше чем',
  },
  {
    value: '_gte',
    label: 'больше или равно',
  },
  {
    value: '_contains',
    label: 'содержит',
  },
];
