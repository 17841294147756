// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum ReviewsMutationTypes {
  FETCH_REVIEWS = 'FETCH_REVIEWS',
  DELETE_REVIEW_CONTENT = 'DELETE_REVIEW_CONTENT',

  // Status
  REVIEWS_LOADING = 'REVIEWS_LOADING',
  REVIEWS_SUCCEEDED = 'REVIEWS_SUCCEEDED',
  REVIEWS_ERROR = 'REVIEWS_ERROR',

  REVIEWS_TABLE_LOADING = 'REVIEWS_TABLE_LOADING',
  REVIEWS_TABLE_SUCCEEDED = 'REVIEWS_TABLE_SUCCEEDED',
  REVIEWS_TABLE_ERROR = 'REVIEWS_TABLE_ERROR',
}
