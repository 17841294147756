import { MutationTree } from 'vuex';
import { IProfile } from '@/@typespaces/interfaces/profile.interface';
import { ProfileState } from './state';
import { ProfileMutationTypes } from './mutation-types';

export type Mutations<S = ProfileState> = {
  [ProfileMutationTypes.FETCH_PROFILE_DATA](state: S, payload: IProfile): void;
  [ProfileMutationTypes.EDIT_PROFILE](state: S, payload: IProfile): void;
};

export const mutations: MutationTree<ProfileState> & Mutations = {
  [ProfileMutationTypes.FETCH_PROFILE_DATA](state: ProfileState, data: IProfile) {
    state.data = { ...data };
  },

  [ProfileMutationTypes.EDIT_PROFILE](state: ProfileState, data: IProfile) {
    state.data = { ...state.data, ...data };
  },
};
