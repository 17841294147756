import { Services } from '@/@typespaces/interfaces/services.interface';
import Status from '@/@typespaces/enum/status.enum';

export interface State {
  data: Services | null;
  status: Status;
  tableStatus: Status;
  error: string | null;
}

export const state: State = {
  data: null,
  status: Status.IDLE,
  tableStatus: Status.IDLE,
  error: null,
};
