// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum KnowledgeMutationTypes {
  FETCH_KNOWLEDGE_DATA = 'FETCH_KNOWLEDGE_DATA',
  EDIT_KNOWLEDGE_SETTING = 'EDIT_KNOWLEDGE_SETTING',

  // Articles
  CREATE_ARTICLE = 'CREATE_ARTICLE',
  EDIT_ARTICLE = 'EDIT_ARTICLE',
  REMOVE_ARTICLE = 'REMOVE_ARTICLE',
  PUBLISH_ARTICLE = 'PUBLISH_ARTICLE',
  UN_PUBLISH_ARTICLE = 'UN_PUBLISH_ARTICLE',
  DND_KB_ARTICLES = 'DND_KB_ARTICLES',
  KNOWLEDGE_ARTICLES_LOADING = 'KNOWLEDGE_ARTICLES_LOADING',
  KNOWLEDGE_ARTICLES_SUCCEEDED = 'KNOWLEDGE_ARTICLES_SUCCEEDED',

  // Category
  CREATE_KNOWLEDGE_CATEGORY = 'CREATE_KNOWLEDGE_CATEGORY',
  EDIT_KNOWLEDGE_CATEGORY_PRIORITY = 'EDIT_KNOWLEDGE_CATEGORY_PRIORITY',
  EDIT_KNOWLEDGE_CATEGORY = 'EDIT_KNOWLEDGE_CATEGORY',
  REMOVE_KNOWLEDGE_CATEGORY = 'EDIT_KNOWLEDGE_CATEGORY',
  SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY',

  // Status
  KNOWLEDGE_DATA_LOADING = 'KNOWLEDGE_DATA_LOADING',
  KNOWLEDGE_DATA_SUCCEEDED = 'KNOWLEDGE_DATA_SUCCEEDED',
  KNOWLEDGE_DATA_ERROR = 'KNOWLEDGE_DATA_ERROR',

  KNOWLEDGE_CATEGORIES_LOADING = 'KNOWLEDGE_CATEGORIES_LOADING',
  KNOWLEDGE_CATEGORIES_SUCCEEDED = 'KNOWLEDGE_CATEGORIES_SUCCEEDED',
}
