// eslint-disable-next-line import/prefer-default-export
export const queryValueInterpreter = (query: string | Date, params: string) => {
  const isString = typeof query === 'string';
  const checkedParams = () => {
    if (params === 'published' || ('available_in_dashboard' && query === 'true')) {
      return 'published';
    }
    if (params === 'published' || ('available_in_dashboard' && query === 'false')) {
      return 'unpublished';
    }
    return query;
  };
  if (isString) {
    switch (checkedParams()) {
      case 'active':
      case 'false':
        return 'Активен';
      case 'blocked':
      case 'true':
        return 'Заблокирован';
      case 'block-in-project':
        return 'Блок в проекте';
      case 'published':
        return 'Опубликован';
      case 'unpublished':
        return 'Не опубликован';
      default:
        return query;
    }
  } else {
    return new Date(query).toLocaleDateString();
  }
};
