import { MutationTree } from 'vuex';
import Status from '@/@typespaces/enum/status.enum';
import { TemplatesCategoriesMutationTypes } from '@/store/modules/templatesCategories/mutation-types';
import { TemplateCategory } from '@/@typespaces/interfaces/templates.interface';
import { State } from './state';

export type Mutations<S = State> = {
  [TemplatesCategoriesMutationTypes.FETCH_TEMPLATES_CATEGORIES](state: S, payload: TemplateCategory[]): void;
  [TemplatesCategoriesMutationTypes.CREATE_TEMPLATES_CATEGORY](state: S, payload: TemplateCategory): void;
  [TemplatesCategoriesMutationTypes.EDIT_TEMPLATES_CATEGORY](state: S, payload: TemplateCategory): void;
  [TemplatesCategoriesMutationTypes.REMOVE_TEMPLATES_CATEGORY](state: S, payload: string): void;
  // Status
  [TemplatesCategoriesMutationTypes.TEMPLATES_CATEGORY_ERROR](state: S, payload: string): void;
  [TemplatesCategoriesMutationTypes.TEMPLATES_CATEGORY_LOADING](state: S): void;
  [TemplatesCategoriesMutationTypes.TEMPLATES_CATEGORY_SUCCEEDED](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [TemplatesCategoriesMutationTypes.FETCH_TEMPLATES_CATEGORIES](state: State, payload: TemplateCategory[]) {
    state.data = payload;
  },
  [TemplatesCategoriesMutationTypes.CREATE_TEMPLATES_CATEGORY](state: State, payload: TemplateCategory) {
    state.data.push(payload);
  },
  [TemplatesCategoriesMutationTypes.EDIT_TEMPLATES_CATEGORY](state: State, payload: TemplateCategory) {
    state.data = state.data.map((item) => {
      if (item._id === payload._id) {
        return payload;
      }
      return item;
    });
  },
  [TemplatesCategoriesMutationTypes.REMOVE_TEMPLATES_CATEGORY](state: State, id: string) {
    const removeIndex = state.data.map((item) => item._id).indexOf(id);
    state.data.splice(removeIndex, 1);
  },

  // Status
  [TemplatesCategoriesMutationTypes.TEMPLATES_CATEGORY_LOADING](state) {
    state.status = Status.LOADING;
  },
  [TemplatesCategoriesMutationTypes.TEMPLATES_CATEGORY_SUCCEEDED](state) {
    state.status = Status.SUCCEEDED;
  },
  [TemplatesCategoriesMutationTypes.TEMPLATES_CATEGORY_ERROR](state: State, error: string) {
    state.error = error;
    state.status = Status.IDLE;
  },
};
