import { FranchiseFileType } from '@/@typespaces/enum/file-type.enum';
import { Franchise } from '@/@typespaces/interfaces/franchise.interface';
import { CheckHttpsPayload, DomainData } from '@/@typespaces/types/domain.types';
import { Url } from '@/@typespaces/interfaces/domain.interface';
import InstanceHttpClient from './instance';

export default class FranchiseClient extends InstanceHttpClient {
  constructor() {
    super('franchise');
  }

  async getFranchise(): Promise<Franchise> {
    try {
      return await this.apiCall({
        method: 'GET',
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  public async editFranchise(data: Partial<Franchise>): Promise<Franchise> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  public async updateFranchiseFile(data: { type: FranchiseFileType; fileId: string }): Promise<void> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        data,
        url: '/update-file',
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async connectDomain(data: DomainData): Promise<Url> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: `/connect-domain`,
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async checkHttpsAccess(body: CheckHttpsPayload): Promise<Url> {
    const { data } = body;
    try {
      return await this.apiCall({
        method: 'POST',
        url: `check-https-access`,
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async removeDomain(body: CheckHttpsPayload) {
    const { data } = body;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `/remove-domain`,
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async removeFile(data: { file_id: string; type: string }) {
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `delete-file`,
        data,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }
}
